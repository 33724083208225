import moment from 'moment';

export const parseDate = (date: Date | string | null, forInput = false): string => {
  if (typeof date === 'string') {
    // check is a date without -
    if (date.length === 8) {
      // eslint-disable-next-line no-param-reassign
      date = `${date.slice(0, 4)}-${date.slice(4, 6)}-${date.slice(6, 8)}`
    }

    // eslint-disable-next-line no-param-reassign
    date = new Date(date);
  }

  if (date === null) {
    return '~';
  }

  if (Number.isNaN(+date)) {
    return '~';
  }

  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return forInput ? `${year}-${month}-${day}` : `${day}/${month}/${year}`;
};

export const parseFullDate = (date: Date | string): string => {
  if (typeof date === 'string') {
    // eslint-disable-next-line no-param-reassign
    date = new Date(date);
  }

  if (Number.isNaN(+date)) {
    return '~';
  }
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  const hour = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');
  return `${day}/${month}/${year} a las ${hour}:${minutes}:${seconds}`;
};

export const parsePrice = (number: number, currency = 'USD'): string => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
  }).format(number);
};

export const parseNumber = (number: number): string => {
  return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1-');
};

export const iso3ToCountry = (iso: string): string => {
  if (iso === 'MEX') return 'mexico';
  if (iso === 'COL') return 'colombia';
  if (iso === 'ARG') return 'argentina';
  if (iso === 'CHL') return 'chile';
  if (iso === 'PER') return 'peru';
  if (iso === 'BOL') return 'bolivia';
  if (iso === 'COL') return 'colombia';
  if (iso === 'ESP') return 'euro';
  if (iso === 'USA') return 'usd';
  return 'usd'
}

// export function parseTime(dateString: moment.MomentInput) {
//   const time = moment(dateString);
//   return time.format('HH:mm');
// }

export function dateParse(dateString: moment.MomentInput) {
  const date = moment(dateString);
  return date.format('MM/DD/YYYY');
}

export function parseTime(time: moment.MomentInput) {
  if (!time ) return '12:00';
  const date = new Date(`1970-01-01T${time}:00`);
  return date.toLocaleTimeString('en-ES', { hour: '2-digit', minute: '2-digit' });
}
