import Button from 'components/Button';
import React, { useEffect, useState } from 'react'
import AppleSystemConsume from 'services/AppleSystemService';
import IActivity from 'interfaces/IActivity';

type Props = {
  activity?: IActivity;
  reloadPut?: Function;
  reloadPost?: Function;
  newGet?: Function;
}


export default function Activity({ activity, reloadPut = () => { }, newGet = () => { }, reloadPost = () => { } }: Props) {

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [form, setForm] = useState<IActivity & { hora?: string }>({
    id: 0,
    codUsuario: 0,
    nombre: '',
    fecha: new Date(),
    lugar: '',
    telefono: '',
    direccion: '',
    link: '',
    hora: ''
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === 'fecha' || name === 'hora') {
      setForm(prev => ({
        ...prev,
        [name]: name === 'fecha' ? new Date(value) : value
      }));
    } else {
      setForm(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  useEffect(() => {
    if (activity) setForm(activity)
  }, [activity])

  const putData = (id: string) => {
    setIsLoading(true);
    AppleSystemConsume('activities')
      .put(id, form, {})
      .then((res) => {
        if (res.error) throw new Error(res.message)
        reloadPut()
        newGet()
        setIsLoading(false)
      })
  }

  function convertirHora12a24(hora12: string) {
    const [hora, minutos] = hora12.split(':');
    let h = Number(hora);
    const m = Number(minutos);
    if (hora12.includes('PM')) {
      h += 12;
    }
    return `${h}:${m}`;
  }

  const add = async () => {
    setIsLoading(true);
    if (form.hora) {
      const hora24 = convertirHora12a24(form.hora);
      const fechaConHora = new Date(`${form.fecha.toISOString().substring(0, 10)}T${hora24}`);
      const { hora, ...restoForm } = form;
      const formConFechaYHora = { ...restoForm, fecha: fechaConHora };
  
      AppleSystemConsume('activities')
        .post('', formConFechaYHora, {})
        .then((res) => {
          if (res.error) throw new Error(res.message);
          reloadPost();
          newGet();
          setIsLoading(false)
          setForm({
            id: 0,
            codUsuario: 0,
            nombre: '',
            fecha: new Date(),
            lugar: '',
            telefono: '',
            direccion: '',
            link: '',
            hora: ''
          });
  
          // Llama a addActivity aquí
          // if (addActivity) {
          //   addActivity(formConFechaYHora);
          // }
        })
        .catch((error: any) => {
          console.error(error)
        })
    } else {
      console.error('La hora no está definida');
    }
  };
  
  
  
  return (
    <>
      {
        isLoading ? (`${activity != null ? 'Modificando datos...' : 'Guardando datos...'} `)
          :
          <div style={{ margin: '0 auto' }}>
            <div className='d-flex flex-column justify-content-center align-content-center'>
              <div className='d-flex p-2 gap-2'>
                <input
                  className="form-control m-2 text-center"
                  name='nombre'
                  onChange={(e) => handleInputChange(e)}
                  type='text'
                  placeholder='Nombre'
                  value={form.nombre} />
                <input
                  className="form-control m-2 text-center"
                  placeholder='Fecha'
                  name='fecha'
                  onChange={handleInputChange}
                  type='date'
                  value={form.fecha.toISOString().substring(0, 10)}
                />
                <input
                  className="form-control m-2 text-center"
                  placeholder='Hora'
                  name='hora'
                  onChange={handleInputChange}
                  type='time'
                  value={form.hora}
                />
                <input
                  className="form-control m-2 text-center"
                  placeholder='Lugar'
                  name='lugar'
                  onChange={(e) => handleInputChange(e)}
                  type='text'
                  value={form.lugar} />
              </div>
              <div className='d-flex p-2 gap-2'>
                <input
                  className="form-control m-2 text-center"
                  placeholder='Teléfono'
                  name='telefono'
                  onChange={(e) => handleInputChange(e)}
                  type='text'
                  value={form.telefono} />
                <input
                  className="form-control m-2 text-center"
                  placeholder='Dirección'
                  name='direccion'
                  onChange={(e) => handleInputChange(e)}
                  type='text'
                  value={form.direccion} />
                <input
                  className="form-control m-2 text-center"
                  placeholder='Link'
                  name='link'
                  onChange={(e) => handleInputChange(e)}
                  type='text'
                  value={form.link} />
              </div>
            </div>
            <div className='text-center'>
              <Button title={`${activity ? 'Modificar' : 'Guardar'}`}
                onPress={activity ? () => { putData(form.id.toString()) } : () => { add() }} />
            </div>
          </div>
      }
    </>
  )
}