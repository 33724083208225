import React, { useEffect, useState } from 'react';
import './recomiendate.css';
import AppleSystemConsume from 'services/AppleSystemService';

type SendEmail = {
    email: string;
    name?: string
}

export default function Recomiendate() {

    const [username, setUsername] = useState<string>('');
    const [form, setForm] = useState<SendEmail>({
        email: '',
        name: ''
    });

    const sendEmail = () => {
        AppleSystemConsume('user')
            .post('refer', form, {})
            .then((res) => {
                console.log('resForm:', res);
                setForm({
                    email: '',
                    name: ''
                });
            })
            .catch((error) => console.error('Error al enviar el correo:', error));
    }

    const searchUsername = () => {
        AppleSystemConsume('auth')
            .get('info')
            .then((res) => {
                setUsername(res.usuario.nombreUsuario);
            })
    }

    useEffect(() => {
        searchUsername();
    })

    const copyLink = () => {
        const link = `https://client.sistemaapple.com.mx/registration/${username}`
        navigator.clipboard.writeText(link)
            // eslint-disable-next-line no-alert
            .then(() => alert('Enlace copiado'))
            .catch((error) => console.error('Algo salió mal al copiar el enlace', error));
    }


    return (
        <div className='recomiendate-container'>
            <div className='recomiendate-section'>
                <h2>Recomiéndate</h2>
                <p>
                    Te permitimos elegir a quién desees para que puedas enviarle una invitación <br />
                    a que se registre en el Sistema Apple nombrándote como su patrocinador.
                </p>
            </div>
            <div className='recomiendate-section'>
                <h3>Enviar mail de recomendación</h3>
                <div className='recomiendate-input'>
                    <label htmlFor="email">Mail invitado:</label>
                    <input
                        type="text"
                        name="email"
                        value={form.email}
                        onChange={e => setForm({ ...form, email: e.target.value })}
                        placeholder="Email..." />
                </div>
                <button className='recomiendate-button' onClick={sendEmail}>Recomiéndate</button>
            </div>
            <div className='recomiendate-section'>
                <h3>Link de referido</h3>
                <p>
                    Haciendo click en el siguiente botón, <br />
                    podrás copiar el enlace de registro a Sistema Apple MLM con tu usuario <br /> como patrocinador y se registren bajo tu usuario.
                </p>
                <button className='recomiendate-button' onClick={copyLink}>Copiar Link</button>
            </div>
        </div>
    )
}
