import React, { useEffect, useState } from 'react';
import '../Registration.css';
import Platform from 'types/Platform';
import api from 'utils/serverApi';
import Logo from './Logo';

export const RegistrationDone = () => {

    const params = new URLSearchParams(window.location.search);
    const platform = params.get('platform') as Platform;

    const onRedirect = (url: string) => {
        window.location.replace(url);
    }

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [message, setMessage] = useState<string>('');
    const [isSuccessful, setIsSuccessful] = useState<boolean>(false);

    const showCongrats = () => {
        try {
            setIsLoading(true)
            const registrationId = localStorage.getItem('registrationId');
            api('registration')
                .post('congrats', { registrationId })
                .then((res) => {
                    setMessage(res.message)
                    const error = Boolean(res.error);
                    if (error) return;
                    setIsSuccessful(true);
                    const redirectUrl: string = `${res.url}?jwt=${res.jwt}`;
                    onRedirect(redirectUrl);
                    localStorage.removeItem('registrationId')
                })
                .catch((error) => {
                    throw new Error(error.message)
                })
                .finally(() => {
                    setIsLoading(false);
                })

        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        const registrationIdParams = params.get('registrationId');
        if (registrationIdParams) localStorage.setItem('registrationId', registrationIdParams);
        showCongrats();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className='body-registration'>
            <header className='container-logo'>
                <div className='logo'>
                    <Logo id="logo_desktop" platform={platform} logoColor="white" />
                </div>
            </header>
            <div className='container-register'>
                <p className='text-center text-danger'>{message}</p>
                {isLoading ? <h4>Cargando...</h4>
                    :
                    isSuccessful &&
                    <div className='texto'>
                        <h4 style={{ fontSize: '30px' }}>Felicitaciones</h4><br /><br />
                        <p style={{ fontSize: '17px' }}>Has completado exitosamente<br />
                            tu etapa de registro en Sistema Apple</p><br /><br />
                    </div>
                }
            </div>
        </div>
    )
}
