import { useEffect } from 'react';

export default function OnKeyScapeHook(enable: boolean, func: Function) {
  useEffect(() => {
    function handleEscapeKey(event: KeyboardEvent) {
      if (event.code === 'Escape') {
        func();
      }
    }
    if (enable) {
      document.addEventListener('keydown', handleEscapeKey);
    }
    return () => document.removeEventListener('keydown', handleEscapeKey);
  }, [enable, func]);
}
