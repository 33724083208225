import TarjetaGenerica from 'components/templates/TarjetaGenerica';
import { useState, useEffect } from 'react';
import AppleSystemConsume from 'services/AppleSystemService';
import { parsePrice } from 'utils/parser';

interface TotalTransfer {
  error: boolean;
  code: number;
  message: string;
  totalTransfers: number;
}

export default function TarjetaTransferencias() {

  const [totalTransfer, setTotalTransfer] = useState<number>();
  const [cargando, setCargando] = useState<boolean>(false);

  const fetchTotal = () => {
    setCargando(true)
    AppleSystemConsume('usuario')
      .get('total-transfers')
      .then((res: TotalTransfer) => {
        if (res.error) throw new Error(res.message)
        setTotalTransfer(res.totalTransfers)
      })
      .finally(() => {
        setCargando(false)
      })
  }

  useEffect(() => {
    fetchTotal();
  }, [])

  return (
    <>
      <TarjetaGenerica
        onLoad={cargando}
        fondo='#E1FBFF'
        cantop='Transferencias'
        numero={parsePrice(Number(totalTransfer))}
        colorLetter='#000000'
        colorNumber='#000000'
      />
    </>
  )

}
