// import Badge from 'components/Badge';
import Badge from 'components/Badge';
import Table from 'components/Table';
import { LayoutHeader } from 'components/navs/LayoutHeader';
import React, { useEffect, useState } from 'react';
import AppleSystemConsume from 'services/AppleSystemService';
import { parseFullDate, parsePrice } from 'utils/parser';


interface Receptor {
  nombreUsuario: number;
}
interface Transferencia {
  codigo: number;
  codUsuario: number;
  codUsuarioReceptor: number;
  fecha: string;
  monto: number;
  costoTransferencia: number;
  receptor: Receptor;
}

interface Solicitudtransferencia {
  codigo: number;
  codUsuario: number;
  codUsuarioReceptor: number;
  fecha: string;
  monto: number;
  costoTransferencia: number;
  fechaCreacion: string;
  referencia: string;
  confirmado: number;
  confirmadoReceptor: number;
  receptor: Receptor;
}

interface sendDatatransferencia {
  error: boolean;
  code: number;
  message: string;
  moneyTransfers: Array<Transferencia>;
}

interface sendDataRequest {
  error: boolean;
  code: number;
  message: string;
  moneyTransfers: Array<Solicitudtransferencia>;
}

export default function Transferencias() {

  const [transferencias, setTransferencias] = useState<Transferencia[] | null>(null)
  const [solicitudTransferencia, setSolicitudtransferencia] = useState<Solicitudtransferencia[] | null>(null)
  const [rowTransferencias, setRowTransferencias] = useState<Transferencia[]>([]);
  const [rowTransferenciaRequest, setRowTransferenciaRequest] = useState<Solicitudtransferencia[]>([]);
  const [activeTab, setActiveTab] = useState('Tus Transferencias');

  const transferenciasFetch = async () => {
    await AppleSystemConsume('money-transfer')
      .get('')
      .then((res: sendDatatransferencia) => {
        if (res.error) throw new Error(res.message)
        setTransferencias(res.moneyTransfers)
      })
  }

  const transferenciasRequestFetch = async () => {
    await AppleSystemConsume('money-transfer')
      .get('request')
      .then((res: sendDataRequest) => {
        if (res.error) throw new Error(res.message)
        setSolicitudtransferencia(res.moneyTransfers)
      })
  }

  useEffect(() => {
    transferenciasFetch();
    transferenciasRequestFetch();
  }, [])

  return (

    <div className='container'>
      <LayoutHeader title='Transferencias' tabs={['Tus Transferencias', 'Solicitudes']} setActiveTab={setActiveTab} />
      <div>
        {activeTab === 'Tus Transferencias' ?
          <Table
            background='#fff'
            rows={transferencias ?? []}
            pagination
            onLoad={!transferencias}
            renderRows={(e) => setRowTransferencias(e)}
          >
            <thead>
              <tr style={{ fontSize: '12px' }}>
                <th>Fecha</th>
                <th>Monto</th>
                <th>Costo de Transferencia</th>
                <th>Receptor</th>
              </tr>
            </thead>
            <tbody>
              {rowTransferencias.map((row, index) => (
                <tr key={index} style={{ fontSize: '12px' }}>
                  <td>{parseFullDate(row.fecha)}</td>
                  <td style={{ color: row.monto > 0 ? 'green' : 'red' }}>
                    {parsePrice(row.monto)}
                  </td>
                  <td>{parsePrice(row.costoTransferencia)}</td>
                  <td>{row.receptor.nombreUsuario}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          : <Table
            rows={solicitudTransferencia ?? []}
            pagination
            onLoad={!solicitudTransferencia}
            renderRows={(e) => setRowTransferenciaRequest(e)}
          >
            <thead>
              <tr style={{ fontSize: '12px' }}>
                <th>Fecha</th>
                <th>Monto</th>
                <th>Costo de Transferencia</th>
                <th>Referencia</th>
                <th>Estado</th>
                <th>Receptor</th>
              </tr>
            </thead>
            <tbody>
              {rowTransferenciaRequest.map((row, index) => (
                <tr key={index} style={{ fontSize: '12px' }}>
                  <td>{parseFullDate(row.fecha)}</td>
                  <td style={{ color: row.monto > 0 ? 'green' : 'red' }}>
                    {parsePrice(row.monto)}
                  </td>
                  <td>{parsePrice(row.costoTransferencia)}</td>
                  <td>{row.referencia}</td>
                  <td><Badge state={row.confirmado ? 'Si' : 'No'} /></td>
                  <td>{row.receptor.nombreUsuario}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        }
      </div>
    </div>

  )
}