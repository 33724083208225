import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Platform from 'types/Platform';
import AppleSystemConsume from 'services/AppleSystemService';
import Logo from '../Logo'
import './RecoveryPassword.css'

interface Prop {
  email: string
  username: string;
}

interface ServerResponse {
  error: boolean;
  code: number;
  message: string;
}

export default function RecoveryPassword() {

  const params = new URLSearchParams(window.location.search);
  const platform = params.get('platform') as Platform;
  const newLocal = 'white';

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [formData, setFormData] = useState<Prop>({
    email: '',
    username: ''
  })

  const isEmailValid = (email: string) => {
    // Expresión regular para validar correos electrónicos
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement | HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    })
  };

  const saveEmailPassword = async () => {
    setIsLoading(true);
    try {
      const userField = formData.email.includes('@') ? 'email' : 'username';
      const data = { username: formData.email };

      if (userField === 'email' && !isEmailValid(formData.email)) {
        setErrorMessage('El correo electrónico ingresado no es válido.');
        setIsLoading(false);
        setIsButtonDisabled(true);
        return;
      }

      AppleSystemConsume('auth')
        .post('recovery-password', data, {})
        .then((res: ServerResponse) => {
          if (res.error) throw new Error(res.message)
          setSuccessMessage('Redirigiendo....')
          navigate('/reload-recovery')
        })
        .catch((error: ServerResponse) => {
          setErrorMessage(error.message)
        })
        .finally(() => { setIsLoading(false) })

    } catch (error) {
      setIsLoading(false);
      setErrorMessage('Error de red u otro error.');
      setIsButtonDisabled(true);
    }
  };


  return (
    <>
      <div className='body-recovery-password'>
        <header className='container-logo'>
          <div className='logo'>
            <Logo id="logo_desktop" platform={platform} logoColor={newLocal} />
          </div>
        </header>
        <div className='container-login-recoveryPassword'>
          <h4 className='mt-4'>Recuperar Contraseña</h4>
          <div className='texto'>
            <form className='form-cuenta-login'>
              <div className='form-group-recoveryPassword'>
                <input type="text"
                  placeholder='Nombre de usuario o Email'
                  name='email'
                  value={formData.email}
                  onInput={handleEmailChange}
                />
                <p>Deberas tener acceso a tu email ya que te enviaremos <br />
                  un enlace para reestablecer la contraseña.
                </p>
              </div>
            </form>
          </div>
          <div className='button-login'>
            <button onClick={saveEmailPassword} disabled={isLoading || isButtonDisabled}>
              {isLoading ? 'Cargando...' : 'Enviar'}
            </button>
            {isLoading ? <p className='text-center text-success'>{successMessage}</p> : <></>}
          </div>
          <p className='text-center text-danger'>{errorMessage}</p>
        </div>
      </div>
    </>
  )
}
