import React from 'react';
import './faqItem.css';
import Article from './templates/Article';

interface Faqs {
  codigo: number;
  pregunta: string;
  respuesta: string;
}

interface FaqItemProps {
  faq: Faqs;
}

const FaqItem: React.FC<FaqItemProps> = ({ faq }) => {
  return (
    <div className='m-3'>
      <Article title={faq.pregunta} color='warning' initialShow={false}>
        {faq.respuesta}
      </Article>
    </div>
  );
};

export default FaqItem;
