import Logo from '../../assets/images/logo_AS_fondo_negro.png';

export default function RetiroSolicitado() {

    return (
        <div className="card mb-3" style={{ width: '100%', height: '100%', background: 'linear-gradient(to right, #E9E7E6, #fff)' }}>
            <div className="card-body">
                <p style={{ fontSize: 28, fontWeight: '600' }} className="card-title text-center">Solicitud pendiente de confirmación</p>
                <p className="card-text text-center" style={{ fontSize: 12, fontWeight: '600' }}>
                    Se ha enviado un mail de confirmación a su cuenta de correo electronico
                    registrada en nuestro sistema. Por favor, verifique su email y realice la confirmación
                    correspondiente para que podamos comenzar a procesar su solicitud.
                    Para cualquier aclaración o soporte dirigirse a la sección de soporte de la plataforma donde puedes generar tu ticket.
                </p>
            </div>
            <img className="card-img-top" style={{ width: '50%', margin: 'auto' }} src={Logo} alt="" />
        </div>
    )
}
