import IUsuario from 'interfaces/IUsuario';

export default function UserDataFondeoPackage({usuario: {
    email,
    nombreUsuario,
    nombreUsuarioPatrocinador,
    apellidoPaterno,
    apellidoMaterno,
    nombre,
},saldoFondeo, titleSaldo}: {titleSaldo: string, saldoFondeo: number, usuario: IUsuario}) {

    return (
        <section className='p-4'>
            <p style={{ fontWeight: 'bolder', fontSize: 13 }} className='text-center'>Tus Datos</p>
            <div className='d-flex gap-2'>
                <p style={{ fontWeight: 'bolder', fontSize: 13 }}>Email:</p>
                <p>{email}</p>
            </div>
            <div className='d-flex gap-2'>
                <p style={{ fontWeight: 'bolder', fontSize: 13 }}>Nombre de usuario:</p>
                <p>{nombreUsuario}</p>
            </div>
            <div className='d-flex gap-2'>
                <p style={{ fontWeight: 'bolder', fontSize: 13 }}>Usuario Patrocinador:</p>
                <p>{nombreUsuarioPatrocinador}</p>
            </div>
            <div className='d-flex gap-2'>
                <p style={{ fontWeight: 'bolder', fontSize: 13 }}>Apellido Paterno:</p>
                <p>{apellidoPaterno}</p>
            </div>
            <div className='d-flex gap-2'>
                <p style={{ fontWeight: 'bolder', fontSize: 13 }}>Apellido Materno:</p>
                <p>{apellidoMaterno}</p>
            </div>
            <div className='d-flex gap-2'>
                <p style={{ fontWeight: 'bolder', fontSize: 13 }}>Nombre:</p>
                <p>{nombre}</p>
            </div>
            <div className='d-flex gap-2 align-items-center'>
                <p className='text-success' style={{ fontWeight: 'bolder', fontSize: 13 }}>{titleSaldo}</p>
                <p className={saldoFondeo && saldoFondeo < 0 ? 'text-danger' : 'text-success'}>{saldoFondeo}</p>
            </div>
        </section>
    )
}