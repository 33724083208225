import imagen1 from '../../assets/images/concursosYPremios/1.jpeg';
import imagen2 from '../../assets/images/concursosYPremios/2.jpeg';
import imagen3 from '../../assets/images/concursosYPremios/3.jpeg';
import imagen4 from '../../assets/images/concursosYPremios/4.jpeg';
import imagen5 from '../../assets/images/concursosYPremios/5.jpeg';
import imagen6 from '../../assets/images/concursosYPremios/6.jpeg';
import imagen7 from '../../assets/images/concursosYPremios/7.jpeg';
import imagen8 from '../../assets/images/concursosYPremios/8.jpeg';
import imagen9 from '../../assets/images/concursosYPremios/9.jpeg';
import imagen10 from '../../assets/images/concursosYPremios/10.jpeg';
import imagen11 from '../../assets/images/concursosYPremios/11.jpeg';
import imagen12 from '../../assets/images/concursosYPremios/12.jpeg';
import imagen13 from '../../assets/images/concursosYPremios/13.jpeg';
import imagen14 from '../../assets/images/concursosYPremios/14.jpeg';
import imagen15 from '../../assets/images/concursosYPremios/15.jpeg';
import imagen16 from '../../assets/images/concursosYPremios/16.jpeg';
import imagen17 from '../../assets/images/concursosYPremios/17.jpeg';
import imagen18 from '../../assets/images/concursosYPremios/18.jpeg';
import imagen19 from '../../assets/images/concursosYPremios/19.jpeg';
import './concursos.css';

export default function ConcursosPremios() {
    
    /* eslint-disable max-len */
    const imagenes = [
        { 
            src: imagen1, 
            titulo: 'Bono de Patrocinador', 
            desc: 'Bono pagado solo una vez al ser RA, no importa el mes que lo logres, y tendrás de manera directa un 5% del paquete base de menor valor entre tu y tu patrocinador.' 
        },
        { 
            src: imagen2, 
            titulo: 'Bono de Inicio Rápido', 
            desc: 'Bono pagado solo una vez al ser RA, lo debes lograr en mes y fracción desde que entras con tu paquete base, y tendrás de manera directa un 5% de tu paquete base.' 
        },
        { 
            src: imagen3, 
            titulo: 'Bono de Nivel', 
            desc: 'Toda nueva inversión de representante genera un 10% de CRR y tu puedes llevarte de ese CRR hasta un 40%, esto depende del nivel donde ingrese esa nueva inversión.' 
        },
        { 
            src: imagen4, 
            titulo: 'Bono Estrella', 
            desc: '200 SApoint a tu saldo, si ingresas de manera directa a 5 representantes con una sumatoria de paquetes mínimo de 5000 SApoint en un mismo mes calendario, puedes ganar cuantos desees.' 
        },
        { 
            src: imagen5, 
            titulo: 'Bono +2% de rendimiento', 
            desc: 'Si ya eres Representante Activo, podrás obtener un 2 por ciento adicional a tu rendimiento cada vez que mantengas tu posición como RA.' 
        },
        { 
            src: imagen6, 
            titulo: 'Rendimientos', 
            desc: 'Todo paquete de inversión genera por cada mes activo un mínimo un 5% de rentabilidad mensual, alcanzando un mínimo de un 60% Anual.' 
        },
        { 
            src: imagen7, 
            titulo: 'Regalías por compras y pagos de servicios APPLE GOLDEN',
            desc: 'Toda compra de artículos como nutrición, maquillaje, cuidado personal, del hogar, alimentos, ya sea pagados con puntos, ganancias o financiamiento al igual que el pago de facturas como luz, agua, gas, y muchas mas, te generan CRR y de estos pagos un porcentaje es para ti.' 
        },
        { 
            src: imagen8, 
            titulo: 'Comisiones Treidblue', 
            desc: 'Toda compra en linea de productos o servicios de las personas de la comunidad, o prestación de servicios de un profesional, genera un CRR, y tu ganas de cada movimiento en tu red.' 
        },
        { 
            src: imagen9, 
            titulo: 'Residuales', 
            desc: 'Cada vez que alguien gane algún beneficio dentro de la red, el sistema hace una retención sobre beneficio RSB, y una gran parte de esta retención será para ti.' 
        },
        { 
            src: imagen10, 
            titulo: 'Ganancia Venta Público', 
            desc: 'Tu como representante puedes conseguir los productos desde un 20% a 40% con descuento, y al venderlos te generan una gran ganancia de venta al publico.' 
        },
        { 
            src: imagen11, 
            titulo: 'Treidblue', 
            desc: 'Vende lo que siempre has vendido, y ofrece tus servicios profesionales, hay una comunidad dispuesta a comprarte sin que tengan dinero en su bolsillo. Incrementa tus ingresos.' 
        },
        { 
            src: imagen12, 
            titulo: 'Se RA', 
            desc: 'Vuélvete Representante Activo y tendrás acceso a ganar de todo lo que suceda en tu organización como comisiones, regalías, residuales, bonos, aparte de tu rendimiento, este se incrementa un 2% a lo que paga el sistema.' 
        },
        { 
            src: imagen13, 
            titulo: 'Se Lider Titanio', 
            desc: 'Ganas mayor porcentaje en todo CRR, mas un 2% de porcentaje en tu rentabilidad, y elige entre un celular de gama alta o un paquete de 600 SApoint como premio por lograrlo.' 
        },
        { 
            src: imagen14, 
            titulo: 'Se Lider Plata', 
            desc: 'Ganas mayor porcentaje en todo CRR en niveles 5 y 6, mas un 3% de porcentaje en tu rentabilidad, y elige entre una Laptop de ultima generación o un paquete de 1000 SApoint como premio por lograrlo.' 
        },
        { 
            src: imagen15, 
            titulo: 'Se Lider Oro', 
            desc: 'Ganas mayor porcentaje en todo CRR en niveles 5, 6 y 7, mas un 3% de porcentaje en tu rentabilidad, y elige entre un Crucero de lujo por el caribe para dos personas por 7 días con todo pagado, o un paquete de 2000 SApoint como premio por lograrlo.' 
        },
        { 
            src: imagen16, 
            titulo: 'Se Lider Platino', 
            desc: 'Ganas mayor porcentaje en todo CRR en niveles hasta el nivel 12, mas un 4% de porcentaje en tu rentabilidad, y elige entre un Camioneta Sportage ultimo modelo, o un paquete de 5000 SApoint como premio por lograrlo.' 
        },
        { 
            src: imagen17, 
            titulo: 'Se Lider Diamante', 
            desc: 'Ganas mayor porcentaje en todo CRR en todos tus niveles hasta nivel infinito, mas un 5% de porcentaje en tu rentabilidad, y elige entre un Mercedes Benz Clase A del año, o un paquete de 10000 SApoint como premio por lograrlo.' 
        },
        { 
            src: imagen18, 
            titulo: 'Tus puntos son dinero', 
            desc: 'Convierte todos tus pagos administrativos, como lo son inscripción, renovación, calificación en puntos Apple Golden y canjealos por productos, así no gastaras en ellos y los podrás capitalizar vendiendo el producto.' 
        },
        { 
            src: imagen19, 
            titulo: 'Bonos Extraordinarios', 
            desc: 'Cada mes los lideres y la compañía otorgan nuevos bonos por alcanzar metas logradas y bonos de ultima hora, no te pierdas estos beneficios y pregunta por ellos.' 
        }
    ];

    return (
        <div className="body">
            <div className="container" style={{ width: '100%', height: '100%' }}>
                <p className="text-center p-3" style={{ fontSize: '25px' }}>Concursos y Premios</p>
                <p className="text-center" style={{ fontSize: '16px' }}>Estimado representante, en el transcurso de su éxito por nuestra empresa,
                    Sistema Apple desarrolla bonos adicionales, premios y concursos aparte del <br />
                    plan de compensación para dar mayores beneficios,
                    calidad y estilo de vida.</p>
                <div className="grid-container">
                    {imagenes.map((imagen, index) => (
                        <div key={index} className='imgContainer' style={{padding: '10px'}}>
                            <img src={imagen.src} alt={`Imagen ${index + 1}`} className="img-fluid" />
                            <div className='txtContainer'>
                                <p className='text-center' style={{fontSize: '15px', fontWeight: '600', textDecoration: 'underline'}}>{imagen.titulo}</p>
                                <p className='text-center'>{imagen.desc}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}