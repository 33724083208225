type Obj = {
  [key: string]: string;
};

function ObjectToURI(obj: Obj = {}): string {
  return Object.keys(obj).reduce((acc, key) => acc + encodeURI(`${key}=${obj[key]}&`), '');
}

const api = (resource: string) => {
  const url = (process.env.NODE_ENV === 'production' ? 'https://stp.sistemaapple.com.mx/api/' : process.env.REACT_APP_API) + resource;
  const headers = new Headers();
  const authStorage = localStorage.getItem('auth');
  const auth = authStorage ? JSON.parse(authStorage) : '';
  headers.append('Authorization', auth.jwt ?? '');
  headers.append('Content-Type', 'application/json; charset=utf-8');

  return {
    get: (id = '', params: Obj = {}) =>
      fetch(`${url}${id ? `/${id}` : ''}${Object.keys(params).length ? `?${ObjectToURI(params)}` : ''}`, {
        headers,
      })
        .then(async (res) => {
          const awaitRes = await res.json();
          const Authorization = res.headers?.get('Authorization') ?? '';
          if (Authorization) {
            const { userType } = JSON.parse(localStorage.getItem('auth') ?? '{ userType: "" }');
            localStorage.setItem('auth', JSON.stringify({ jwt: Authorization, userType }));
          }
          return { ...awaitRes, jwt: res.headers?.get('Authorization') ?? '' };
        })
        .then((res) => {
          if (res.token_expired) {
            localStorage.removeItem('auth');
            window.location.href = '/login-V2';
          }
          return res;
        })
        .catch(() => {
          return {
            success: false,
            message: 'Hubo un problema al comunicarse con el servidor',
            err: 'ERR_CONNECTION_REFUSED',
          };
        }),
    post: (id = '', body: Object = {}, params: Obj = {}) =>
      fetch(`${url}${id ? `/${id}` : ''}${Object.keys(params).length ? `?${ObjectToURI(params)}` : ''}`, {
        headers,
        method: 'POST',
        body: JSON.stringify(body),
      })
        .then(async (res) => {
          const awaitRes = await res.json();
          const Authorization = res.headers?.get('Authorization') ?? '';
          if (Authorization) {
            const { userType } = JSON.parse(localStorage.getItem('auth') ?? '{ "userType": "" }');
            localStorage.setItem('auth', JSON.stringify({ jwt: Authorization, userType }));
          }
          return { ...awaitRes, jwt: res.headers?.get('Authorization') ?? '' };
        })
        .then((res) => {
          if (res.token_expired) {
            localStorage.removeItem('auth');
            window.location.href = '/login-V2';
          }
          return res;
        })
        .catch(() => {
          return {
            success: false,
            message: 'Hubo un problema al comunicarse con el servidor',
            err: 'ERR_CONNECTION_REFUSED',
          };
        }),
  };
};

export default api;
