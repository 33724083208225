import React from 'react';

type TextoDepositoProps = {
    tipo: 'bancario' | 'transferencia';
};

const TextoDeposito: React.FC<TextoDepositoProps> = ({ tipo }) => {
    const depositoBancarioTxt = (
        <p>
            * La opción 1 mexico ya no es necesario confirmar fondeo, ni subir ficha de deposito,
            tus SApoints aparecerán disponibles automáticamente en<br />
            el transcurso de 1 a 5 minutos en tu cuadro de fondeo, apenas realices la
            transferencia desde tu banca tradicional, para las otras opciones favor<br />
            de subir sus fichas de depósito.
            ** En nombre de la cuenta poner tu nombre completo como titular de la misma<br />
            *** El monto a depositar debe ser el mismo que aparece en la calculadora,
            recuerde que si su transferencia se demora en realizarla esta puede<br />
            tener variación en precio y llegar un monto en SApoint diferente.<br />
            **** Si haces el depósito vía STP no es necesario subir ficha de deposito
            los SApoint aparecerán en automático en tu cuadro de fondeo.
        </p>
    );

    const depositoBancarioTransferencia = (
        <p>
            Para transferencias desde otros países y pagos con tarjeta de crédito - débito recomendamos usar las 
            siguientes plataformas:<br/>
            * xoom.com desde Estados Unidos y Canada, puedes pagar con tarjeta de débito, tarjeta de crédito, 
            o transferencia bancaria local. (Usar<br/>
            cuenta clabe y monto en pesos a recibir).<br/>
            Skrill desde el área de transferencia de dinero, puedes pagar con tarjeta de crédito o débito desde 
            cualquier parte del mundo. (Usar cuenta<br/>
            clabe y monto en pesos a recibir).<br/>
            *Global66.com desde Latinoamerica y cuenta con diferentes formas de depósito localmente. 
            (Usar cuenta clabe y monto en pesos a recibir).<br/>
            * Transferencia Internacional directamente en bancos desde cualquier parte del mundo 
            (Usar Swift y cuenta en pesos y transferir<br/> monto a pagar en dólares).<br/>
            Para montos en pesos usar nuestra calculadora de fondeo y colocar moneda de pago México
            y transferir<br/> el monto total a pagar en pesos, pueden existir cargos adicionales dependiendo la plataforma.
        </p>
    );

    return (
        <div>
            {tipo === 'bancario' ? depositoBancarioTxt : depositoBancarioTransferencia}
        </div>
    );
};

export default TextoDeposito;
