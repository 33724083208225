import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Platform from 'types/Platform';
import AppleSystemConsume from 'services/AppleSystemService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import Logo from '../Logo';
import './RecoveredPassword.css'

interface Contrasena {
  password: string;
  passwordconfirm: string;
  username: string | null;
  uuid: string | null
}

interface ServerResponse {
  error: boolean;
  code: number;
  message: string;
}

export default function RecoveredPassword(): React.ReactElement | null {

  const params = new URLSearchParams(window.location.search);
  const platform = params.get('platform') as Platform;
  const newLocal = 'white';

  const navigate = useNavigate();
  const uuid = params.get('uuid')
  const username = params.get('username')

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showPwd, setShowPwd] = useState<boolean>(false);
  const [showPwd1, setShowPwd1] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [password, setPassword] = useState<Contrasena>({
    password: '',
    passwordconfirm: '',
    username,
    uuid
  });

  const inputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLInputElement>) => {
    const { name, value } = e.target
    setPassword({
      ...password,
      [name]: value
    })
  }

  const sendPassword = async () => {
    if (password.password !== password.passwordconfirm) {
      setIsLoading(false);
      setErrorMessage('Las contraseñas no coinciden.');
      return null
    };

    try {
      setIsLoading(true);
      AppleSystemConsume('auth')
        .post('update-password', password, {})
        .then((res: ServerResponse) => {
          if (res.error) {
            throw new Error(res.message)
          }
          navigate('/login')
        })
        .catch((error: ServerResponse) => {
          setErrorMessage(error.message)
        })
        .finally(() => { setIsLoading(false) })

    } catch (error) {
      setIsLoading(false);
      setErrorMessage('Error de red u otro error.');
    }
    return null
  };

  return (
    <div className='body-recovered-password '>
      <header className='container-logo'>
        <div className='logo'>
          <Logo id="logo_desktop" platform={platform} logoColor={newLocal} />
        </div>
      </header>
      <div className='container-login-recoveredPassword'>
        <h4 className='mt-4'>Elegir nueva Contraseña</h4>
        <div className='textop'>
          <form className='form-cuenta-login'>
            <div className='form-group-recoveredPassword'>
              <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <input
                  type={showPwd ? 'text' : 'password'}
                  name='password'
                  onInput={inputChange}
                  placeholder='Nueva Contraseña'
                  style={{ flex: 1 }} />
                <div className="column-icon" onClick={() => setShowPwd(!showPwd)} title="Mostrar/Ocultar contraseña">
                  {showPwd ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />}
                </div>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <input
                  type={showPwd1 ? 'text' : 'password'}
                  name='passwordconfirm'
                  onInput={inputChange}
                  placeholder='Confirmar Contraseña'
                  style={{ flex: 1 }} />
                <div className="column-icon" onClick={() => setShowPwd1(!showPwd1)} title="Mostrar/Ocultar contraseña">
                  {showPwd1 ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />}
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className='button-login text-center'>
          <button onClick={sendPassword} disabled={isLoading} >
            {isLoading ? 'Cargando...' : 'Confirmar'}
          </button>
          <p className='text-center text-danger'>{errorMessage}</p>
        </div>
      </div>
    </div>
  )
}
