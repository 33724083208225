import './template.css';
import TrendingUp from 'assets/icons/TrendingUp';
import TrendingDown from 'assets/icons/TrendingDown';
import TextSkeleton from 'components/skeletons/TextSkeleton';

interface Prop {
  numero: string | number | undefined;
  text: string;
  fondo?: string
  percentaje?: string
  colorLetter?: string
  colorPercentaje?: string
  fill?: string;
  txtPercentaje?: string;
  onLoad?: boolean
}

const TarjetaInicio = ({ text, numero, percentaje, fondo, colorLetter, colorPercentaje, fill, txtPercentaje, onLoad }: Prop) => {
  const showNumber = numero !== undefined && numero !== '' ? numero : '0';

  return (
    <div className='tarjetaInicio px-3 d-flex flex-column p-4'
      style={{ background: fondo, textAlign: 'left', height: '199px' }}>
      {onLoad || numero === undefined ? <TextSkeleton />
        :
        <>
          <div className='pb-2' style={{ fontSize: '15px', fontWeight: 'normal', color: colorLetter }}>
            <p style={{ fontSize: '18px' }}>{text}</p>
          </div>
          <div style={{ fontSize: '40px', color: colorLetter }}>
            {showNumber}
          </div>
          {percentaje !== '' && percentaje !== '0' && (
            <div style={{ color: colorPercentaje, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'left' }}>
              <p style={{ fontSize: '13px' }}>{percentaje && percentaje <= '0' ?
                <TrendingDown fill={fill} />
                :
                <TrendingUp fill={fill} />
              }{percentaje}</p>
              <p style={{ fontSize: '12px', marginLeft: '10px', color: colorLetter }}>{txtPercentaje}</p>
            </div>
          )}
        </>}
    </div>
  );
};

export default TarjetaInicio
