import TarjetaInicio from 'components/templates/TarjetaInicio';
import { useEffect, useState } from 'react';
import AppleSystemConsume from 'services/AppleSystemService';
import '../templates/template.css'
import { parsePrice } from 'utils/parser';

interface TotalGenerated {
  error: boolean;
  code: number;
  message: string;
  totalGenerated: number;
  generatedThisMonth: number;
  generatedLastMonth: number;
  success: boolean;
  percentaje: string;
}

export default function TarjetaTotalGenerado() {

  const [totalGenerado, setTotalGenerado] = useState<number>();
  const [percentajeGenerado, setPercentajeGenerado] = useState<string>();
  const [cargando, setCargando] = useState<boolean>(false)

  const fetchGenerado = async () => {
    setCargando(true)
    AppleSystemConsume('usuario')
      .get('total-generated')
      .then((res: TotalGenerated) => {
        if (res.error) throw new Error(res.message);
        setTotalGenerado(res.totalGenerated);
        setPercentajeGenerado(res.percentaje);
      })
      .catch(() => { })
      .finally(() => {
        setCargando(false)
      })
  }

  useEffect(() => {
    fetchGenerado();
  }, [])

  const dataFetch = {
    generado: {
      numero: totalGenerado,
      percentaje: percentajeGenerado,
      text: 'Total Generado',
      txtPercentaje: 'del mes pasado',
      fondo: '#000000',
      colorLetter: '#FFFFFF',
      colorPercentaje: '#E20616',
      fill: Number(percentajeGenerado) >= 0 ? '#5FC507' : '#E20616'
    }
  }

  return (
    <div className='tarjeta-margin'>
      {Object.values(dataFetch).map((data, index) => (
        <TarjetaInicio
          key={index}
          onLoad={cargando}
          text={data.text}
          numero={parsePrice(Number(data.numero))}
          percentaje={data.percentaje}
          txtPercentaje={data.txtPercentaje}
          fondo={data.fondo}
          colorLetter={data.colorLetter}
          colorPercentaje={data.colorPercentaje}
          fill={data.fill}
        />
      ))}

    </div>
  )
}
