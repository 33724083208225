import image from '../../assets/images/anuncioviaje.jpeg';

export default function Viajes() {
    /* eslint-disable max-len */
    return (
        <div className='d-flex flex-column justify-content-center align-center text-center p-4' style={{ margin: 'auto' }}>
            <span style={{ fontSize: '25px', fontWeight: '600' }}>Viaje a Punta Cana 2025</span>
            <img style={{ marginBottom: '15px', width: '50%', margin: 'auto' }} src={image} alt="viaje" />
            <div style={{ width: '50%', margin: 'auto' }}>
                <p style={{ fontSize: '22px', textDecoration: 'underline', fontWeight: '600' }}>Información:</p>
                <p style={{fontSize: '14px', fontWeight: '600'}}>
                    Viaje de convención de Sistema Apple a uno de los destinos más exclusivos del mundo, Punta Cana, República Dominicana, para Agosto de 2025 (los dias exactos se anunciarán en el aniversario de 2025).
                    La convención sera en Hotel de 5 estrellas y gran turismo en base ocupación doble, con boletos aéreos desde la Ciudad de México,
                    incluyendo transportación aeropuerto-hotel-aeropuerto, Alimentos y bebidas con alcohol ilimitado dentro del hotel con propinas incluidas,
                    actividades extraordinarias, habrá cena de gala de todo el equipo, una noche de fiesta, una sección de capacitación y otra de liderazgo de la compañía,
                    actividades en la playa de diversión, paseo y experiencias fuera del hotel y todo con una magnífica integración llena de lujo, además te daremos playeras,
                    gorra y más beneficios, tendremos actividades sopresa y grupales. Recuerda que independientemente de los bonos de viaje,
                    este lo puedes pagar con financiamiento, rendimientos, residuales, regalías y bonos generales del sistema,
                    lo importante es que nunca sacas dinero del bolsillo para viajar, ahora si deseas ganarlo te tenemos una sorpresa.
                </p>
                <p style={{ fontSize: '22px', textDecoration: 'underline', fontWeight: '600' }}>Bono Adicional Viaje:</p>
                <p style={{fontSize: '14px', fontWeight: '600'}}>
                    Para obtener bonos adicionales para el viaje primero debes estar registrado en el viaje,
                    el sistema te hará el cobro a tu cuenta mes a mes y los bonos adicionales por el viaje serán colocados cada mes como bono extraordinario,
                    para este bono de viaje deberás notificar a soporte cada vez que lo logres para que sea abonado tu bono extraordinario de viaje,
                    solo se podrán solicitar bonos por el mes en curso o en el anterior.
                    - Por cada persona directa y nueva en el sistema con un paquete base de 2000 SP obtendrás 40 SP.
                </p>
                <p style={{ fontSize: '22px', textDecoration: 'underline', fontWeight: '600' }}>Instrucciones</p>
                <ol style={{textAlign: 'left'}}>
                    <li style={{fontSize: '14px', fontWeight: '600'}}>Registra tu nombre para el viaje y acepta los términos, recuerda que para poder acceder al viaje debes tener una inversión mínima de 3000 SP, en caso de no tener todavía el volumen de aportación, podrás subirlo en el transcurso de 2024-2025, antes del aniversario de 2025.</li>
                    <li style={{fontSize: '14px', fontWeight: '600'}}>Si tienes más de 5000 SP en inversión puedes invitar a otra persona y colocar su nombre como invitado, en caso de tener menos y el sistema acepte el invitado tendrás que subir tu aportación a 5000 SP, , con los términos del punto anterior.</li>
                    <li style={{fontSize: '14px', fontWeight: '600'}}>Al registrarse el sistema cobrará 300 SP como pago del primer mes, si no tienes saldo disponible el sistema generará financiamiento, pero este será cubierto por tus ganancias mensuales, si colocas invitado el sistema cobrará 600 SP por las dos personas, (En caso de haberlo hecho antes del 5 de Marzo de 2024, no habrá cobro de inscripcion solo mensualidad).</li>
                    <li style={{fontSize: '14px', fontWeight: '600'}}>El sistema estará haciendo un cobro mensual de 185 SP hasta cobrar la totalidad del viaje, en el caso de los que adquirieron la promoción, en caso de no tener promoción el cobro mensual sera de 200 y este cobro ira incrementando mes a mes según avance el tiempo.</li>
                    <li style={{fontSize: '14px', fontWeight: '600'}}>El viaje debe estar cubierto a Julio de 2025.</li>
                    <li style={{fontSize: '14px', fontWeight: '600'}}>Todo bono generado para viaje deberá ser solicitado vía soporte en su panel de administración y este aparecerá como bono extraordinario ayudando a reducir el saldo de financiamiento, solo se podrá solicitar bono del mes en curso o el mes anterior.</li>
                    <li style={{fontSize: '14px', fontWeight: '600'}}>El monto de financiamiento para toda cuenta es del 30% del monto total de la inversión, Si al mes de Julio 2025 el monto de financiamiento arriba del 30%, el sistema cobrará la diferencia de sus paquetes de aportación.</li>
                    <li style={{fontSize: '14px', fontWeight: '600'}}>El precio del viaje de lujo con todo pagado es de 2550 SP, y solo para las personas que lo adquieran antes del 5 de Marzo de 2024 tendrán una bonificación de 600 puntos Apple Golden que serán entregados en el transcurso antes de viajar a Punta Cana.</li>
                    <li style={{fontSize: '14px', fontWeight: '600'}}>Si alguno por algún motivo no puede viajar, puede ceder lo que lleva pagado a otro representante o pedir una devolución, pero debe tener en cuenta que hay penalizaciones por la contratación de los aéreos, hotel, shows, excursiones por lo que gran porcentaje de sus pagos no retornan a su cuenta de Sistema Apple, esta penalización puede ir desde el 40% hasta el 100% del mismo valor ya pagado, si se cede lo pagado a otro usuario este nuevo usuario deberá pagar un valor por cambio de 50 SApoint, y el arreglo del valor abonado por el viaje es entre los dos usuarios.</li>
                    <li style={{fontSize: '14px', fontWeight: '600'}}>Se podrán llevar niños y el valor tomado es 2550 SP</li>
                    <li style={{fontSize: '14px', fontWeight: '600'}}>El valor de SP es tomado a la baja para el costo del viaje.</li>
                    <li style={{fontSize: '14px', fontWeight: '600'}}>Es obligación de todos tener pasaporte vigente al momento de la convención.</li>
                    <li style={{fontSize: '14px', fontWeight: '600'}}>Hotel, Aerolínea, fechas exactas, excursiones, serán anunciados en el aniversario de 2025.</li>
                    <li style={{fontSize: '14px', fontWeight: '600'}}>El sistema Apple puede cambiar las condiciones y términos del viaje en cualquier momento.</li>
                    <li style={{fontSize: '14px', fontWeight: '600'}}>En caso de generar el título de Líder Oro y solicitar el viaje, podrá solicitar al sistema una devolución de los cobros pagados para el viaje.</li>
                    <li style={{fontSize: '14px', fontWeight: '600'}}>En caso de estar en otro país diferente a México, la compañía analizará si es conveniente que los viajeros lleguen al destino sin venir a México, y si hay algún descuento por ser de mayor beneficio este se hará hasta finalizar los pagos.</li>
                </ol>
            </div>
        </div>
    )
}
