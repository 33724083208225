import React, { useEffect } from 'react';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';

function ThemeController({ children }: { children: React.ReactNode }) {
  const config = useAppSelector((state: RootState) => state.config);
  useEffect(() => {
    if (config.theme === 'dark') {
      document.querySelector('body')?.classList.add('dark-theme');
      document.querySelector('html')?.classList.add('dark-theme');
    } else {
      document.querySelector('body')?.classList.remove('dark-theme');
      document.querySelector('html')?.classList.remove('dark-theme');
    }
  }, [config.theme]);
  return <>{children}</>;
}

export { ThemeController };
