import React from 'react';
import { faCancel, faCheck } from '@fortawesome/free-solid-svg-icons';
import EColor from 'enums/EColor';
import Button from 'components/Button';
import OnkeyScaleHook from 'hooks/OnkeyScapeHook';

type ModalProps = {
  children: React.ReactNode;
  title: string;
  onClose: Function;
  show: boolean;
  btnRechazar?: boolean;
  onRechazar?: Function;
  btnAceptar?: string;
  onAceptar?: Function;
  childrenFooter?: React.ReactNode | null;
  showFooter?: boolean;
};

export default function Modal({
  children,
  showFooter = true,
  childrenFooter = null,
  title,
  onClose,
  show,
  btnRechazar = false,
  onRechazar = () => {},
  btnAceptar = '',
  onAceptar = () => {},
}: ModalProps) {
  OnkeyScaleHook(show, () => onClose());
  return (
    <div
      title={title}
      className={`${show ? 'd-block' : 'd-none'} modal fade show`}
      style={{ backgroundColor: '#000000aa', display: show ? 'block' : 'none' }}
    >
      <div className="modal-dialog modal-xl modal-dialog-scrollable modal-dialog-centered">
        <div className="modal-content" style={{ boxShadow: '0 .125rem .25rem rgba(0,0,0,.075)' }}>
          <div className="modal-header">
            <h5 className="modal-title">{title}</h5>
            <button title="icon-btn-close" type="button" className="btn-close" onClick={() => onClose()}></button>
          </div>
          <div className="modal-body">{children}</div>
          <div>{childrenFooter}</div>
          <div title="footer" className={showFooter ? 'modal-footer' : 'd-none'}>
            <button type="button" className="btn btn-secondary" onClick={() => onClose()}>
              Cerrar
            </button>
            {btnRechazar ? <Button onPress={() => onRechazar()} color={EColor.RED} icon={faCancel} title="rechazar" /> : <></>}
            {btnAceptar ? <Button onPress={() => onAceptar()} icon={faCheck} title={btnAceptar} /> : <></>}
          </div>
        </div>
      </div>
    </div>
  );
}
