import NewsItem from 'components/NewsItem';
import TextSkeleton from 'components/skeletons/TextSkeleton';
import { useState, useEffect } from 'react';
import AppleSystemConsume from 'services/AppleSystemService';

interface New {
  codigo: number;
  fecha: Date;
  asunto: string;
  texto: string;
}

interface sendData {
  code: number;
  message: string;
  error: boolean;
  news: Array<New>
}

export default function News() {

  const [resNew, setResNew] = useState<New[] | null>(null);
  const [message, setMessage] = useState<string>('');
  const [cargando, setCargando] = useState<boolean>(false);

  const fetchNews = () => {
    setCargando(true)
    AppleSystemConsume('news')
      .get('')
      .then((res: sendData) => {
        if (res.error === true) throw new Error
        setResNew(res.news)
      })
      .catch((error) => {
        setMessage(error.message)
        setResNew([])
      })
      .finally(() => {
        setCargando(false)
      })
  }

  useEffect(() => {
    fetchNews();
  }, [])

  return (

    <div className='container'>
      <header className='text-center mt-3 text-decoration-underline'>
        <h3 style={{ fontWeight: 'bold', color: 'black' }}>
          Últimas Noticias
        </h3>
      </header>
      {cargando
        ?
        <TextSkeleton />
        :
        resNew && resNew.map((item) => <NewsItem key={item.codigo} item={item} />)

      }
      <p className='text-danger'>{message}</p>
    </div>

  )

}