import React, { useState } from 'react';
import './template.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

type ArticleProps = {
  children: React.ReactNode;
  title?: string;
  color?: string;
  full?: boolean;
  initialShow?: boolean;
};

export default function Article({ color = 'as', children, title = '', full = false, initialShow = true }: ArticleProps) {
  const [show, setShow] = useState<Boolean>(initialShow);
  return (
    <article className="article-template" style={{ position: 'relative' }}>
      {title ? (
        <div onClick={() => setShow(!show)} className={`alert alert-${color || 'secondary'} cursor-pointer fs-15 text-center py-1 mb-0 my-3`}>
          {title}
          <FontAwesomeIcon
            icon={faChevronRight}
            style={{
              position: 'absolute',
              top: '.6em',
              right: '.6em',
              transform: `rotate(${show ? '90' : '0'}deg`,
            }}
          />
        </div>
      ) : (
        ''
      )}
      <div className={`${full ? '' : 'p-4'} ${show ? '' : 'd-none'}`}>{children}</div>
    </article>
  );
}
