import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../Registration.css';
import Platform from 'types/Platform';
import AppleSystemConsume from 'services/AppleSystemService';
import BackButtonRegister from 'components/BackButtonRegister';
import Logo from './Logo';


interface ServerResponse {
  routes: { next: string }
  error: boolean;
  code: number;
  message: string;
}

export const ValidarCorreo = () => {
  const params = new URLSearchParams(window.location.search);
  const platform = params.get('platform') as Platform;
  const newLocal = 'white';

  const [digitos, setDigitos] = useState<string[]>(['', '', '', '']);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');

  const handleDigitoChange = (index: number, value: string) => {
    if (value.length === 1 && value >= '0' && value <= '9') {
      const nuevosDigitos = [...digitos];
      nuevosDigitos[index] = value;
      setDigitos(nuevosDigitos);
    }
  };

  const sendCode = async () => {
    const requestBody = {
      registrationId: localStorage.getItem('registrationId') || '',
      code: digitos.join(''),
    };

    if (requestBody.code.length !== 4) {
      setMessage('Por favor, ingresa todos los números');
      return;
    }

    setMessage('')
    setIsLoading(true);

    AppleSystemConsume('registration')
      .post('email_validation_code', requestBody, {})
      .then((res: ServerResponse) => {
        if (res.error) throw new Error(res.message);
        navigate(res.routes.next);
      })
      .catch((err: any) => setMessage(err.message))
      .finally(() => setIsLoading(false));
  };

  const handleKeyUp = (index: number, event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.currentTarget.value && index < digitos.length - 1) {
      const nextSibling = event.currentTarget.nextElementSibling as HTMLInputElement;
      nextSibling.focus();
    }
  };

  useEffect(() => {
    const registrationIdParams = params.get('registrationId');
    if (registrationIdParams) {
      localStorage.setItem('registrationId', registrationIdParams)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  return (
    <>
      <div className='body-registration'>
      <div className='back-button'>
          <BackButtonRegister />
        </div>
        <header className='container-logo'>
          <div className='logo'>
            <Logo id="logo_desktop" platform={platform} logoColor={newLocal} />
          </div>
        </header>
        <div className='container-register'>
          <div className='texto'>
            <h4 style={{ fontSize: '30px' }}>Verificar Correo Electrónico</h4>
            <p style={{ fontSize: '17px' }}>Paso 2 de 5</p>
            <div className='centered-content'>
              <div className='form-group validate'>
                <div className='input-validate'>
                  {digitos.map((digito, index) => (
                    <input
                      key={index}
                      type='number'
                      value={digito}
                      className='hide-number-input-arrows'
                      maxLength={1}
                      onChange={(e) => handleDigitoChange(index, e.target.value)}
                      onKeyUp={(e) => handleKeyUp(index, e)}
                    />
                  ))}
                </div>
                <p className='p-code-phone-email'>Debes ingresar el código que enviamos a la cuenta de correo electrónico que ingresaste.
                </p>
              </div>
              <div className='button-register'>
                <button onClick={sendCode} disabled={isLoading}>
                  {isLoading ? 'Cargando...' : 'Continuar'}
                </button>
              </div>
              <p className="text-center text-danger">{message}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
