import Button from 'components/Button';
import Modal from 'components/Modal';
import React, { useEffect, useState } from 'react'
import AppleSystemConsume from 'services/AppleSystemService';
import { parseFullDate } from 'utils/parser';
import RetiroSolicitado from './RetiroSolicitado';

interface TipoCambio {
    success: boolean;
    SERVER_INFO: Record<string, unknown>;
    changes: {
        'USA-Compra': number;
        'COP-Compra': number;
        'MXN-Compra': number;
        'ARG-Compra': number;
        'CLP-Compra': number;
        'PEN-Compra': number;
        'BOB-Compra': number;
        'EUR-Compra': number;
        'CRC-Compra': number;
        'DOP-Compra': number;
    };
}

type SolicitudRetiro = {
    code: number;
    error: boolean;
    message: string;
    success: boolean;
}

export default function SectionSolicitarRetiro({ saldoDisponible, optUser }: { saldoDisponible?: number, optUser?: string }) {

    const [verMas, setVerMas] = useState<boolean>(false);
    const [inputMonto, setInputMonto] = useState<number>(0);
    const [convertedMonto, setConvertedMonto] = useState<number>(0);
    const [cambio, setCambio] = useState<TipoCambio | null>(null);
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [message, setMessage] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const getLastDayOfMonth = (year: number, month: number): Date => {
        return new Date(year, month + 1, 0);
    };
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth();
    const lastDay = getLastDayOfMonth(year, month);

    const changeSeeMore = () => {
        setVerMas(!verMas)
    }

    const fetchTipoCambio = async () => {
        const res = await fetch('https://api.treidblue.com/tipo-cambio');
        if (!res.ok) {
            throw new Error(`HTTP error! status: ${res.status}`);
        }
        const data = await res.json();
        setCambio(data);
    }

    useEffect(() => { fetchTipoCambio() }, [])

    useEffect(() => {
        if (inputMonto && cambio?.changes['MXN-Compra']) {
            setConvertedMonto((inputMonto * cambio.changes['MXN-Compra']) * 0.95);
        }
    }, [inputMonto, cambio]);

    const postRetiro = () => {
        try {
            if (saldoDisponible !== undefined && saldoDisponible < inputMonto) {
                throw new Error('No se puede realizar la solicitud de retiro.')
            }
            setIsLoading(true)
            AppleSystemConsume('withdrawal')
                .post('', { monto: inputMonto }, {})
                .then((res: SolicitudRetiro) => {
                    if (res.error) throw new Error(res.message)
                    setIsModalVisible(true);
                })
                .finally(() => {
                    setIsLoading(false)
                })
        } catch (err: any) {
            console.log(err)
            setMessage(err.message)
        }
    }

    const montoValue = `MXN: ${convertedMonto.toFixed(2)}`;

    return (
        <section className='p-4'>
            <p style={{ fontWeight: 'bolder', fontSize: 13 }} className='text-center'>Solicitar Retiro</p>
            <p>fecha de corte del retiro: {parseFullDate(lastDay)}</p>
            <p>Opcion de retiro seleccionada: {optUser} </p>
            <p style={{ background: 'linear-gradient(to right, #FFFBD0, #FDF8C2)' }}>
                - Este tipo de retiro tiene un gasto financiero ajeno a nuestra compañia de 5%. <br />
                - Todo retiro puede tardar de 1 a 3 días hábiles posterior a la fecha de corte o fecha de retiro extemporáneo.<br />
                - Si desea cambiar su forma de retiro, por favor contactar vía soporte y actualizar sus datos.
            </p>
            <div className='d-flex gap-4 align-items-center'>
                <input
                    className="form-control h-25 mb-3 border border-dark"
                    type="number"
                    name="inputMonto"
                    id="inputMonto"
                    defaultValue={inputMonto}
                    onBlur={(e) => setInputMonto(Number(e.target.value))}
                />
                <input
                    className="form-control h-25 mb-3 border border-dark"
                    type="text"
                    readOnly
                    value={montoValue}
                />
                <p className='text-danger text-center'>{message}</p>
            </div>
            <p style={{ display: 'inline-flex', background: 'linear-gradient(to right, #FFFBD0, #FDF8C2)', padding: 5, marginTop: 10 }}>
                Una vez realizada la solicitud, la misma debe ser aceptada por el administrador.
            </p>
            <div style={{ background: 'linear-gradient(to right, #FA8A7A, #FFCFC9)', padding: 5 }}>
                <strong>
                    El máximo valor a retirar por operación es de 1000 SApoint y un retiro por corte.<br />
                    Invitamos a usar retiro extemporáneo, transferencia de saldo, comprar <br />
                    y pagar sus servicios en Apple Golden y Treidblue.
                </strong>
                {verMas ? <p>
                    * Toda solicitud de retiro o fondeo que entra del exterior a cuentas bancarias mexicanas <br />
                    o sale al exterior enviado por plataformas de dispersión, <br />
                    el SApoint tomará el valor del precio en Mexico, esto es mientras nuestra compañía<br />
                    no se encuentre localmente con cuentas bancarias en sus países. <br />
                    Puede haber variaciones en el precio final y comisiones extras.
                </p> : <></>}
                <p style={{ cursor: 'pointer', textDecoration: 'underline', textAlign: 'center' }}
                    onClick={changeSeeMore}>{verMas ? 'Ocultar' : 'Ver mas'}</p>
            </div>
            <div className='m-auto d-flex justify-content-center mt-4'>
                <Button onLoad={isLoading} title='Solicitar Retiro' onPress={postRetiro} />
            </div>
            <Modal title='Retiro Solicitado' onClose={() => setIsModalVisible(false)} show={isModalVisible}>
                <RetiroSolicitado />
            </Modal>
        </section>
    )
}
