import CalculaldoraFondeo from 'components/CalculadoraFondeo';
import LineChart from 'pages/admin/charts/LineChart';
import '../../components/templates/template.css'
import TarjetaAportacion from 'components/tarjetasDashboard/TarjetaAportacion';
import TarjetaTotalGenerado from 'components/tarjetasDashboard/TarjetaTotalGenerado';
import TarjetaSaldo from 'components/tarjetasDashboard/TarjetaSaldo';
import TarjetaPuntosAG from 'components/tarjetasDashboard/TarjetaPuntosAG';
import TarjetaRetiros from 'components/tarjetasDashboard/TarjetaRetiros';
import TarjetaTransferencias from 'components/tarjetasDashboard/TarjetaTransferencias';

export default function Dashboard() {

  return (
    <div
      className='container container-dashboard'
      style={{
        alignItems: 'start',
        background: 'linear-gradient(to bottom right, #EAF6EA, #EAF6EA',
        margin: 0,
        padding: 20
      }}>
      <span><h4>General</h4></span>
      <div className='container-first' style={{ overflow: 'auto', marginBottom: '30px' }}>
        <TarjetaAportacion />
        <TarjetaTotalGenerado />
        <TarjetaSaldo />
        <TarjetaPuntosAG />
      </div>
      <span><h4>Tus Gastos</h4></span>
      <div className='grid-grafico-caluladora'>
        <div className='grafico' style={{ gridArea: 'grafico', height: '100%' }}>
          <LineChart />
        </div>
        <div className='tarjetas-transferencias-retiros' style={{ gridArea: 'tarjetas' }}>
          <TarjetaRetiros />
          <TarjetaTransferencias />
        </div>
        <div className='area-calculadora' style={{ gridArea: 'calculadora' }}>
          <CalculaldoraFondeo />
        </div>
      </div>
    </div>
  )
}

