import React, { useState } from 'react';

type DepositFormProps = {
    // eslint-disable-next-line no-unused-vars
    onPress: (e: React.FormEvent) => void;
    formRef?: React.RefObject<HTMLFormElement>;
    fileRef?: React.RefObject<HTMLInputElement>;
    // eslint-disable-next-line no-unused-vars
    onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
    valor: number | string;
    fechaFondeo: string;
    moneda?: string;
    inputAvailable?: boolean;
    txid?: boolean;
    txId?: string;
    nameValor?: string;
};

const DepositForm: React.FC<DepositFormProps> = ({
    onPress,
    formRef,
    fileRef,
    onChange,
    valor,
    fechaFondeo,
    moneda,
    txId,
    inputAvailable = false,
    txid = false,
    nameValor = 'Valor'
}) => {

    const [filePreview, setFilePreview] = useState<string | null>(null);

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0] || null;
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setFilePreview(reader.result as string);
            };
            reader.readAsDataURL(file);
        } else {
            setFilePreview(null);
        }
    };

    return (
        <form className="d-flex flex-column gap-3 shadow p-3 mb-5 bg-transparent rounded" onSubmit={onPress} ref={formRef}>
            <div className="d-flex gap-3">
                {txid && (
                    <div className="form-group flex-grow-1">
                        <label htmlFor="txid">TxId</label>
                        <input
                            type="text"
                            id="txid"
                            name="txid"
                            className="form-control"
                            value={txId}
                            onChange={onChange}
                        />
                    </div>
                )}
                <div className="form-group flex-grow-1">
                    <label htmlFor="valor">{nameValor}</label>
                    <input
                        type="number"
                        id="valor"
                        name="valor"
                        className="form-control"
                        value={valor}
                        onChange={onChange}
                    />
                </div>
                <div className="form-group flex-grow-1">
                    <label htmlFor="fechaFondeo">Fecha de Fondeo</label>
                    <input
                        type="date"
                        id="fechaFondeo"
                        name="fechaFondeo"
                        className="form-control"
                        value={fechaFondeo}
                        onChange={onChange}
                    />
                </div>
                {inputAvailable && (
                    <div className="form-group flex-grow-1">
                        <label htmlFor="moneda">Moneda del país</label>
                        <select
                            id="moneda"
                            name="moneda"
                            className="form-control"
                            value={moneda}
                            onChange={onChange}
                        >
                            <option value="">Seleccione una opción</option>
                            <option value="MXN">México</option>
                            <option value="COL">Colombia</option>
                            <option value="USD">Estados Unidos</option>
                        </select>
                    </div>
                )}
            </div>
            {fileRef && (
                <div className="d-flex align-items-center">
                    <div className="form-group flex-grow-1">
                        <label htmlFor="comprobante">Comprobante</label>
                        <input
                            type="file"
                            id="comprobante"
                            ref={fileRef}
                            name="comprobante"
                            className="form-control"
                            onChange={(e) => {
                                onChange(e);
                                handleFileChange(e);
                            }}
                        />
                    </div>
                    {filePreview && (
                        <div className="ms-3">
                            <img src={filePreview} alt="Vista previa del archivo" className="img-thumbnail" style={{ maxWidth: '200px' }} />
                        </div>
                    )}
                </div>
            )}
            <div className='d-flex m-auto'>
                <button className="btn btn-primary" style={{ backgroundColor: 'var(--color-as)' }}>Confirmar fondeo</button>
            </div>
        </form>
    );
};

export default DepositForm;
