import React, { useState, useEffect } from 'react';
import { PAGINATION_CONFIG } from 'configs';
import './templates/template.css';

// components
import Pagination from 'components/Pagination';
import TableSkeleton from 'components/skeletons/TableSkeleton';

export default function Table<T>({
  rows = [],
  textFilter = '',
  pagination = false,
  children,
  renderRows,
  onLoad = false,
  color,
  // shadow
}: {
  rows: Array<T & { nombreUsuario?: string; codigo?: string | number; proceso?: string }>;
  textFilter?: string;
  pagination?: boolean;
  onLoad?: boolean;
  children: React.ReactNode;
  color?: string
  background?: string
  // shadow?:string
  // eslint-disable-next-line no-unused-vars
  renderRows: (ar: T[]) => void;
}) {
  const [dataFilter, setDataFilter] = useState<Array<T>>([]);
  const [page, setPage] = useState<number>(0);


  useEffect(() => {
    if (rows.length === 0) {
      return;
    }
    if (!Array.isArray(rows)) {
      console.error('rows must be an array');
      return;
    }

    const filter = rows.filter((e) => {
      if (e?.proceso) return e.proceso.toUpperCase().includes(textFilter.toUpperCase())
      if (e?.nombreUsuario) return e.nombreUsuario.includes(textFilter);
      if (e?.codigo) return String(e.codigo).includes(textFilter);
      return true;
    });

    setDataFilter(filter);
  }, [rows, textFilter, page, pagination]);


  useEffect(() => {
    if (pagination) {
      renderRows(dataFilter.slice(page * PAGINATION_CONFIG.ITEMS_PAGES, (page + 1) * PAGINATION_CONFIG.ITEMS_PAGES));
      return;
    }
    renderRows(dataFilter);
    // eslint-disable-next-line
  }, [dataFilter, page, pagination]);
  return (
    <div className="table-responsive">
      <table style={{ boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px', backgroundColor: '#fff' }}
        className="table table-hover text-center">{onLoad ? <TableSkeleton color={color} thead colspan={99} /> : children}</table>
      {pagination ? <Pagination items={dataFilter.length} onPress={(item: number) => setPage(item)} /> : <></>}
    </div>
  );
}
