/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IConfig {
  theme: string;
}

const initialState: IConfig = {
  theme: localStorage.getItem('theme') ?? 'light',
};

const config = createSlice({
  name: 'Config',
  initialState,
  reducers: {
    init: (state, action: PayloadAction<IConfig>) => {
      localStorage.setItem('theme', action.payload.theme);
      state = action.payload;
      return state;
    },
  },
});

export const { init } = config.actions;
export default config.reducer;
