import Button from 'components/Button';
import { FormControl } from 'components/forms/FormControl';
import Article from 'components/templates/Article';
import { v4 as uuid } from 'uuid';
import AppleSystemConsume from 'services/AppleSystemService';
import { useContext, useEffect, useRef, useState } from 'react';
import IUsuario from 'interfaces/IUsuario';
import IProffesion from 'interfaces/IProffesion';
import ICountry from 'interfaces/ICountry';
import formToObject from 'utils/formToJson';
import IDocumentType from 'interfaces/IDocumentType';
import ITaxIdentification from 'interfaces/ITaxIdentification';
import IBank from 'interfaces/IBank';
import IBankType from 'interfaces/IBankType';
import EColor from 'enums/EColor';
import Badge from 'components/Badge';
import AuthContext from 'contexts/auth.context';
import Modal from 'components/Modal';

interface IValidacion {
  codUsuario: number,
  codigo: number,
  documentID: string,
  documentSigned: null,
  documentURL: string;
  signatoryID: string;
  status: string;
}

// eslint-disable-next-line camelcase
const calculateRFC = (name: string = '', surname: string = '', date_birth: string = ''): string => {
  const rfc = ['', '', '', '', '', '', ''];
  rfc[0] = surname.slice(0, 1);
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const [firstVocal, ..._] = surname.slice(1).match(/[aeiouAEIOU]/) ?? [''];
  rfc[1] = firstVocal;
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const [, firstMother, ..._1] = surname.match(/\s(\w)/) ?? ['', 'X'];
  rfc[2] = firstMother;
  rfc[3] = name.slice(0, 1);

  // eslint-disable-next-line camelcase
  const [, year, month, day] = date_birth.match(/^\d\d(\d\d)-(\d\d)-(\d\d)$/) ?? ['', '', '', ''];
  rfc[4] = year;
  rfc[5] = month;
  rfc[6] = day;
  return rfc.join().replaceAll(',', '').toUpperCase();
}

export default function EditProfile() {
  const { jwt, setJwt } = useContext(AuthContext);
  const idSurname = uuid();
  const [usuario, setUsuario] = useState<IUsuario | null>(null);

  const [check1, setCheck1] = useState<boolean>(false);
  const [check2, setCheck2] = useState<boolean>(false);
  const [check3, setCheck3] = useState<boolean>(false);

  const [validacion, setValidacion] = useState<IValidacion | null>(null);
  const [reloadUrl, setReloadUrl] = useState<boolean>(false);

  const [selectedBankType, setSelectedBankType] = useState<string>('');
  const [showCLABE, setShowCLABE] = useState<boolean>(false);
  const [showUSDT, setShowUSDT] = useState<boolean>(false);
  const [clabe, setClabe] = useState<string>('');
  const [bankAccount, setBankAccount] = useState<string>('');
  const [swift, setSwift] = useState<string>('');
  const [wallet, setWallet] = useState<string>('');
  const [taxNumber, setTaxNumber] = useState<string>('');

  const [proffesions, setProffesions] = useState<IProffesion[] | null>(null);
  const [countries, setCountries] = useState<ICountry[] | null>(null);
  const [documentTypes, setDocumentTypes] = useState<IDocumentType[] | null>(null);
  const [documentTypesFilteded, setDocumentTypesFiltered] = useState<IDocumentType[]>([]);
  const [taxIdentifications, setTaxIdentifications] = useState<ITaxIdentification[] | null>(null);
  const [taxIdentificationsFiltered, setTaxIdentificationsFiltered] = useState<ITaxIdentification[]>([]);
  const [banks, setBanks] = useState<IBank[] | null>(null);
  const [bankTypes, setBankTypes] = useState<IBankType[] | null>(null);

  const [onLoadInformation, setOnLoadInformation] = useState<boolean>(false);
  const [onLoadResidence, setOnLoadResidence] = useState<boolean>(false);
  const [onLoadIdentification, setOnLoadIdentification] = useState<boolean>(false);
  const [onLoadBankAccount, setOnLoadBankAccount] = useState<boolean>(false);

  const [errorInformation, setErrorInformation] = useState<string>('');
  const [successInformation, setSuccessInformation] = useState<string>('');
  const [errorIdentification, setErrorIdentification] = useState<string>('');
  const [errorResidence, setErrorResidence] = useState<string>('');
  const [errorBankAccount, setErrorBankAccount] = useState<string>('');
  const [successBankAccount, setSuccessBankAccount] = useState<string>('');
  const [serverError, setServerError] = useState<string>('');

  const [onLoadPhoneValidate, setOnLoadPhoneValidate] = useState<boolean>(false);
  const [phoneCode, setPhoneCode] = useState<string>('');

  const formInformation = useRef<HTMLFormElement | null>(null);
  const formResidence = useRef<HTMLFormElement | null>(null);
  const formIdentification = useRef<HTMLFormElement | null>(null);
  const formBankAccount = useRef<HTMLFormElement | null>(null);

  const [onInit, setOnIinit] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [claimNumber, setClaimNumber] = useState(false);
  const [claimNumberLoad, setClaimNumberLoad] = useState(false);

  useEffect(() => {
    if (usuario?.person_identification?.tax_identification_number) {
      setTaxNumber(usuario?.person_identification?.tax_identification_number);
      return;
    }
    setTaxNumber(calculateRFC(usuario?.person_information?.name, usuario?.person_information?.surname, usuario?.person_information?.date_birth))
  }, [usuario?.person_information, usuario?.person_identification]);

  const getState = (state: string) => {
    const states: {
      [index: string]: string;
    } = {
      'PENDING': 'PENDIENTE',
      'COMPLETED': 'COMPLETADO',
      'DRAFT': 'BORRADOR',
    }
    return states[state] ?? 'NINGUNO'
  };

  useEffect(() => {
    if (onInit) {
      return;
    };
    setOnIinit(true);
    AppleSystemConsume('usuario', jwt).get('my-usuario').then(res => {
      if (!res.usuario) {
        setServerError(res.message);
        return;
      }

      setUsuario(res.usuario);
      if (res.usuario?.person_bank_account) {
        setSelectedBankType(String(res.usuario.person_bank_account.id_bank_type));
        setSwift(res.usuario.person_bank_account.swift ?? '');
        setClabe(res.usuario.person_bank_account.clabe ?? '');
      }

      if (!res.usuario.person_bank_account) {
        if (res.usuario.cuenta && res.usuario.cuenta.length === 18) {
          setClabe(res.usuario.cuenta ?? '');
          setSwift(res.usuario.swift ?? '');
          setSelectedBankType('4');
        } else {
          setBankAccount(res.usuario.cuenta ?? '');
        }
      }

      if (res.usuario.person_bank_account) {
        if (res.usuario.person_bank_account.wallet) setWallet(res.usuario.person_bank_account.wallet);
        if (res.usuario.person_bank_account.id_bank_type !== 4) setBankAccount(res.usuario.person_bank_account.bank_number)
      }

      if (res.validacion) {
        setValidacion(res.validacion);
        // return;
      }
      // AppleSystemConsume('weetrust', jwt).post('documents', {}, {})
      //   .then(response => {
      //     if (response.jwt) {
      //       setJwt(response.jwt);
      //     }
      //     if (response.validacion) {
      //       setValidacion(response.validacion);
      //     }
      //   })
    })

    AppleSystemConsume('proffesion', jwt).get('').then(res => {
      if (res.jwt) {
        setJwt(res.jwt);
      }
      if (res.proffesions) {
        setProffesions(res.proffesions.sort((a: IProffesion, b: IProffesion) => a.name > b.name ? 1 : -1));
      }
    })

    AppleSystemConsume('country', jwt).get('').then(res => {
      if (res.jwt) {
        setJwt(res.jwt);
      }
      if (res.countries) {
        setCountries(res.countries);
      }
    })

    AppleSystemConsume('document-type', jwt).get('').then(res => {
      if (res.jwt) {
        setJwt(res.jwt);
      }
      if (res.documentTypes) {
        setDocumentTypes(res.documentTypes);
      }
    })

    AppleSystemConsume('tax-identification', jwt).get('').then(res => {
      if (res.jwt) {
        setJwt(res.jwt);
      }
      if (res.taxIdentifications) {
        setTaxIdentifications(res.taxIdentifications);
      }
    })

    AppleSystemConsume('bank', jwt).get('').then(res => {
      if (res.jwt) {
        setJwt(res.jwt);
      }
      if (res.banks) {
        setBanks(res.banks);
      }
    })

    AppleSystemConsume('bank-type', jwt).get('').then(res => {
      if (res.jwt) {
        setJwt(res.jwt);
      }
      if (res.bankTypes) {
        setBankTypes(res.bankTypes);
      }
    })
  }, [jwt, setJwt, onInit])

  const handleCreateClaimNumber = () => {
    setErrorInformation('');
    setSuccessInformation('');
    if (!formInformation.current) return;
    const { phone, id_country_phone: idCountryPhone } = formToObject(formInformation.current);
    setClaimNumberLoad(true);
    AppleSystemConsume('soporte')
      .post('claim-number', {
        phone, idCountryPhone
      }, {})
      .then(res => {
        if (res.error) {
          setErrorInformation(res.message);
          return;
        }
        setSuccessInformation(res.message);
      })
      .finally(() => {
        setClaimNumberLoad(false);
      });
  }

  const formSubmitPhoneValidate = () => {
    setOnLoadPhoneValidate(true);
    setErrorInformation('');
    setSuccessInformation('');
    AppleSystemConsume('person-information').post('validate-phone', { phoneCode }, {})
      .then(res => {
        if (res.jwt) {
          setJwt(res.jwt);
        }
        if (res.error) {
          setErrorInformation(res.message);
          return;
        }
        setSuccessInformation(res.message);
        if (!usuario) return;
        const userUpdate: IUsuario = { ...usuario };
        if (!usuario || !userUpdate.person_information) return;
        userUpdate.person_information.phoneValidated = true;
        setUsuario(userUpdate);
      })
      .finally(() => {
        setOnLoadPhoneValidate(false);
      });
  }

  const handleReloadUrl = () => {
    setReloadUrl(true);
    AppleSystemConsume('weetrust/documents', jwt).get('update-url').then(res => {
      if (res.jwt) {
        setJwt(res.jwt);
      }
      if (res.url) {
        window.parent.postMessage(res.url, '*');
      };
    }).finally(() => {
      setReloadUrl(false);
    })
  }

  const countryToId = (country: string): number => {
    if (!countries) return 0;
    const find = countries.find(e => e.iso === country);
    if (!find) return 0;
    return find.id;
  }

  const formSubmitUpdatePersonInformation = () => {
    if (!formInformation.current)
      return;

    if (!formInformation.current.checkValidity()) {
      setErrorInformation('por favor, completa todos los campos');
      return;
    }
    setClaimNumber(false);
    setPhoneCode('');
    setOnLoadInformation(true);
    setErrorInformation('');
    setSuccessInformation('');
    AppleSystemConsume('person-information', jwt)
      .put('', formToObject(formInformation.current), {})
      .then(res => {
        if (res.jwt) {
          setJwt(res.jwt);
        }
        if (res.error) {
          setErrorInformation(res.message);
          if (res.message === 'El número ingresado ya esta en uso.') setClaimNumber(true);
          return;
        }
        setSuccessInformation('Datos actualizados correctamente');
        setUsuario(res.usuario);
      })
      .finally(() => {
        setOnLoadInformation(false)
      });
  }

  const formSubmitPersonInformation = () => {
    if (!formInformation.current)
      return;

    if (!formInformation.current.checkValidity()) {
      setErrorInformation('por favor, completa todos los campos');
      return;
    }
    setOnLoadInformation(true);
    setErrorInformation('');
    AppleSystemConsume('person-information', jwt)
      .post('', formToObject(formInformation.current), {})
      .then(res => {
        if (res.jwt) {
          setJwt(res.jwt);
        }
        if (!res.usuario) {
          setErrorInformation(res.message);
          return;
        }
        setErrorInformation('');
        setUsuario(res.usuario);
      })
      .finally(() => {
        setOnLoadInformation(false)
      });
  }

  const formSubmitUploadPersonResidence = () => {
    if (!formResidence.current)
      return;

    if (!formResidence.current.checkValidity()) {
      setErrorResidence('por favor, completa todos los campos');
      return;
    }

    setErrorResidence('');
    setOnLoadResidence(true);
    AppleSystemConsume('person-residence', jwt)
      .put('', formToObject(formResidence.current), {})
      .then(res => {
        if (res.jwt) {
          setJwt(res.jwt);
        }
        if (!res.usuario) {
          setErrorResidence(res.message);
          return;
        }
        setErrorResidence('');
        setUsuario(res.usuario);
      })
      .finally(() => {
        setOnLoadResidence(false);
      });
  }

  const formSubmitPersonResidence = () => {
    if (!formResidence.current)
      return;

    if (!formResidence.current.checkValidity()) {
      setErrorResidence('por favor, completa todos los campos');
      return;
    }

    setErrorResidence('');
    setOnLoadResidence(true);
    AppleSystemConsume('person-residence', jwt)
      .post('', formToObject(formResidence.current), {})
      .then(res => {
        if (res.jwt) {
          setJwt(res.jwt);
        }
        if (!res.usuario) {
          setErrorResidence(res.message);
          return;
        }
        setErrorResidence('');
        setUsuario(res.usuario);
      })
      .finally(() => {
        setOnLoadResidence(false);
      });
  }

  const formSubmitUpdatePersonIdentification = () => {
    if (!formIdentification.current)
      return;

    if (!formIdentification.current.checkValidity()) {
      setErrorIdentification('por favor, completa todos los campos');
      return;
    }

    setErrorIdentification('');
    setOnLoadIdentification(true);
    AppleSystemConsume('person-identification', jwt)
      .put('', formToObject(formIdentification.current), {})
      .then(res => {
        if (res.jwt) {
          setJwt(res.jwt);
        }
        if (!res.usuario) {
          setErrorIdentification(res.message);
          return;
        }
        setErrorIdentification('');
        setUsuario(res.usuario);
      })
      .finally(() => {
        setOnLoadIdentification(false);
      });
  }

  const formSubmitPersonIdentification = () => {
    if (!formIdentification.current)
      return;

    if (!formIdentification.current.checkValidity()) {
      setErrorIdentification('por favor, completa todos los campos');
      return;
    }

    setErrorIdentification('');
    setOnLoadIdentification(true);
    AppleSystemConsume('person-identification', jwt)
      .post('', formToObject(formIdentification.current), {})
      .then(res => {
        if (res.jwt) {
          setJwt(res.jwt);
        }
        if (!res.usuario) {
          setErrorIdentification(res.message);
          return;
        }
        setErrorIdentification('');
        setUsuario(res.usuario);
      })
      .finally(() => {
        setOnLoadIdentification(false);
      });
  }

  const formSubmitPersonBankAccount = () => {
    if (!formBankAccount.current)
      return;
    setSuccessBankAccount('');
    if (!formBankAccount.current.checkValidity()) {
      setErrorBankAccount('por favor, completa todos los campos');
      return;
    }

    setErrorBankAccount('');
    setOnLoadBankAccount(true);
    AppleSystemConsume('person-bank-account', jwt)
      .post('', formToObject(formBankAccount.current), {})
      .then(res => {
        if (res.jwt) {
          setJwt(res.jwt);
        }
        if (!res.usuario) {
          setErrorBankAccount(res.message);
          return;
        }
        setSuccessBankAccount(res.message);
        setErrorBankAccount('');
        setUsuario(res.usuario);
      })
      .finally(() => {
        setOnLoadBankAccount(false);
      });
  }

  useEffect(() => {
    if (!usuario) return;
    if (!usuario.person_residence) return;
    if (!countries) return;
    setShowCLABE(selectedBankType === '4');
    setShowUSDT(selectedBankType === '6');
  }, [countries, usuario, selectedBankType])

  const getBankID = (): number => {
    if (!usuario) return 0;
    if (!banks) return 0;
    return banks.find(e => e.code === usuario.institucionClabe)?.id ?? 0;
  }

  // const getTipoCuenta = () => {
  //   if (!usuario) return 0;
  //   if (!bankTypes) return 0;
  //   // if(usuario.tipoCuentaBanco?.toUpperCase() === 'CUENTA CLABE') {
  //   //   setShowCLABE(true);
  //   // }
  //   return bankTypes.find(e => e.name.toUpperCase() === usuario.tipoCuentaBanco?.toUpperCase())?.id ?? 0;
  // }

  useEffect(() => {
    if (!documentTypes) return;
    if (!taxIdentifications) return;
    switch (usuario?.person_residence?.id_country_residence) {
      case 141: // Mexico
        setDocumentTypesFiltered(documentTypes.filter(e => [1, 3, 5].includes(e.id ?? -1)));
        setTaxIdentificationsFiltered(taxIdentifications.filter(e => [1].includes(e.id ?? -1)));
        break;
      case 45: // Colombia
        setDocumentTypesFiltered(documentTypes.filter(e => [1, 2, 6].includes(e.id ?? -1)));
        setTaxIdentificationsFiltered(taxIdentifications.filter(e => [2].includes(e.id ?? -1)));
        break;
      default: // Resto del mundo
        setDocumentTypesFiltered(documentTypes.filter(e => [1, 4].includes(e.id ?? -1)));
        setTaxIdentificationsFiltered(taxIdentifications.filter(e => [3].includes(e.id ?? -1)));
        break;
    }

  }, [usuario?.person_residence?.id_country_residence, documentTypes, taxIdentifications]);

  return (
    <div className='container my-4' style={{ paddingBottom: '10em' }}>
      {
        usuario ?
          <Article title='Datos Personales' color={usuario.person_information ? 'success' : ''}>
            <form ref={formInformation}>
              <div className="row">
                <FormControl title='Nombres' className='col-12 col-md-6'>
                  <input className='form-control mb-3' type="text"
                    defaultValue={usuario.person_information?.name ?? usuario.nombre}
                    disabled />
                </FormControl>
                <FormControl title='Apellidos' className='col-12 col-md-6' uuid={idSurname}>
                  <input className='form-control mb-3' type="text" id={idSurname}
                    defaultValue={
                      usuario.person_information?.surname ?? `${usuario.apellidoPaterno} ${usuario.apellidoMaterno}`}
                    disabled />
                </FormControl>
                <FormControl title='Fecha de nacimiento' className='col-12 col-md-6' required>
                  <input name="date_birth" className='form-control mb-3' type="date"
                    defaultValue={usuario.id_person_information
                      ? String(usuario.person_information?.date_birth).slice(0, 10)
                      : usuario.fechaNacimiento}

                    disabled={(usuario.person_information && usuario.person_information.updatedDateBirth)}
                    required />
                </FormControl>
                <FormControl title='Sexo' className='col-12 col-md-6' required>
                  <select
                    name="sex" className='form-select mb-3'
                    required
                    disabled={usuario.person_information && usuario.person_information.updatedSex}
                    defaultValue={usuario.person_information?.sex ?? usuario.sexo}>
                    <option value="">Selecciona una opión</option>
                    <option value="F">Femenino</option>
                    <option value="M">Masculino</option>
                  </select>
                </FormControl>
                <FormControl title='Código País' className='col-12 col-md-6' required>
                  {
                    countries
                      ? <select name="id_country_phone" className='form-select mb-3'
                        defaultValue={usuario.person_information?.id_country_phone ?? ''} required>
                        <option value=""></option>
                        {countries.map(country => <option key={`ct-${country.id}`} value={country.id}>{country.name} ({country.phone_code})</option>)}
                      </select>
                      : <></>
                  }
                </FormControl>
                <FormControl title='Teléfono' className='col-12 col-md-6' required>
                  <input name="phone" className='form-control mb-3' type="tel"
                    defaultValue={usuario.person_information?.phone ?? usuario.telefono} required />
                  {
                    // usuario.person_information?.forceValidate
                    //   ? <label className='text-danger'>El teléfono no pudo verficarse</label>
                    //   : <></>
                  }
                </FormControl>
                <FormControl title='Profesión' className='col-12 col-md-6' required>
                  {proffesions
                    ? <select name="id_proffesion" className='form-select mb-3'
                      defaultValue={usuario.person_information?.id_proffesion ?? ''} required>
                      <option value=""></option>
                      {proffesions.map(proffesion => <option key={`pr-${proffesion.id}`} value={proffesion.id}>{proffesion.name}</option>)}
                    </select>
                    : <></>}
                </FormControl>
                <FormControl title='Beneficiario' className='col-12 col-md-6' required>
                  <input
                    name="beneficiary" className='form-control mb-3' type="text"
                    defaultValue={usuario.person_information?.beneficiary ?? usuario.beneficiario} required />
                </FormControl>
                <FormControl title='Email' className='col-12 col-md-6'>
                  <input className='form-control mb-3' type="text" defaultValue={usuario.email}
                    disabled />
                </FormControl>
              </div>
              <div className="text-center">
                {
                  usuario.person_information
                    ? <Button title="Guardar Cambios"
                      onLoad={onLoadInformation}
                      onPress={formSubmitUpdatePersonInformation} />
                    : <Button title="Guardar Cambios"
                      onLoad={onLoadInformation}
                      onPress={formSubmitPersonInformation} />
                }
                {
                  claimNumber ?
                    <Button title="Iniciar Reclamo"
                      onLoad={claimNumberLoad}
                      color={EColor.YELLOW}
                      onPress={handleCreateClaimNumber} />
                    : <></>
                }
                {
                  usuario.person_information && !usuario.person_information.phoneValidated && !usuario.person_information.forceValidate
                    ? <div className='row mt-5'>
                      <p className='col-12'>Por favor, escribe el código enviado al telefono ingresado</p>
                      <div className='col-6'>
                        <input
                          placeholder='Código de verificaciön' value={phoneCode}
                          onChange={e => setPhoneCode(e.target.value)}
                          className='form-control mb-3' type="text" />
                      </div>
                      <div className="col-6">
                        <Button title="verificar "
                          onLoad={onLoadPhoneValidate}
                          onPress={formSubmitPhoneValidate} />
                      </div>
                    </div>
                    : <></>
                }
                {successInformation ? <p className='alert alert-success text-center'>{successInformation}</p> : <></>}
                {errorInformation ? <p className='alert alert-danger'>{errorInformation}</p> : <></>}
              </div>
            </form>
          </Article> : <></>
      }
      {
        !usuario && !serverError
          ? <div className="d-flex justify-content-center align-items-center color-as" style={{ minHeight: '10em' }}>
            <div className="spinner-border" role="status"></div>
          </div>
          : <></>
      }
      {
        !usuario && serverError
          ? <h3 className='text-center text-danger'>{serverError}</h3>
          : <></>
      }
      {
        usuario &&
          usuario.id_person_information
          && (usuario.person_information?.phoneValidated || usuario.person_information?.forceValidate
            || usuario.person_residence)
          ? <Article title='Residencia' color={usuario.person_residence ? 'success' : undefined}>
            <form ref={formResidence}>
              <div id="Residencia" className="row">
                <FormControl title='País de nacimiento' className='col-12 col-md-6' required>
                  {
                    countries
                      ? <select name="id_country_birth" className='form-select mb-3'
                        disabled={usuario.person_residence && usuario.person_residence.updatedIdCountryBirth}
                        defaultValue={
                          usuario.person_residence?.id_country_birth
                          ?? countryToId(String(usuario.paisNacimiento))
                        } required>
                        <option value=""></option>
                        {countries.map(country => <option key={`pr-${country.id}`} value={country.id}>{country.name}</option>)}
                      </select>
                      : <></>
                  }
                </FormControl>
                <FormControl title='País de residencia' className='col-12 col-md-6' required>
                  {
                    countries
                      ? <select name="id_country_residence" className='form-select mb-3'
                        defaultValue={
                          usuario.person_residence?.id_country_residence
                          ?? countryToId(String(usuario.paisResidencia))
                        } required>
                        <option value=""></option>
                        {countries.map(country => <option key={`pr-${country.id}`} value={country.id}>{country.name}</option>)}
                      </select>
                      : <></>
                  }
                </FormControl>
                <FormControl title='Dirección completa' className='col-12 col-md-6' required>
                  <input name='address' className='form-control mb-3' type="text" required
                    defaultValue={usuario.person_residence?.address ?? `${usuario.calle ?? ''} ${usuario.nroExt ?? ''} ${usuario.nroInt ?? ''}`} />
                </FormControl>
                <FormControl title='Código postal' className='col-12 col-md-6' required>
                  <input name='postal_code' className='form-control mb-3' type="text"
                    defaultValue={usuario.person_residence?.postal_code ?? usuario.cp} required />
                </FormControl>
                <FormControl title='Ciudad' className='col-12 col-md-6' required>
                  <input name='city'
                    className='form-control mb-3' type="text"
                    defaultValue={usuario.person_residence?.city ?? usuario.ciudad} required />
                </FormControl>
              </div>
              <div className="text-center">
                {errorResidence ? <p className='alert alert-danger'>{errorResidence}</p> : <></>}
                {
                  usuario.person_residence
                    ? <Button title="Guardar Cambios"
                      onLoad={onLoadResidence} onPress={formSubmitUploadPersonResidence} />
                    : <Button title="Guardar Cambios"
                      onLoad={onLoadResidence} onPress={formSubmitPersonResidence} />
                }
              </div>
            </form>
          </Article>
          : <></>
      }
      {
        usuario && usuario.id_person_residence
          ? <Article title='Identificación' color={usuario.person_identification ? 'success' : undefined}>
            <form ref={formIdentification}>
              <div className="row">
                <FormControl title='Tipo de documento' className='col-12 col-md-6' required>
                  {documentTypesFilteded.length
                    ? <select name="id_document_type" className='form-select mb-3'
                      defaultValue={usuario.person_identification?.id_document_type ?? ''} required>
                      <option value=""></option>
                      {documentTypesFilteded.map(documentType =>
                        <option key={`pr-${documentType.id}`} value={documentType.id}>
                          {documentType.name}
                        </option>
                      )}
                    </select>
                    : <></>}
                </FormControl>
                <FormControl title='Número de documento' className='col-12 col-md-6' required>
                  <input
                    defaultValue={usuario.person_identification?.document_number ?? usuario.numeroId}
                    name="document_number" className='form-control mb-3' type="text" required />
                </FormControl>
                <FormControl title='Identificación tributaria' className='col-12 col-md-6' required>
                  {taxIdentificationsFiltered
                    ? <select name="id_tax_identification" className='form-select mb-3'
                      disabled={!!usuario.person_identification}
                      defaultValue={
                        usuario.person_identification?.id_tax_identification
                        ?? ''
                      } required>
                      {taxIdentificationsFiltered.map(ti => <option key={`pr-${ti.id}`} value={ti.id}>{ti.name}</option>)}
                    </select>
                    : <></>}
                </FormControl>
                <FormControl title='Número de tributaria' className='col-12 col-md-6' required>
                  <input
                    disabled={!!usuario.person_identification}
                    value={taxNumber}
                    onChange={(e) => setTaxNumber(e.target.value)}
                    name="tax_identification_number" className='form-control mb-3' type="text" />
                </FormControl>
                <p className="alert alert-warning">
                  En caso de no colocar su informacion de impuesto, el sistema colocará un número genérico de
                  impuestos gubernamentales, recuerde que cuando la sumatoria de sus retiros a su cuenta bancaria
                  o transferencias a otros usuarios sea mayor a al 100% de su aportación, se generará un pago de impuesto.
                </p>
              </div>
              <div className="text-center">
                {errorIdentification ? <p className='alert alert-danger'>{errorIdentification}</p> : <></>}
                {
                  usuario.person_identification
                    ? <Button title="Guardar Cambios"
                      onLoad={onLoadIdentification} onPress={formSubmitUpdatePersonIdentification} />
                    : <Button title="Guardar Cambios"
                      onLoad={onLoadIdentification} onPress={formSubmitPersonIdentification} />
                }

              </div>
            </form>
          </Article>
          : <></>
      }
      {
        usuario && usuario.id_person_identification
          ? <Article title='Cuenta bancaria' color={usuario.person_bank_account ? 'success' : undefined} >
            {
              usuario.person_bank_account && !usuario.person_bank_account?.emailValidated
                ? <p className='alert alert-danger text-center'>Revisa tu corréo ({usuario.email}) para aplicar los cambios bancarios que hiciste</p>
                : <></>
            }
            <form ref={formBankAccount}>
              <div className="row m-0 p-0">
                <FormControl title='Tipo de cuenta' className='col-12 col-md-6' required>
                  {
                    bankTypes
                      ? <select name="id_bank_type" className='form-select mb-3'
                        onChange={(e) => setSelectedBankType(e.target.value)}
                        defaultValue={usuario.id_person_bank_account ? usuario.person_bank_account?.id_bank_type : ''} required>
                        <option value=""></option>
                        {bankTypes.map(bank => <option key={`bt-${bank.id}`} value={bank.id}>{bank.name}</option>)}
                      </select>
                      : <></>
                  }
                </FormControl>
                <FormControl title='SWIFT' className='col-12 col-md-6' required>
                  {
                    <input
                      defaultValue={usuario.id_person_bank_account ? usuario.person_bank_account?.swift : ''}
                      onChange={(e) => setSwift(e.target.value.replaceAll(/\D/g, ''))}
                      className='form-control mb-3' type="text" name='swift' />
                  }
                </FormControl>
                <FormControl title='Banco' className={`col-12 col-md-6 ${showCLABE || showUSDT ? 'd-none' : ''}`} required>
                  <input
                    disabled={showCLABE || showUSDT}
                    defaultValue={usuario.id_person_bank_account ? (usuario.person_bank_account?.bank ?? swift) : usuario.banco}
                    className='form-control mb-3' name="bank" type="text" />
                </FormControl>
                <FormControl title='Número de cuenta' className={`col-12 col-md-6 ${showCLABE || showUSDT ? 'd-none' : ''}`} required>
                  <input
                    value={bankAccount ?? ''}
                    disabled={showCLABE || showUSDT}
                    onChange={(e) => setBankAccount(e.target.value.replaceAll(/\D/g, ''))}
                    className='form-control mb-3' type="text" name='bank_number' />
                </FormControl>
                <FormControl title='clabe' className={`col-12 col-md-6 ${showCLABE ? '' : 'd-none'}`} required>
                  <input
                    maxLength={18}
                    value={clabe ?? ''}
                    disabled={!showCLABE}
                    onChange={(e) => setClabe(e.target.value.replaceAll(/\D/g, ''))}
                    className='form-control mb-3' type="text" name='clabe' />
                  <p className={`alert alert-danger ${(clabe.length > 0 && clabe.length !== 18) ? '' : 'd-none'}`}>la CLABE debe ser de 18 digitos</p>
                </FormControl>
                <FormControl title='banco de la cuenta CLABE' className={`col-12 col-md-6 ${showCLABE ? '' : 'd-none'}`} required>
                  {
                    banks
                      ? <select name="id_clabe_bank" className='form-select mb-3'
                        disabled={!showCLABE}
                        defaultValue={usuario.id_person_bank_account ? (usuario.person_bank_account?.id_clabe_bank ?? '') : getBankID()}>
                        <option value=""></option>
                        {banks.map(bank => <option key={`b-${bank.id}`} value={bank.id}>{bank.name}</option>)}
                      </select>
                      : <></>
                  }
                </FormControl>
                <div className={`col-12 ${showUSDT ? 'row m-0 p-0' : 'd-none'}`}>
                  <FormControl title='Dirección de la billetera (USDT)' className='col-12 col-md-6'>
                    <input
                      disabled={!showUSDT}
                      className='form-control mb-3' name="wallet" type="text"
                      defaultValue={usuario.person_bank_account?.wallet ? (wallet ?? '') : ''} placeholder='0x12345667890abcdef'
                      onChange={(e) => setWallet(e.target.value)}
                    />
                  </FormControl>
                  <FormControl title='Red' className='col-12 col-md-6'>
                    <input className='form-control mb-3' name="network" type="text" placeholder='TRC20' disabled />
                  </FormControl>
                  <div className='col-12'>
                    <p className='text-danger'>
                      Importante, si pones una dirección de una billetera, asegura de que sea de la criptomoneda
                      <b> USDT</b> seleccionando la red <b>TRC20</b>
                    </p>
                    <ul className='alert alert-warning ps-4'>
                      <li>Este tipo de retiro tiene un gasto financiero ajeno a nuestra compañia de 10%.</li>
                      <li>Todo retiro puede tardar de 1 a 3 días hábiles posterior a la fecha de corte o fecha de retiro extemporáneo.</li>
                      <li>El valor de liquidación pagado del SApoint es al tipo de cambio de venta del Dólar en nuestro sistema.</li>
                    </ul>
                  </div>
                </div>
                <p className={`alert alert-danger ${usuario?.person_residence?.id_country_residence !== 141
                  && selectedBankType === '4'
                  ? '' : 'd-none'}`}
                >La cuenta CLABE es unicamente para usuarios que residen en México</p>
                <ul className='alert alert-warning ps-4'>
                  <li>Para residentes en México favor registrar cuenta clabe.</li>
                  <li>La cuenta destino debe pertenecer al mismo titular de la cuenta.</li>
                  <li>Acepto Términos y condiciones.</li>
                  <li>Actuó a mi nombre y por propia cuenta.</li>
                  <li>Entiendo que todo cambio en contrato o términos y condiciones estará la
                    notificación en el área de noticia para ser revisado.</li>
                </ul>
              </div>
              <div className="text-center">
                {errorBankAccount ? <p className='alert alert-danger'>{errorBankAccount}</p> : <></>}
                {successBankAccount ? <p className='alert alert-success text-center'>{successBankAccount}</p> : <></>}
                <Button title="Guardar Cambios"
                  color={EColor.DEFAULT}
                  onLoad={onLoadBankAccount} onPress={formSubmitPersonBankAccount} />
              </div>
            </form>
          </Article>
          : <></>
      }
      {
        usuario?.person_bank_account && !usuario
          ? <Article title='Validación y firma' color={validacion?.status === 'COMPLETED' ? 'success' : undefined}>
            <p className='text-center'>Estado: <Badge state={getState(validacion?.status ?? '')} /></p>
            {validacion?.status !== 'COMPLETED'
              ? <>
                <p className="alert alert-danger text-left">
                  Al presionar el botón <b>Validar y Firmar</b> serás dirigido a un proveedor externo para la validación biométrica,
                  de identificación y firma de contrato, al terminar el proceso regresaras a nuestro portal, los términos y
                  condiciones y protección de datos de proveedor de validación podrá verlos en el
                  siguiente <a href="https://www.weesign.mx" className='link-as' target="_BLANK" rel="noreferrer">link</a>
                </p>
                <p className="alert alert-info">
                  En caso de obtener un mensaje de que el enlace a caduado, click en <b>Refrescar Enlace</b>
                </p>
              </>
              : <p className='alert alert-success'>La validación de identidad fue finalizada con éxito</p>
            }
            <div className="text-center">
              {
                validacion && validacion.documentURL && validacion.status !== 'COMPLETED'
                  ? <>
                    <form className="text-start">
                      <div className="form-check">
                        <input className="form-check-input" type="checkbox" value="" onChange={(e) => setCheck1(e.target.checked)} />
                        <label className="form-check-label" htmlFor="check1">
                          Acepto el <span className='link-as'>Contrato</span>.
                        </label>
                      </div>
                      <div className="form-check">
                        <input className="form-check-input" type="checkbox" value="" onChange={(e) => setCheck2(e.target.checked)} />
                        <label className="form-check-label" htmlFor="check1">
                          Acepto los <span className='link-as'>Términos y condiciones</span>.
                        </label>
                      </div>
                      <div className="form-check">
                        <input className="form-check-input" type="checkbox" value="" onChange={(e) => setCheck3(e.target.checked)} />
                        <label className="form-check-label" htmlFor="check1">
                          Acepto el <span className='link-as'>Plan de compensación y protección de datos</span>.
                        </label>
                      </div>
                    </form>
                    {
                      check1 && check2 && check3
                        ? <>
                          <Button onLoad={reloadUrl} color={EColor.GREY} title='Refrescar Enlace' onPress={handleReloadUrl} />
                          <Button title="Validar y Firmar"
                            color={EColor.DEFAULT}
                            onLoad={onLoadBankAccount}
                            onPress={() => setShowModal(true)}
                          />
                        </>
                        : <></>
                    }
                  </>
                  : <></>
              }
            </div>
          </Article>
          : <></>
      }
      <Modal title='Verificación de documento de identidad segura'
        show={showModal} onClose={() => setShowModal(false)}
        btnAceptar='Acepto'
        onAceptar={() => window.parent.postMessage(validacion?.documentURL, '*')}>
        <div className="text-center">
          <p>Verificación de documento de identidad segura Con tecnología de weesing</p>
          <h5 >Términos y consentimiento</h5>
          <p>Weesing está ayudando a verificar su identidad y prevenir el fraude. Haga clic en «Acepto» para:</p>
          <p>• Aceptar nuestros Términos de uso (incluidos los términos de arbitraje y la renuncia a acciones
            colectivas); y</p>
          <p>• Consentir la recopilación, el uso y la conservación de sus datos biométricos y personales de acuerdo con
            nuestra Política de privacidad (incluyendo para la mejora de productos y servicios
            Haga clic en «Rechazo» para cancelar la verificación de identidad.</p>
        </div>
      </Modal>
    </div>
  );
}
