import Tarjeta from 'components/templates/Tarjeta';
import React, { useState } from 'react'

type Props = {
  tabs?: Array<string>
  badges?: { [index: string]: string | number | null }
  setActiveTab?: React.Dispatch<React.SetStateAction<string>>;
  title?: string;
}

export const LayoutHeader = ({ title, tabs = [], badges = {}, setActiveTab = () => { } }: Props) => {
  const [activeTab, setActiveTabState] = useState(tabs[0] ?? '');

  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
    setActiveTabState(tab);
  };
  return (
    <header className='header'>
      <div className='container'>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <h5 className='text-center' style={{ fontSize: '30px', color: 'black' }}>{title}</h5>
        </div>
        <div className='d-flex justify-content-center align-items-center flex-wrap mb-4'>
          {Object.keys(badges).map((k, index) => <Tarjeta key={index} cantop={k} numero={badges[k]} />)}
        </div>
      </div>
      <div className='d-flex justify-content-center align-items-center'>
        <div className='text-center align-items-center'>
          <div className={`${tabs.length ? 'd-flex' : 'd-none'} justify-content-center flex-wrap mx-md-auto mt-0 pt-2 p-2`}>
            {tabs.map((tabName, index) => (
              <div
                key={index}
                style={{
                  cursor: 'pointer',
                  color: activeTab === tabName ? 'white' : 'black',
                  paddingTop: '5px',
                  paddingBottom: '5px',
                  paddingLeft: '10px',
                  paddingRight: '10px',
                  backgroundColor: activeTab === tabName ? '#5FC507' : '#fff',
                  fontSize: 13,
                  border: '2px solid #5FC507',
                  borderTopLeftRadius: index === 0 ? '8px' : '0',
                  borderBottomLeftRadius: index === 0 ? '8px' : '0',
                  borderTopRightRadius: index === tabs.length - 1 ? '8px' : '0',
                  borderBottomRightRadius: index === tabs.length - 1 ? '8px' : '0',
                  lineHeight: '30px',
                }}
                onClick={() => handleTabClick(tabName)}
              >
                <span style={{ margin: '0' }}>{tabName}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </header>
  )
}
