type Prop = {
  fill?: string
}

export default function TrendingDown({fill}: Prop) {


  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" fill={fill}>
      <path d="M640-240v-80h104L536-526 376-366 80-664l56-56 240 240 160-160 264 264v-104h80v240H640Z" />
    </svg>
  )
}