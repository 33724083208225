import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../Registration.css'
import Country from 'interfaces/ICountry';
import Platform from 'types/Platform';
import AppleSystemConsume from 'services/AppleSystemService';
import BackButtonRegister from 'components/BackButtonRegister';
import Logo from './Logo';


interface FormData {
  registrationId: string;
  nombre: string;
  apellidoPaterno: string;
  apellidoMaterno: string;
  paisCode: string;
}

interface ServerResponse {
  routes: { next: string }
  error: boolean;
  code: number;
  message: string;
}

export const DatosPersonales = () => {

  const params = new URLSearchParams(window.location.search);
  const platform = params.get('platform') as Platform;
  const newLocal = 'white';

  const navigate = useNavigate(); // Obtiene la función de navegación
  const [isLoading, setIsLoading] = useState<boolean>(false); // Agrega un estado isLoading
  const [errorMessage, setErrorMessage] = useState<string>(''); // Muestra un mensaje de error
  const [countries, setCountries] = useState<Country[]>([]);
  const [formdata, setFormdata] = useState<FormData>({
    registrationId: localStorage.getItem('registrationId') || '',
    nombre: '',
    apellidoPaterno: '',
    apellidoMaterno: '',
    paisCode: ''
  });

  useEffect(() => {
    const registrationIdParams = params.get('registrationId');
    if (registrationIdParams) {
      localStorage.setItem('registrationId', registrationIdParams)
      setFormdata({...formdata, registrationId: registrationIdParams})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    AppleSystemConsume('country')
      .get('')
      .then((data) => {
        setCountries(data.countries);
      })
      .catch((err: any) => setErrorMessage(err.message));
  }, []);

  const inputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormdata({
      ...formdata,
      [name]: value
    });
  }

  const handleClickForm = async () => {
    setIsLoading(true);

    AppleSystemConsume('registration')
      .post('data_validation', formdata, {})
      .then((res: ServerResponse) => {
        if (res.error) throw new Error(res.message);
        navigate(res.routes.next)
      })
      .catch((err: any) => setErrorMessage(err.message))
      .finally(() => setIsLoading(false));
  }

  return (
    <>
      <div className='body-registration'>
      <div className='back-button'>
          <BackButtonRegister />
        </div>
        <header className='container-logo'>
          <div className='logo'>
            <Logo id="logo_desktop" platform={platform} logoColor={newLocal} />
          </div>
        </header>
        <div className='container-register'>
          <div className='d-flex flex-column justify-content-center align-center align-items-center'>
            <h4 style={{ fontSize: '30px' }}>Datos Personales</h4>
            <p className='paso' style={{ fontSize: '17px' }}>Paso 4 de 5</p>
            <div className='centered-content'>
              <div className='form-group-datos'>
                <input
                  type="text"
                  name='nombre'
                  onChange={inputChange}
                  value={formdata.nombre}
                  placeholder='Nombre' />
              </div>
              <div className='form-group-datos'>
                <input
                  type="text"
                  name='apellidoPaterno'
                  onChange={inputChange}
                  value={formdata.apellidoPaterno}
                  placeholder='Apellido Paterno' />
              </div>
              <div className='form-group-datos'>
                <input
                  type="text"
                  name='apellidoMaterno'
                  onChange={inputChange}
                  value={formdata.apellidoMaterno}
                  placeholder='Apellido Materno' />
              </div>
              <div className='form-group-datos'>
                <select
                  required
                  name='paisCode'
                  value={formdata.paisCode}
                  onChange={inputChange}
                >
                  <option>Pais de residencia</option>
                  {countries.map((country) => (
                    <option key={country.id} value={country.iso}>
                      {country.name}
                    </option>
                  ))}
                </select>
              </div><br />
              <div className='button-register'>
                <button onClick={handleClickForm} disabled={isLoading} >
                  {isLoading ? 'Cargando...' : 'Validar'}
                </button>
              </div>
              <p className='text-danger text-center'>{errorMessage}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
