import React, { useEffect, useState } from 'react';
import Table from 'components/Table';
import Badge from 'components/Badge';
import { parseFullDate } from 'utils/parser';
import Th from 'components/tables/Th';
import { LayoutHeader } from 'components/navs/LayoutHeader';
import AppleSystemConsume from 'services/AppleSystemService';

interface Tabla {
  codigo: string,
  codeUsuario: string,
  proceso: string,
  fecha: string,
  concepto: string,
  valor: string,
  saldo: string
}

interface Fondeos {
  codUsuario: string,
  codigo: string,
  fecha: string,
  proceso: string,
  tipo: string,
  valor: string
}

interface SendData {
  message: string | undefined;
  procesos: Array<Tabla>,
  cantOperaciones: string,
  procesosFondeo: Array<Fondeos>,
  saldoActual: string
  success: boolean,
  error: boolean
}

export const EstadoCuenta = () => {

  const [datosTabla, setDatosTabla] = useState<Tabla[] | null>(null);
  const [datosFondeo, setDatosFondeo] = useState<Fondeos[] | null>(null);
  const [rowsTabla, setRowsTabla] = useState<Tabla[]>([]);
  const [rowsFondeo, setRowsFondeo] = useState<Fondeos[]>([]);
  const [message, setMessage] = useState<string>('');
  const [cantOperaciones, setCantOperaciones] = useState<string | null>(null);
  const [saldoActual, setSaldoActual] = useState<string | null>(null);
  const [textFilter, setTextFilter] = useState<string>('');
  const [textFilter2, setTextFilter2] = useState<string>('');
  const [activeTab, setActiveTab] = useState('Fondeo');

  const fetchData = () => {

    AppleSystemConsume('usuario')
    .get('estado-cuenta')
    .then((res: SendData) => {
      if (res.error) throw new Error(res.message)
      setDatosTabla(res.procesos)
      setDatosFondeo(res.procesosFondeo)
      setCantOperaciones(res.cantOperaciones);
      setSaldoActual(res.saldoActual);
    })
    .catch((err) => {
      setMessage(err.message)
      setDatosFondeo([])
      setDatosTabla([])
      setCantOperaciones('')
      setSaldoActual('')
    })
  }
  
  useEffect(() => {
    fetchData()
  }, []);

  const sortDatosFondeos = (field: string, sort: number) => {
    if (datosFondeo == null) return;
    if (field === 'FECHA') {
      const firstSort = sort === -1 ? 1 : -1;
      const secondSort = sort === -1 ? -1 : 1;
      const sorted = datosFondeo.sort((a, b) => {
        return a.fecha > b.fecha ? firstSort : secondSort;
      });
      setDatosFondeo([...sorted]);
    }

    if (field === 'VALOR') {
      const firstSort = sort === -1 ? 1 : -1;
      const secondSort = sort === -1 ? -1 : 1;
      const sorted = datosFondeo.sort((a, b) => {
        return Number(a.valor) > Number(b.valor) ? firstSort : secondSort;
      });
      setDatosFondeo([...sorted]);
    }
  }

  const sortDatosTabla = (field: string, sort: number) => {
    if (datosTabla == null) return;
    if (field === 'FECHA') {
      const firstSort = sort === -1 ? 1 : -1;
      const secondSort = sort === -1 ? -1 : 1;
      const sorted = datosTabla.sort((a, b) => {
        return a.fecha > b.fecha ? firstSort : secondSort;
      });
      setDatosTabla([...sorted]);
    }

    if (field === 'VALOR') {
      const firstSort = sort === -1 ? 1 : -1;
      const secondSort = sort === -1 ? -1 : 1;
      const sorted = datosTabla.sort((a, b) => {
        return Number(a.valor) > Number(b.valor) ? firstSort : secondSort;
      });
      setDatosTabla([...sorted]);
    }
  }

  return (
    <div className='container'>
      <LayoutHeader tabs={['Fondeo', 'Saldo']} setActiveTab={setActiveTab}
        badges={{ 'Saldo Disponible: $': saldoActual, 'Nº Operaciones:': cantOperaciones }} />
      <div className='contenido'>
        {
          activeTab === 'Fondeo'
            ? <article className='article-procesos'>
              <input
                type="text"
                onChange={(e) => setTextFilter(e.target.value)}
                className="form-control h-25 mb-3 border border-dark"
                placeholder="Buscar fondeo..."
                aria-describedby="search"
              />
              <Table
                background='#fff'
                color='bg-dark'
                rows={datosFondeo ?? []}
                pagination
                onLoad={!datosFondeo}
                textFilter={textFilter}
                renderRows={(e) => setRowsFondeo(e)}
              >
                <thead>
                  <tr style={{ fontSize: '12px' }}>
                    <Th onChange={(sort) => sortDatosFondeos('FECHA', sort)} sorted initiarSort={1}>Fecha</Th>
                    <th>Tipo de Proceso</th>
                    <Th onChange={(sort) => sortDatosFondeos('VALOR', sort)} sorted>Valor</Th>
                  </tr>
                </thead>
                <tbody>
                  {rowsFondeo.map((row, index) => (
                    <tr key={index} style={{ fontSize: '12px' }}>
                      <td>{parseFullDate(row.fecha)}</td>
                      <td><Badge state={row.proceso} /></td>
                      <td>${(+row.valor).toFixed(2)}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </article>
            : <></>
        }
        {activeTab === 'Saldo' && (
          <article className='article-data'>
            <input
              type="text"
              onChange={(e) => setTextFilter2(e.target.value)}
              className="form-control h-25 text-center"
              placeholder="Buscar saldo..."
              aria-describedby="search"
            />
            <Table
              background='#fff'
              rows={datosTabla ?? []}
              textFilter={textFilter2}
              pagination
              onLoad={!datosTabla}
              renderRows={(e) => setRowsTabla(e)}
            >
              <thead>
                <tr style={{ fontSize: '12px' }}>
                  <Th onChange={(sort) => sortDatosTabla('FECHA', sort)} sorted initiarSort={1}>Fecha</Th>
                  <th>Concepto</th>
                  <Th onChange={(sort) => sortDatosTabla('VALOR', sort)} sorted>Valor</Th>
                </tr>
              </thead>
              <tbody>
                {rowsTabla.map((row, index) => (
                  <tr key={index} style={{ fontSize: '12px' }}>
                    <td>{parseFullDate(row.fecha)}</td>
                    <td><Badge state={row.proceso} /></td>
                    <td className={+row.valor >= 0 ? 'text-success ' : 'text-danger '}>${(+row.valor).toFixed(2)}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </article>
        )}
        <p className='text-danger text-center pt-4'>{message}</p>
      </div>
    </div>
  );
};
