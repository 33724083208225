export default function TransferenciaPendiente() {

  return (
    <div className="d-flex flex-column text-center p-4 mt-4"
      style={{ backgroundColor: '#fff' }}>
      <p style={{ fontSize: 20, fontWeight: 'bolder' }}>TRANSFERENCIA PENDIENTE</p>
      <p style={{ fontSize: 15 }}>
      Se ha registrado su solicitud de transferencia. 
      Debe confirmar la misma desde su cuenta de e-mail y la misma se realizará en automático.
      </p>
    </div>
  )
}