import EProceso from 'enums/EProceso';
import EPosicion from 'enums/EPosicion';
import EProcesoFondeo from 'enums/EProcesoFondeo';

export default function Badge({ state }: { state: EProcesoFondeo | string | EPosicion | EProceso }) {
  const primary = new Set<EProcesoFondeo | EProceso | EPosicion | string>([
    EProceso.BONO_NIVEL,
    EProceso.BONO_PATROCINADOR,
    EProceso.BONO_RETROACTIVO,
    EProceso.BONO_ESTRELLA,
    EProceso.BONO_POR_COMPRAS_APPLE_GOLDEN,
    EProceso.BONO_RENDIMIENTO_RA,
    EProceso.BONO_DE_BENEFICIOS,
    EProceso.BONO_DE_NIVEL_POR_PAQUETES_DE_INVERSION,
    EProceso.BONO_EXTRAORDINARIO,
    EProceso.BONO_APPLE_GOLDEN,
    EProceso.BONO_DE_RENDIMIENTO_LA,
    EPosicion.REPRESENTANTE,
    'Saldo',
    'hotel',
    'NONE'
  ]);

  const success = new Set<EProcesoFondeo | EProceso | EPosicion | string>([
    EProceso.RENDIMIENTO_MENSUAL,
    EProceso.RENOVACION,
    EProceso.RENOVACION_DE_INSCRIPCION,
    EProceso.RENDIMIENTO,
    EPosicion.USUARIO,
    'Confirmado',
    'Si',
    'CERRADO',
    'coche',
    'ACEPTADO',
    'Fondeo',
    'COMPLETADO',
    'Entregado'
  ]);

  const danger = new Set<EProcesoFondeo | EProceso | EPosicion | string>([
    EProceso.RETIRO_EXTEMPORANEO_CONFIRMADO,
    EProceso.RETIRO_CONFIRMADO,
    EProceso.RETENCION_SOBRE_BENEFICIO,
    EProceso.RETENCION_RETIRO_EXTEMPORANEO,
    EPosicion.R_INVERSOR,
    'Rechazado',
    'No',
    'Mensaje sin responder',
    'Expirado',
    'CANCELADO',
    'Retiro',
    'BORRADOR',
    'Devuelto',
    'Cancelado'
  ]);

  const warning = new Set<EProcesoFondeo | EProceso | EPosicion | string>([
    EProceso.COMPRA_A_TREIDBLUE,
    EProceso.COMPRA_A_APPLE_GOLDEN,
    EProceso.COMPRA_DE_BOLETOS,
    EProceso.FINANCIAMIENTO_APPLE_GOLDEN,
    EProceso.FINANCIAMIENTO_DE_SERVICIO_EN_APPLE_GOLDEN,
    EProceso.FONDEO,
    EProceso.INSCRIPCION,
    EPosicion.REPRESENTANTE_INVERSOR,
    'En proceso',
    'ABIERTO',
    'Dinero',
    EProcesoFondeo.FONDEO,
    'DEVUELTO',
    'PENDIENTE'
  ]);

  const info = new Set<EProcesoFondeo | EProceso | EPosicion | string>([
    EProceso.TRANSFERENCIA_CONFIRMADA,
    EProceso.TRANSFERENCIA_DE_SALDO_DE_TREIDBLUE,
    EProceso.ADELANTO_DE_SALDO,
    EProceso.ADQUISICION_DE_MANTENIMIENTO,
    EProceso.ADQUISICION_DE_PAQUETE,
    EPosicion.RENOVACION_DE_INSCRIPCION,
    EProcesoFondeo.FONDEO_POR_TARJETA_PREPAGA,
    'vuelo',
    'LIQUIDADO'
  ]);

  const secondary = new Set<EProcesoFondeo | EProceso | EPosicion | string>([
    EProceso.PAGO_A_SISTEMA_APPLE,
    EProceso.PAGO_DE_SERVICIO_A_APPLE_GOLDEN,
    EProceso.RENOVACION,
    EProceso.RENOVACION_DE_INSCRIPCION,
    EProceso.RENDIMIENTO,
    EPosicion.REPRESENTANTE_ACTIVO,
    'NINGUNO'
  ]);

  const dark = new Set<EProcesoFondeo | EProceso | EPosicion | string>([
    EProceso.CALIFICACION_CR1,
    EProceso.CALIFICACION_CR2,
    EProceso.CALIFICACION_CR5,
    EProceso.CUOTA_DE_CANCELACION_DE_PAQUETE,
    EProceso.CONVERSION_SALDO_FONDEO_A_SALDO_DISPONIBLE,
    EProceso.COTIZACION_AGENCIA_DE_VIAJE,
    EProceso.CANCELACION_PAQUETE,
    EProceso.CANJE_TARJETA_PREPAGA_DESDE_APPLE_GOLDEN,
    EProceso.CALIFICACION,
    EPosicion.LIDER_TITANIO,
  ]);

  const light = new Set<EProcesoFondeo | EProceso | EPosicion | string>([EPosicion.R_ACTIVO]);

  const color = (colorState: EProcesoFondeo | EProceso | string): string => {
    if (primary.has(colorState)) return 'primary';

    if (success.has(colorState)) return 'success';

    if (danger.has(colorState)) return 'danger';

    if (warning.has(colorState)) return 'warning';

    if (info.has(colorState)) return 'info';

    if (secondary.has(colorState)) return 'secondary';

    if (dark.has(colorState)) return 'dark';

    if (light.has(colorState)) return 'light';

    return 'light';
  };

  return <span className={`badge text-capitalize rounded-pill alert-${color(state)}`}>{state}</span>;
}
