import { useState, useEffect } from 'react';
import FaqItem from 'components/FaqItem';
import AppleSystemConsume from 'services/AppleSystemService';

interface Faqs {
  codigo: number;
  pregunta: string;
  respuesta: string;
}

interface sendData {
  code: number;
  message: string;
  error: boolean;
  faqs: Array<Faqs>
}

export default function Faq() {

  const [message, setMessage] = useState<string>('');
  const [resFaq, setResFaq] = useState<Faqs[] | null>(null);

  const fetchFaq = () => {
    AppleSystemConsume('faq')
      .get('')
      .then((res: sendData) => {
        if (res.error) throw new Error(res.message)
        setResFaq(res.faqs)
      })
      .catch((err) => {
        setMessage(err.message)
        setResFaq([])
      })
  }

  useEffect(() => {
    fetchFaq();
  }, [])



  return (

    <div className='container bg-white'>
      {resFaq && resFaq.map((faq) => <FaqItem key={faq.codigo} faq={faq} />)}
      <p className='text-danger text-center pt-4'>{message}</p>
    </div>

  )

}