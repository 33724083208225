/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import Table from 'components/Table';
import api from 'services/AppleSystemService';
import FormContact from 'components/forms/FormContact';
import Modal from 'components/Modal';
import Contact from 'components/forms/Contact';
import Button from 'components/Button';

interface Qualification {
  codigo: number;
  codUsuario: number;
  nombre: string;
  email: string;
  celular: string;
  ciudad: string;
  pais: string;
  observaciones: string;
  presentacion: number;
  seguimiento1: number;
  seguimiento2: number;
  seguimiento3: number;
  estado: 'Pendiente' | 'Representante' | 'No intereso';
}

interface sendData {
  error: boolean;
  code: number;
  message: string;
  contacts: Array<Qualification>
}

export default function MyContacts() {

  const [resContacts, setResContacts] = useState<Qualification[] | null>(null);
  const [rowsContacts, setRowsContacts] = useState<Qualification[]>([]);
  const [message, setMessage] = useState<string>('');
  const [show, setShow] = useState<boolean>(false);
  const [showPut, setShowPut] = useState<boolean>(false);
  const [selectedContactId, setSelectedContactId] = useState<number | null>(null);
  const [reload, setReload] = useState(false);

  const handleForm = () => {
    setShow(true)
  }

  const handleFormPut = (id: number) => {
    const idContact = id
    setSelectedContactId(idContact);
    setShowPut(true);
  }

  const setContacts = () => {
    api('contacts-list')
      .get('my-contacts')
      .then((res: sendData) => {
        if (res.error) throw new Error(res.message)
        setMessage(res.message)
        setResContacts(res.contacts)
        setReload(true)
      })
      .catch((error) => {
        if (error.response) {
          console.error('Status:', error.response.status);
          console.error('Data:', error.response.data);
          console.error('Headers:', error.response.headers);
        } else {
          console.error('Error Message:', error.message);
        }
        setMessage(error.message)
        setResContacts([])
      })
  }

  const setContact = () => {

    if (selectedContactId != null) {
      api('contacts-list')
        .get(`${selectedContactId}`)
        .then((res: sendData) => {
          if (res.error) throw new Error(res.message)
          setMessage(res.message)
          setResContacts(res.contacts)
        })
        .catch((error) => {
          if (error.response) {
            console.error('Status:', error.response.status);
            console.error('Data:', error.response.data);
            console.error('Headers:', error.response.headers);
          } else {
            console.error('Error Message:', error.message);
          }
          setMessage(error.message)
          setResContacts([])
        })
    }
    return null
  }

  useEffect(() => {
    setContacts()
    setContact()
  }, [reload])

  return (

    <div className='container'>
      <h3 className='my-3 text-center'>Lista de Contactos</h3>
      <Table
        background='#fff'
        color='bg-dark'
        rows={resContacts ?? []}
        pagination
        onLoad={!resContacts}
        renderRows={(e) => setRowsContacts(e)}
      >
        <thead>
          <tr style={{ fontSize: '12px' }}>
            <th>Nombre</th>
            <th>Email</th>
            <th>Celular</th>
            <th>Ciudad</th>
            <th>Pais</th>
            <th style={{ minWidth: 140 }}>Opciones</th>
          </tr>
        </thead>
        <tbody>
          {rowsContacts.map((row, index) => (
            <tr key={index} style={{ textAlign: 'center', fontSize: '12px' }}>
              <td>{row.nombre}</td>
              <td>{row.email}</td>
              <td>{row.celular}</td>
              <td>{row.ciudad}</td>
              <td>{row.pais}</td>
              <td style={{ minWidth: 140 }}> <Button onPress={() => handleFormPut(row.codigo)} title='Ver detalle' /></td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div style={{ textAlign: 'center' }}>
        <Button title='Agregar contacto' onPress={handleForm} />
      </div>
      <div className='text-center mt-5'><span className='text-danger'>{message}</span></div>
      <Modal title='Agregar Contacto' onClose={() => { setShow(false) }} show={show}>
        <FormContact reload={() => { setContacts(); setShow(false) }} initShow={show} />
      </Modal>
      <Modal title='Editar Contacto' onClose={() => { setShowPut(false) }} show={showPut}>
        <Contact id={selectedContactId} reloadPut={() => { setContact(); setShowPut(false) }} initShow={showPut} />
      </Modal>
    </div>
  )

}