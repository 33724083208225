import { dateParse } from 'utils/parser';

export default function PaqueteAdquirido({ fecha, monto }: { fecha?: string, monto?: number }) {

  return (
    <div className="d-flex flex-column text-center p-4 mt-4"
      style={{ backgroundColor: '#fff' }}>
      <div className="d-flex gap-10 align-center m-auto">
        <p style={{ fontSize: 15, fontWeight: 600, marginRight: '2em' }}>Fecha: {dateParse(fecha)}</p>
        <p style={{ fontSize: 15, fontWeight: 600 }} className="text-success">Monto: {monto}</p>
      </div>
      <p style={{ fontSize: 20, fontWeight: 'bolder' }}>Paquete adquirido correctamente</p>
      <p style={{ fontSize: 15 }}>
        Para cualquier aclaración o soporte dirigirse a la sección de soporte de la plataforma donde puedes generar tu ticket.
      </p>
    </div>
  )
}