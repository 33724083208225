import AppleSystemConsume from 'services/AppleSystemService';
import DepositForm from 'components/forms/DepositForm';
import CalculaldoraFondeo from 'components/CalculadoraFondeo';
import React, { useEffect, useRef, useState } from 'react';
import { LATAM, ApiResponse } from 'types/Wallets';

export default function ConfirmarFondeoGlobal() {
    const [latam, setLatam] = useState<LATAM>();
    const [valor, setValor] = useState<number>(0);
    const [fechaFondeo, setFechaFondeo] = useState<string>('');
    const [moneda, setMoneda] = useState<string>('');
    const [message, setMessage] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>();

    const formRef = useRef<HTMLFormElement | null>(null);
    const fileRef = useRef<HTMLInputElement | null>(null);

    const handleFormChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        if (name === 'valor') {
            setValor(Number(value));
        } else if (name === 'fechaFondeo') {
            setFechaFondeo(value);
        } else if (name === 'moneda') {
            setMoneda(value);
        }
    };

    const getDeposit = () => {
        AppleSystemConsume('wallets')
            .get('')
            .then((res: ApiResponse) => {
                setLatam(res.WALLETS.LATAM);
            })
            .catch((err) => {
                throw new Error(err.message);
            });
    };

    useEffect(() => {
        getDeposit();
    }, []);

    const confirmFondeo = (e: React.FormEvent) => {
        e.preventDefault();
        if (!formRef.current) return;
        if (!fileRef.current) return;
        const data = new FormData(formRef.current);
        setIsLoading(true);
        AppleSystemConsume('acquire-balance')
            .post('global66-deposit', data, {})
            .then((res: ApiResponse) => {
                if (res.error !== false) setError(res.error)
                setError(res.error)
                setMessage(res.message);
            })
            .catch((err) => {
                setMessage(err.message);
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    return (
        <div className="d-flex flex-column min-vh-100 w-100 p-3">
            <p className="fs-3 text-center p-2">Via Depósito Bancario desde países de Latinoamérica</p>
            <div className='d-flex flex-column'>
                <p className="text-center fs-5">Procedimiento para enviar transferencia a nuestra cuenta en México:</p>
                <div className="fs-6 p-2 text-info bg-info bg-opacity-10 rounded">
                    {latam &&
                        <ul className='text-center list-unstyled'>
                            <li className='p-2 fs-5'>Cuenta: {latam.ACCOUNT}</li>
                            <li className='p-2 fs-5'>Nº Cuenta en pesos: {latam.ACCOUNT_NUMBER}</li>
                            <li className='p-2 fs-5'>Cuenta clabe: {latam.CUENTA_CLABE}</li>
                            <li className='p-2 fs-5'>Nombre del banco: {latam.BANK_NAME}</li>
                            <li className='p-2 fs-5'>País: {latam.COUNTRY}</li>
                        </ul>
                    }
                </div>
                <CalculaldoraFondeo />
                <DepositForm
                    onPress={confirmFondeo}
                    formRef={formRef}
                    fileRef={fileRef}
                    onChange={handleFormChange}
                    valor={valor}
                    fechaFondeo={fechaFondeo}
                    moneda={moneda}
                />
                {isLoading ? <p className='text-center fs-6 mt-3'>Cargando...</p> :
                    <p className={`text-center mt-3 fs-6 ${error ? 'text-danger' : 'text-success'}`}>{message.toUpperCase()}</p>}
            </div>
        </div>
    );
}
