import React from 'react';

function FormControl({
  className = '',
  children,
  title = '',
  required = false,
  uuid = '',
}: {
  className?: string;
  children: React.ReactNode;
  title?: string;
  required?: boolean;
  uuid?: string;
}) {
  return (
    <div className={className}>
      {required ? <span className="text-danger pe-1">*</span> : <></>}
      <label htmlFor={uuid} className="form-label">
        {title}
      </label>
      {children}
    </div>
  );
}

export { FormControl };
