// import Badge from 'components/Badge';
import Badge from 'components/Badge';
import Table from 'components/Table';
import { LayoutHeader } from 'components/navs/LayoutHeader';
import React, { useEffect, useState } from 'react';
import AppleSystemConsume from 'services/AppleSystemService';
import { parseFullDate, parsePrice } from 'utils/parser';

interface Paquete {
  codigo: number;
  fechaActivacion: string;
  valor: number;
  pagadoConDinero: number;
  estado: string;
  fechaEstado: string;
  costoSaldoComun: number;
  cancelacionPaga: number
  paquete: string;
}

interface SolicitudPaquete {
  codigo: number;
  codUsuario: number;
  fecha: string;
  monto: number;
  fechaCreacion: string;
  costoSaldoComun: number;
  pagadoFondeo: number;
}

interface sendDataPaquete {
  error: boolean;
  code: number;
  message: string;
  packages: Array<Paquete>;
}

interface sendDataRequest {
  error: boolean;
  code: number;
  message: string;
  packageRequests: Array<SolicitudPaquete>;
}

export default function Paquetes() {

  const [paquetes, setPaquetes] = useState<Paquete[] | null>(null)
  const [solicitudPaquete, setSolicitudPaquete] = useState<SolicitudPaquete[] | null>(null)
  const [rowPaquetes, setRowPaquetes] = useState<Paquete[]>([]);
  const [rowPaqueteRequest, setRowPaqueteRequest] = useState<SolicitudPaquete[]>([]);
  const [activeTab, setActiveTab] = useState('Tus Paquetes');

  const PaquetesFetch = async () => {
    await AppleSystemConsume('package')
      .get('')
      .then((res: sendDataPaquete) => {
        if (res.error) throw new Error(res.message)
        setPaquetes(res.packages)
      })
  }

  const PaquetesRequestFetch = async () => {
    await AppleSystemConsume('package')
      .get('request')
      .then((res: sendDataRequest) => {
        if (res.error) throw new Error(res.message)
        setSolicitudPaquete(res.packageRequests)
      })
  }

  useEffect(() => {
    PaquetesFetch();
    PaquetesRequestFetch();
  }, [])

  return (

    <div className='container'>
      <LayoutHeader title='Paquete' tabs={['Tus Paquetes', 'Solicitudes']} setActiveTab={setActiveTab} />
      <div>
        {activeTab === 'Tus Paquetes' ?
          <Table
            background='#fff'
            color='bg-dark'
            rows={paquetes ?? []}
            pagination
            onLoad={!paquetes}
            renderRows={(e) => setRowPaquetes(e)}
          >
            <thead>
              <tr style={{ fontSize: '12px' }}>
                <th>Fecha de activación</th>
                <th>Valor</th>
                <th>Pagado con dinero</th>
                <th>Paquete</th>
              </tr>
            </thead>
            <tbody>
              {rowPaquetes.map((row, index) => (
                <tr key={index} style={{ fontSize: '12px' }}>
                  <td>{parseFullDate(row.fechaActivacion)}</td>
                  <td style={{ color: row.valor > 0 ? 'green' : 'red' }}>
                    {parsePrice(row.valor)}
                  </td>
                  <td><Badge state={row.pagadoConDinero ? 'Si' : 'No'} /></td>
                  <td>{row.paquete}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          : <Table
            rows={solicitudPaquete ?? []}
            pagination
            onLoad={!solicitudPaquete}
            renderRows={(e) => setRowPaqueteRequest(e)}
          >
            <thead>
              <tr style={{ fontSize: '12px' }}>
                <th>Fecha</th>
                <th>Monto</th>
                {/* <th>Fecha de pago</th> */}
                <th>Pagado Fondeo</th>
                <th>Costo saldo común</th>
              </tr>
            </thead>
            <tbody>
              {rowPaqueteRequest.map((row, index) => (
                <tr key={index} style={{ fontSize: '12px' }}>
                  <td>{parseFullDate(row.fecha)}</td>
                  <td style={{ color: row.monto > 0 ? 'green' : 'red' }}>
                    {parsePrice(row.monto)}
                  </td>
                  {/* <td>{parseFullDate(row.fechaPago)}</td> */}
                  <td><Badge state={row.pagadoFondeo ? 'Si' : 'No'} /></td>
                  <td>{parsePrice(row.costoSaldoComun)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        }
      </div>
    </div>

  )
}