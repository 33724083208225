import React from 'react';

export default function TableSkeleton({ colspan = 0, thead = false, color = 'bg-dark' }:
  { colspan?: number; thead?: boolean, color?: string }) {
  return (
    <tbody>
      {thead ? (
        <tr>
          <td className={color} colSpan={colspan}>
            <span className=" mx-1 col-1"></span>
          </td>
        </tr>
      ) : (
        <></>
      )}
      <tr>
        <td colSpan={colspan}>
          <div className="d-flex placeholder-glow justify-content-between">
            <div className="placeholder placeholder-lg col me-2"></div>
            <div className="placeholder placeholder-lg col-5"></div>
            <div className="placeholder placeholder-lg col ms-2"></div>
          </div>
        </td>
      </tr>
      <tr>
        <td colSpan={colspan}>
          <div className="d-flex placeholder-glow justify-content-between">
            <div className="placeholder placeholder-lg col me-2"></div>
            <div className="placeholder placeholder-lg col-5"></div>
            <div className="placeholder placeholder-lg col ms-2"></div>
          </div>
        </td>
      </tr>
      <tr>
        <td colSpan={colspan}>
          <div className="d-flex placeholder-glow justify-content-between">
            <div className="placeholder placeholder-lg col me-2"></div>
            <div className="placeholder placeholder-lg col-5"></div>
            <div className="placeholder placeholder-lg col ms-2"></div>
          </div>
        </td>
      </tr>
      <tr>
        <td colSpan={colspan}>
          <div className="d-flex placeholder-glow justify-content-between">
            <div className="placeholder placeholder-lg col me-2"></div>
            <div className="placeholder placeholder-lg col-5"></div>
            <div className="placeholder placeholder-lg col ms-2"></div>
          </div>
        </td>
      </tr>
    </tbody>
  );
}
