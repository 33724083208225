
export default function BackIcon({fill}: {fill?:string}) {
  return (
    <div>
        <svg xmlns="http://www.w3.org/2000/svg" fill={fill} height="24" viewBox="0 -960 960 960" width="24">
            <path d="m313-440 224 224-57 56-320-320 320-320 57 56-224 224h487v80H313Z"/>
        </svg>
    </div>
  )
}
