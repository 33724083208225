import React, { useState, useEffect } from 'react';
import { PAGINATION_CONFIG } from 'configs';
import './Pagination.css';

export default function Pagination({
  items,
  itemPage = PAGINATION_CONFIG.ITEMS_PAGES,
  onPress,
}: {
  items: number;
  itemPage?: number;
  onPress: Function;
}) {
  const [pages, setPages] = useState(0);
  const [pageActive, setPageActive] = useState(0);

  useEffect(() => {
    const ceil = Math.ceil(items / itemPage);
    setPages(ceil);
    setPageActive(0);
  }, [items, itemPage]);

  const handleClick = (i: number) => {
    setPageActive(i);
    onPress(i);
  };

  const handleNavigate = (i: number) => {
    if (pageActive + 1 === pages && i === 1) {
      return;
    }

    if (pageActive === 0 && i === -1) {
      return;
    }

    setPageActive(pageActive + i);
    onPress(pageActive + i);
  };

  return (
    <nav title="ver paginas" className={items ? 'd-flex justify-content-end flex-column align-items-end p-2' : 'd-none'}>
      <ul className="pagination">
        <li className={pageActive === 0 ? 'page-item cursor-pointer disabled' : 'page-item cursor-pointer'}>
          <span className="page-link" onClick={() => handleNavigate(-1)}>
            Anterior
          </span>
        </li>
        {[...Array(pages)].map((row, i) => (
          <li
            key={i + 1}
            title={`ver página ${i + 1}`}
            // eslint-disable-next-line max-len
            className={`page-item cursor-pointer ${pageActive === i ? 'active' : ''} ${(pageActive + 5 > i && pageActive - 5 < i) || i === 0 ? '' : 'd-none'
              }`}
            onClick={() => handleClick(i)}
          >
            <span className="page-link border-0">{i + 1}</span>
          </li>
        ))}
        <li className={pageActive + 1 === pages ? 'page-item cursor-pointer disabled' : 'page-item cursor-pointer'}>
          <span className="page-link" onClick={() => handleNavigate(1)}>
            Siguiente
          </span>
        </li>
      </ul>
      <p className="d-block text-dark">{items.toLocaleString()} elementos encontrados</p>
    </nav>
  );
}
