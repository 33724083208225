import { useEffect } from 'react';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { set } from 'store/reducers/tipoCambio.reducer';
import { RootState } from 'store';

export default function useTipoCambio() {
  const dispatch = useAppDispatch();
  const tipoCambio = useAppSelector((state: RootState) => state.tipoCambio);

  useEffect(() => {
    if (tipoCambio.usd.compra) {
      return;
    }

    dispatch(
      set({
        colombia: {
          compra: 132.23,
          venta: 123.34,
        },
        mexico: {
          compra: 14.13,
          venta: 12.32,
        },
        argentina: {
          compra: 100.3,
          venta: 99.23,
        },
        chile: {
          compra: 459.34,
          venta: 523.43,
        },
        peru: {
          compra: 3.1,
          venta: 3.65,
        },
        bolivia: {
          compra: 5.43,
          venta: 5.63,
        },
        euro: {
          compra: 0.78,
          venta: 0.98,
        },
        usd: {
          compra: 0.98,
          venta: 1.05,
        },
      })
    );
  }, [dispatch, tipoCambio]);

  return tipoCambio;
}
