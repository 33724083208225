/* eslint-disable max-len */
import { useEffect, Suspense, useState } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import './UserPanel.css';
import UseTipoCuenta from 'hooks/useTipoCuenta.hook';
import ButtonIcon from 'components/ButtonIcon';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import useTipoCambio from 'hooks/useTipoCambio.hook';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';
import { iso3ToCountry } from 'utils/parser';
import logo from '../assets/images/logo-light.png';
import logoAS from '../assets/images/logo_AG_fondo_blanco.png';
import logoTB from '../assets/images/logo_TB.png';


export default function UserLayout() {
  const location = useLocation();

  const { userType } = UseTipoCuenta();
  const [menuOpen, setMenuOpen] = useState('');
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [minimizeMenu, setMinimizeMenu] = useState<boolean>(false);
  const tipoCambio = useTipoCambio();
  const { user, posicion } = useAppSelector((state: RootState) => state.auth);

  useEffect(() => {
    if (menuOpen === 'reports') setMinimizeMenu(false);
  }, [menuOpen]);

  useEffect(() => {
    if (location.pathname.includes('/user-panel/reportes')) setMenuOpen('reports');
    else setMenuOpen('');
    setShowMenu(false);
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, [location.key, location.pathname]);

  if (!userType) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
        <div className="spinner-border color-as" style={{ width: '3em', height: '3em' }} role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
        <span className="ps-2 color-as">Cargando...</span>
      </div>
    );
  }

  return (
    <div style={{ background: 'linear-gradient(to bottom, #1E1E1E 0%, #203412 50%, #1E1E1E 100%' }}>
      <nav className={'logos-nav'}>
        <div style={{ gridArea: 'navmenu', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 5 }}>
          <ButtonIcon onPress={() => setMinimizeMenu(menuOpen === 'reports' ? false : !minimizeMenu)} icon={faBars} />
          <img src={logo} width={150} alt="logo applesystem" />
        </div>
        <div style={{ gridArea: 'navplatforms' }}>
          <a href={'https://applegolden.com.mx/'}><img style={{ marginLeft: '3em' }} src={logoAS} width={150} alt="logo applesystem" /></a>
          <a href="https://www.treidblue.com/"><img style={{ marginLeft: '1em' }} src={logoTB} width={150} alt="logo applesystem" /></a>
        </div>
        <div style={{ gridArea: 'exchanges', display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#fff' }}>
          <span> <b>SApoint</b> compra: {user?.paisResidencia}${tipoCambio[iso3ToCountry(user?.paisResidencia ?? '')].compra} | venta: {user?.paisResidencia}${tipoCambio[iso3ToCountry(user?.paisResidencia ?? '')].venta}</span>
        </div>
        <div className='info-user' style={{ gridArea: 'infouser' }}>
          <img src="https://www.sistemaapple.com.mx/assets/files/perfil/test.png"
            alt="foto de perfil"
            className="rounded-circle" style={{ maxWidth: '3em', maxHeight: '3em', gridArea: 'selfie' }} />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span className='text-white text-capitalize'>{user?.nombreUsuario}</span>
            <span style={{ color: '#ccc' }}>{posicion}</span>
          </div>
        </div>
      </nav>
      <div className={`container-layout ${minimizeMenu ? 'minimize' : ''}`}>
        <nav>
          <div className="nav-menu text-end m-2">
            <ButtonIcon onPress={() => setShowMenu(!showMenu)} icon={faBars} />
          </div>
          <ul className={`nav-link ${showMenu ? 'show-nav' : ''}`}>
            <li className={location.pathname === '/user-panel' ? 'active' : ''}>
              <Link to={'/user-panel'}>
                <img src="https://www.sistemaapple.com.mx/assets/img/icons/home.png"
                  alt='home icon'
                  className="ms-2"
                  style={{ width: '1em', height: '1em' }}
                />
                <span>Inicio | Panel</span>
              </Link>
            </li>
            <li onClick={() => setMenuOpen('reports')}
              className={location.pathname.includes('/user-panel/reportes') || menuOpen === 'reports' ? 'active' : ''}>
              <div className='cursor-pointer'>
                <img src="https://www.sistemaapple.com.mx/assets/img/icons/circle_portion.png"
                  alt='reportes icon'
                  className="ms-2"
                  style={{ width: '1em', height: '1em' }}
                />
                <span>Reportes</span></div>
            </li>
            <li className={`sub-li ${location.pathname.includes('/user-panel/reportes') || menuOpen === 'reports' ? 'active' : 'd-none'}`}>
              <Link to={'/user-panel/reportes/retiros'}><span>Retiros</span></Link>
            </li>
            <li className={`sub-li ${location.pathname.includes('/user-panel/reportes') || menuOpen === 'reports' ? 'active' : 'd-none'}`}>
              <Link to={'/user-panel/reportes/saldos'}><span>saldos</span></Link>
            </li>
            <li className={`sub-li ${location.pathname.includes('/user-panel/reportes') || menuOpen === 'reports' ? 'active' : 'd-none'}`}>
              <Link to={'/user-panel/reportes/paquetes'}><span>paquetes</span></Link>
            </li>
            <li className={`sub-li ${location.pathname.includes('/user-panel/reportes') || menuOpen === 'reports' ? 'active' : 'd-none'}`}>
              <Link to={'/user-panel/reportes/transferencias'}><span>Transferencias</span></Link>
            </li>
            <li className={location.pathname.includes('/user-panel/representantes-en-red') ? 'active' : ''}>
              <Link to={'/user-panel/representantes-en-red'}>
                <img src="https://www.sistemaapple.com.mx/assets/img/icons/red.png"
                  alt='reportes icon'
                  className="ms-2"
                  style={{ width: '1em', height: '1em' }}
                />
                <span>Representantes en Red</span>
              </Link>
            </li>
            <li className={location.pathname.includes('/user-panel/tus-comisiones') ? 'active' : ''}>
              <Link to={'/user-panel/tus-comisiones'}>
                <img src="https://www.sistemaapple.com.mx/assets/img/icons/percent.png"
                  alt='reportes icon'
                  className="ms-2"
                  style={{ width: '1em', height: '1em' }}
                />
                <span>Tus Comisiones</span>
              </Link>
            </li>
            <li className={location.pathname.includes('/user-panel/compras-servicios') ? 'active' : ''}>
              <Link to={'/user-panel/compras-servicios'}>
                <img src="https://www.sistemaapple.com.mx/assets/img/icons/cart.png"
                  alt='reportes icon'
                  className="ms-2"
                  style={{ width: '1em', height: '1em' }}
                />
                <span>Tus Compras/Servicios</span>
              </Link>
            </li>
            <li className={location.pathname.includes('/user-panel/estadocuenta') ? 'active' : ''}>
              <Link to={'/user-panel/estadocuenta'}>
                <img src="https://www.sistemaapple.com.mx/assets/img/icons/statistics.png"
                  alt='reportes icon'
                  className="ms-2"
                  style={{ width: '1em', height: '1em' }}
                />
                <span>Tu Estado de Cuenta</span>
              </Link>
            </li>
            <li className={location.pathname.includes('/user-panel/lista-contactos') ? 'active' : ''}>
              <Link to={'/user-panel/lista-contactos'}>
                <img src="https://www.sistemaapple.com.mx/assets/img/icons/agenda.png"
                  alt='reportes icon'
                  className="ms-2"
                  style={{ width: '1em', height: '1em' }}
                />
                <span>Lista de Contactos</span>
              </Link>
            </li>
            <li className={location.pathname.includes('/user-panel/tus-calificaciones') ? 'active' : ''}>
              <Link to={'/user-panel/tus-calificaciones'}>
                <img src="https://www.sistemaapple.com.mx/assets/img/icons/star.png"
                  alt='reportes icon'
                  className="ms-2"
                  style={{ width: '1em', height: '1em' }}
                />
                <span>Tus Calificaciones</span>
              </Link>
            </li>
            <li className={location.pathname.includes('/user-panel/preguntas-frecuentes') ? 'active' : ''}>
              <Link to={'/user-panel/preguntas-frecuentes'}>
                <img src="https://www.sistemaapple.com.mx/assets/img/icons/question.png"
                  alt='reportes icon'
                  className="ms-2"
                  style={{ width: '1em', height: '1em' }}
                />
                <span>Preguntas Frecuentes</span>
              </Link>
            </li>
            <li className={location.pathname.includes('/user-panel/noticias') ? 'active' : ''}>
              <Link to={'/user-panel/noticias'}>
                <img src="https://www.sistemaapple.com.mx/assets/img/icons/red.png"
                  alt='reportes icon'
                  className="ms-2"
                  style={{ width: '1em', height: '1em' }}
                />
                <span>Notificas</span>
              </Link>
            </li>
          </ul>
        </nav>
        <div className="body-layout" style={{ background: '#eaf6ea' }}>
          <Suspense>
            <Outlet />
          </Suspense>
        </div>
      </div>
    </div>
  );
}