
import Button from 'components/Button';
import React, { useEffect, useState } from 'react';
import api from 'services/AppleSystemService';

interface ContactFormState {
  codigo?: number;
  nombre: string;
  celular: string;
  email: string;
  pais: string;
  ciudad: string;
  observaciones: string;
  presentacion: number;
  seguimiento1: number;
  seguimiento2: number;
  seguimiento3: number;
  estado: 'Pendiente' | 'Representante' | 'No intereso';
}
interface ResContactProps {
  reloadPut?: Function;
  setReload?: Function;
  initShow?: boolean;
  id: number | null;
}

interface sendData {
  error: boolean;
  code: number;
  message: string;
  contacts: ContactFormState
}

const Contact: React.FC<ResContactProps> = ({reloadPut = () => { }, id = null, setReload = () => {} }) => {

  const [formState, setFormState] = useState<ContactFormState>({
    nombre: '',
    celular: '',
    email: '',
    pais: '',
    ciudad: '',
    observaciones: '',
    presentacion: 0,
    seguimiento1: 0,
    seguimiento2: 0,
    seguimiento3: 0,
    estado: 'Pendiente' || 'Representante' || 'No Intereso'
  });

  const [message, setMessage] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  

  // eslint-disable-next-line no-undef
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value,
    });
  };
  // eslint-disable-next-line no-undef
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.checked,
    });
  };

  useEffect(() => {
    if (!id) return;
    setIsLoading(true)
    api('contacts-list')
      .get(id.toString())
      .then((res) => {
        if (res.error) throw new Error(res.message)
        setFormState(res.contact);
        setIsLoading(false) 
      })
      .catch((error) => {
        setMessage(error.message)
        setIsLoading(false) 
      });
  }, [id])

  const handleSubmit = () => {
    api('contacts-list')
      .put(`${id}`, formState, {})
      .then((res: sendData) => {
        if (res.error) throw new Error(res.message)
        setMessage(res.message)
        reloadPut()
        setReload();
      })
      .catch((error) => {
        setMessage(error.message)
      })
  }
  

  return (
    <>
      {isLoading ? (
        <span>Cargando datos...</span>
      )

        : (
          <form style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
            <div className='text-center'>Datos del Contacto {formState.nombre}</div>
            <div className="d-flex flex-column">
              <div className="d-flex flex-row p-4">
                <input type="text"
                  name='nombre'
                  value={formState.nombre}
                  onChange={(e) => handleInputChange(e)}
                  className="form-control m-2 text-center"
                  placeholder="Nombre"
                  aria-label="name" />
                <input type="number"
                  name='celular'
                  value={formState.celular}
                  onChange={(e) => handleInputChange(e)}
                  className="form-control m-2 text-center"
                  placeholder="Celular"
                  aria-label="phone" />
              </div>
              <div className="d-flex flex-row p-4">
                <input type="email"
                  name='email'
                  value={formState.email}
                  onChange={(e) => handleInputChange(e)}
                  className="form-control m-2 text-center"
                  placeholder="Email"
                  aria-label="email" />
                <input type="text"
                  name='pais'
                  value={formState.pais}
                  onChange={(e) => handleInputChange(e)}
                  className="form-control m-2 text-center"
                  placeholder="Pais"
                  aria-label="country" />
              </div>
              <div className="d-flex flex-row p-4">
                <input type="text"
                  name='ciudad'
                  value={formState.ciudad}
                  onChange={(e) => handleInputChange(e)}
                  className="form-control m-2 text-center"
                  placeholder="Ciudad"
                  aria-label="city" />
                <select
                  aria-label="Default select example"
                  className="form-select m-2 text-center"
                  defaultValue={'Estado'}
                >
                  <option>Estado</option>
                  <option value={formState.estado}>Pendiente</option>
                  <option value={formState.estado}>Representado</option>
                  <option value={formState.estado}>No intereso</option>
                </select>
              </div>
            </div>
            <div className="d-flex flex-row p-4">
              <textarea
                className="form-control m-2 text-center"
                name='observaciones'
                value={formState.observaciones}
                onChange={(e) => handleInputChange(e)}
                aria-label="With textarea"
                placeholder="Observaciones"></textarea>
            </div>
            {/* check */}
            <div className="d-flex m-2">
              <label
                htmlFor=""
                className="form-label"
                style={{ color: 'black', marginRight: '10px' }}>Presentación</label>
              <input
                className="form-check-input me-2"
                type="checkbox"
                id="checkboxNoLabel"
                name='presentacion'
                checked={Boolean(formState.presentacion)}
                onChange={(e) => handleCheckboxChange(e)}
                aria-label="..." />
              <label
                htmlFor=""
                className="form-label"
                style={{ color: 'black', marginRight: '10px' }}>Seguimiento 1:</label>
              <input
                className="form-check-input me-2"
                type="checkbox"
                id="checkboxNoLabel"
                name='seguimiento1'
                checked={Boolean(formState.seguimiento1)}
                onChange={(e) => handleCheckboxChange(e)}
                aria-label="..." />
              <label
                htmlFor=""
                className="form-label"
                style={{ color: 'black', marginRight: '10px' }}>Seguimiento 2:</label>
              <input
                className="form-check-input me-2"
                type="checkbox"
                id="checkboxNoLabel"
                name='seguimiento2'
                checked={Boolean(formState.seguimiento2)}
                onChange={(e) => handleCheckboxChange(e)}
                aria-label="..." />
              <label
                htmlFor=""
                className="form-label"
                style={{ color: 'black', marginRight: '10px' }}>Seguimiento 3:</label>
              <input
                className="form-check-input me-2"
                type="checkbox"
                id="checkboxNoLabel"
                name='seguimiento3'
                checked={Boolean(formState.seguimiento3)}
                onChange={(e) => handleCheckboxChange(e)}
                aria-label="..." />
            </div>
            <p className='text-center text-danger'>{message}</p>
            <div style={{ alignItems: 'center', justifyContent: 'center' }}>
              <Button onPress={handleSubmit} title='Editar contacto' />
            </div>
          </form>


        )

      }
    </>

  )
}

export default Contact