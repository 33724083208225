import React from 'react';
import './template.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

interface Prop {
  numero: string | number | null;
  cantop: string;
}

const Tarjeta = ({ cantop, numero }: Prop) => {
  // const showNumber = numero !== '' ? numero : '0';

  return (
    <span className='d-flex flex-row article-tarjeta px-3 mx-3'>
      <div style={{marginRight: '10px'}}>
        {cantop} 
      </div>
     {numero !== null ? numero : <FontAwesomeIcon icon={faSpinner} className="spinner animaton-rotate-ing me-2" />}
    </span>
  );
};

export default Tarjeta
