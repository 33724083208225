import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../Registration.css';
import Platform from 'types/Platform';
import Country from 'interfaces/ICountry';
import AppleSystemConsume from 'services/AppleSystemService';
import BackButtonRegister from 'components/BackButtonRegister';
import Logo from './Logo';


interface Phone {
  registrationId: string
  code: string
  phone: string
}
interface ServerResponse {
  routes: { next: string }
  error: boolean;
  code: number;
  message: string;
}

export const Celular = () => {

  const params = new URLSearchParams(window.location.search);
  const platform = params.get('platform') as Platform;

  const newLocal = 'white';

  const navigate = useNavigate(); // Obtiene la función de navegación
  const [isLoading, setIsLoading] = useState<boolean>(false); // Agrega un estado isLoading
  const [errorMessage, setErrorMessage] = useState<string>(''); // Muestra un mensaje de error
  const [countries, setCountries] = useState<Country[]>([]);
  const [phone, setPhone] = useState<Phone>({
    registrationId: localStorage.getItem('registrationId') || '',
    code: '',
    phone: ''
  })
  useEffect(() => {
    const registrationIdParams = params.get('registrationId');
    if (registrationIdParams) {
      localStorage.setItem('registrationId', registrationIdParams)
      setPhone({...phone, registrationId: registrationIdParams})
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    AppleSystemConsume('country')
    .get('')
    .then((data) => {
      setCountries(data.countries);
    })
    .catch((err: any) => setErrorMessage(err.message));
  }, []);

  const handleCountryChange = (code: string) => {
    setPhone({
      ...phone,
      code
    })
  };

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement | HTMLInputElement>) => {
    const { name, value } = e.target;
    setPhone({
      ...phone,
      [name]: value
    })
  };

  const savePhone = async () => {
    if (!phone.code) {
      setErrorMessage('por favor, selecciona una caracteristica');
      return;
    }

    if (!phone.phone) {
      setErrorMessage('por favor, escribe un numero');
      return;
    }
    setIsLoading(true)

    AppleSystemConsume('registration')
    .post('phone_validation', { phone: `+${phone.code}${phone.phone}`, registrationId: phone.registrationId}, {})
    .then((res: ServerResponse) => {
      if(res.error) throw new Error(res.message);
      navigate(res.routes.next);
    })
    .catch((err: any) => {
      setErrorMessage(err.message);
    })
    .finally(() => setIsLoading(false));
  }

  return (
    <>
      <div className='body-registration'>
      <div className='back-button'>
          <BackButtonRegister />
        </div>
        <header className='container-logo'>
          <div className='logo'>
            <Logo id="logo_desktop" platform={platform} logoColor={newLocal} />
          </div>
        </header>
        <div className='container-register'>
          <div className='texto'>
            <h4 style={{fontSize: '30px'}}>Telefono Celular</h4>
            <p style={{fontSize: '17px'}}>Paso 3 de 5</p>
            <div className='centered-content'>
              <div className='form-group-phone'>
                <div className='select-country'>
                  <select
                    className="form-select"
                    required
                    value={phone.code}
                    onChange={(e) => handleCountryChange(e.target.value)}
                  >
                    <option></option>
                    {countries.map((country) => (
                      <option key={country.id} value={country.phone_code}>
                        {`+${country.phone_code} ${country.name}`}
                      </option>
                    ))}
                  </select>
                  <input
                    type="tel"
                    placeholder='Telefono Celular'
                    name='phone'
                    value={phone.phone}
                    onChange={handlePhoneChange}
                  />
                </div>
                <p className='centered-text'>
                  Deberás tener acceso a tu celular ya que deberás ingresar un código que enviaremos.
                </p>
              </div>
            </div>
            <div className='button-register'>
              <button onClick={savePhone} disabled={isLoading}>
                {isLoading ? 'Cargando...' : 'Validar'}
              </button>
            </div>
            <p className='text-danger'>{errorMessage}</p>
          </div>
        </div>
      </div>
    </>
  )
}
