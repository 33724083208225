import { useEffect } from 'react';
import AppleSystemConsume from 'services/AppleSystemService';

export default function FormRecoveryPassword() {
  const params = new URLSearchParams(window.location.search);
  const uuid = params.get('uuid');
  const id = params.get('id');

  useEffect(() => {
    if (!uuid || !id) return;
    AppleSystemConsume('person-bank-account')
      .post('verify', { uuid, id }, {})
      .then((res) => {
        if (res.error) {
          window.location.href = 'https://www.sistemaapple.com.mx/';
          return;
        }
        window.location.href = 'https://www.sistemaapple.com.mx/mis-datos/validar-identidad';
      })
    // window.location.href
  }, [uuid, id]);

  return (
    <div>
      <h1>verificando datos...</h1>
    </div>
  )
}
