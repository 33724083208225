import Button from 'components/Button';
import Modal from 'components/Modal';
import Table from 'components/Table';
import Th from 'components/tables/Th';
import React, { useEffect, useState } from 'react';
import AppleSystemConsume from 'services/AppleSystemService';

interface arbol {
  codigo: string
  nombreUsuario: string
  name: string
  email: string
  patrocinador: string
  inversion: string
  level: string
  ultimoPaquete: string
  paqueteBaseFecha: string
  paqueteBaseValor: string
}

interface paquete {
  codigo: string,
  codUsuario: string,
  fechaActivacion: string,
  valor: string,
  pagadoConDinero: string,
  estado: string,
  fechaEstado: string,
  costoSaldoComun: string,
  cancelacionPaga: string
}

interface sendData {
  message: string
  error: boolean
  code: string
  arbol: Array<arbol>
}

interface sendDataPaquete {
  nombreUsuario: string
  message: string
  error: boolean
  code: string
  paquetes: Array<paquete>
}

function RepresentantesRed() {

  const [arbolTable, setArbolTable] = useState<arbol[] | null>(null);
  const [verPaquete, setVerPaquete] = useState<paquete[] | null>(null);
  const [show, setShow] = useState<boolean>(false);
  const [nombreUsuario, setNombreUsuario] = useState<string | null>(null);
  const [rowsArbol, setRowsArbol] = useState<arbol[]>([]);
  const [rowsPaquete, setRowsPaquete] = useState<paquete[]>([]);
  const [message, setMessage] = useState('');
  const [textFilter, setTextFilter] = useState<string>('');

  useEffect(() => {
    AppleSystemConsume('usuario')
      .get('representantes-en-red')
      .then((res: sendData) => {
        if (res.error) throw new Error(res.message)
        setArbolTable(res.arbol)
      })
      .catch((err) => {
        setMessage(err.message)
      })
  }, [])

  const sortDatosArbol = (field: string, sort: number) => {
    if (arbolTable == null) return;
    if (field === 'NOMBRE COMPLETO') {
      const firstSort = sort === -1 ? 1 : -1;
      const secondSort = sort === -1 ? -1 : 1;
      const sorted = arbolTable.sort((a, b) => {
        return a.name > b.name ? firstSort : secondSort;
      });
      setArbolTable([...sorted]);
    }

    if (field === 'NOMBRE DE USUARIO') {
      const firstSort = sort === -1 ? 1 : -1;
      const secondSort = sort === -1 ? -1 : 1;
      const sorted = arbolTable.sort((a, b) => {
        return Number(a.nombreUsuario) > Number(b.nombreUsuario) ? firstSort : secondSort;
      });
      setArbolTable([...sorted]);
    }

    if (field === 'EMAIL') {
      const firstSort = sort === -1 ? 1 : -1;
      const secondSort = sort === -1 ? -1 : 1;
      const sorted = arbolTable.sort((a, b) => {
        return Number(a.email) > Number(b.email) ? firstSort : secondSort;
      });
      setArbolTable([...sorted]);
    }

    if (field === 'NIVEL') {
      const firstSort = sort === -1 ? 1 : -1;
      const secondSort = sort === -1 ? -1 : 1;
      const sorted = arbolTable.sort((a, b) => {
        return Number(a.level) > Number(b.level) ? firstSort : secondSort;
      });
      setArbolTable([...sorted]);
    }

    if (field === 'TOTAL') {
      const firstSort = sort === -1 ? 1 : -1;
      const secondSort = sort === -1 ? -1 : 1;
      const sorted = arbolTable.sort((a, b) => {
        return Number(a.inversion) > Number(b.inversion) ? firstSort : secondSort;
      });
      setArbolTable([...sorted]);
    }
  }

  const handleId = (id: string) => {
    const usuarioSeleccionado = arbolTable?.find((usuario) => usuario.codigo === id);
    setNombreUsuario(usuarioSeleccionado?.nombreUsuario || null);
    setVerPaquete(null)
    setShow(true)
    AppleSystemConsume('representantes-en-red')
      .get(id)
      .then((res: sendDataPaquete) => {
        if (res.error) throw new Error(res.message);
        setVerPaquete(res.paquetes)
        console.log(res)
      })
      .catch((err) => {
        setMessage(err.message)
      })
  }

  return (
    <div className='container my-3'>
      <h3 className='text-center'>Representantes en Red</h3>
      <input
        type="text"
        onChange={(e) => setTextFilter(e.target.value)}
        className="form-control my-3 border border-dark"
        placeholder="Buscar..."
        aria-describedby="search"
      />
      <Table
        color='bg-dark'
        rows={arbolTable ?? []}
        pagination
        onLoad={!arbolTable}
        textFilter={textFilter}
        renderRows={(e) => setRowsArbol(e)}
      >
        <thead>
          <tr style={{ fontSize: '12px' }}>
            <Th onChange={(sort) => sortDatosArbol('NOMBRE DE USUARIO', sort)} sorted>Nombre de Usuario</Th>
            <Th onChange={(sort) => sortDatosArbol('NOMBRE COMPLETO', sort)} sorted>Nombre Completo</Th>
            <Th onChange={(sort) => sortDatosArbol('EMAIL', sort)} sorted>Email</Th>
            <th>Patrocinador</th>
            <Th onChange={(sort) => sortDatosArbol('NIVEL', sort)} sorted>Nivel</Th>
            <Th onChange={(sort) => sortDatosArbol('TOTAL', sort)} sorted>Total de Inversión</Th>
            <th>Fecha ultimo paquete</th>
            <th>Fecha paquete base</th>
            <th>Valor paquete base</th>
            <th>Ver Paquetes</th>
          </tr>
        </thead>
        <tbody>
          {rowsArbol.map((row, index) => (
            <tr key={index} style={{ fontSize: '12px', fontWeight: '400' }}>
              <td>{row.nombreUsuario}</td>
              <td>{row.name}</td>
              <td>{row.email}</td>
              <td>{row.patrocinador}</td>
              <td>{row.level}</td>
              <td>{row.inversion}</td>
              <td>{row.ultimoPaquete}</td>
              <td>{row.paqueteBaseFecha}</td>
              <td>{row.paqueteBaseValor}</td>
              <td><Button onPress={() => { handleId(row.codigo) }} title='Ver' /></td>
            </tr>
          ))}
        </tbody>
      </Table>
      <span className='text-danger text-center pt-4'>{message}</span>
      <Modal title={`Paquetes: ${nombreUsuario}`} onClose={() => { setShow(false) }} show={show}>
        <Table
          rows={verPaquete ?? []}
          onLoad={!verPaquete}
          renderRows={(e) => setRowsPaquete(e)}
        >
          <thead>
            <tr style={{ fontSize: '12px' }}>
              <th>Nombre Paquete</th>
              <th>Fecha de Activacion</th>
              <th>Valor</th>
            </tr>
          </thead>
          <tbody>
            {rowsPaquete.map((row, index) => (
              <tr key={index} style={{ fontSize: '12px' }}>
                <td>{`${nombreUsuario} ${index}`}</td>
                <td>{row.fechaActivacion}</td>
                <td>{row.valor}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Modal>
    </div>

  )
}

export default RepresentantesRed