import { useEffect, useState } from 'react';

interface TipoCambio {
    success: boolean;
    SERVER_INFO: Record<string, unknown>;
    changes: {
        'USA-Compra': number;
        'COP-Compra': number;
        'MXN-Compra': number;
        'ARG-Compra': number;
        'CLP-Compra': number;
        'PEN-Compra': number;
        'BOB-Compra': number;
        'EUR-Compra': number;
        'CRC-Compra': number;
        'DOP-Compra': number;
    };
}

export default function TiposDeCambio() {
    
    const [cambio, setCambio] = useState<TipoCambio | null>(null);
    
    const fetchTipoCambio = async () => {
        const res = await fetch('https://api.treidblue.com/tipo-cambio');
        if (!res.ok) {
            throw new Error(`HTTP error! status: ${res.status}`);
        }
        const data = await res.json();
        setCambio(data);
    }

    useEffect(() => {
        fetchTipoCambio();
    }, [])

    return (
        <div className="d-inline-flex flex-column"
            style={{ background: 'linear-gradient(to right, #FFFBD0, #FDF8C2)', padding: '10px' }}>
            <p style={{ fontWeight: 'bold' }}>Cambio USD</p>
            <p>-SApoint a la compra: {cambio?.changes['USA-Compra']}</p>
            <p style={{ fontWeight: 'bold' }}>Cambio Colombia</p>
            <p>-SApoint a la compra: {cambio?.changes['COP-Compra']}</p>
            <p style={{ fontWeight: 'bold' }}>Cambio Mexico</p>
            <p>-SApoint a la compra: {cambio?.changes['MXN-Compra']}</p>
            <p style={{ fontWeight: 'bold' }}>Cambio Argentina</p>
            <p>-SApoint a la compra: {cambio?.changes['ARG-Compra']}</p>
            <p style={{ fontWeight: 'bold' }}>Cambio Chile</p>
            <p>-SApoint a la compra: {cambio?.changes['CLP-Compra']}</p>
            <p style={{ fontWeight: 'bold' }}>Cambio Perú</p>
            <p>-SApoint a la compra: {cambio?.changes['PEN-Compra']}</p>
            <p style={{ fontWeight: 'bold' }}>Cambio Bolivia</p>
            <p>-SApoint a la compra: {cambio?.changes['BOB-Compra']}</p>
            <p style={{ fontWeight: 'bold' }}>Cambio Euro</p>
            <p>-SApoint a la compra: {cambio?.changes['EUR-Compra']}</p>
        </div>
    )
}