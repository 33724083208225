import { faCheck, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

export default function Toast({ message, state = true, show, onClose }: { message: string; state: boolean; show: boolean; onClose: Function }) {
  return (
    <div
      className={`${state ? 'bg-as' : 'bg-danger text-white'} ${show ? 'show' : ''} toast align-items-center border-0 my-1`}
      style={{ zIndex: 99 }}
    >
      <div className="d-flex">
        <div className="toast-body">
          {state ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faTriangleExclamation} />}
          <span className="ms-2">{message}</span>
        </div>
        <button type="button" className="btn-close btn-close-white me-2 m-auto" onClick={() => onClose()}></button>
      </div>
    </div>
  );
}
