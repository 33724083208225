import React, { createContext, useState, useContext, ReactNode } from 'react';

// interfaz
interface RegistrationContextData {
  registrationId: string | null;
  // eslint-disable-next-line no-unused-vars
  setRegistrationIdValue: (id: string | null) => void;
}

//  contexto
const RegistrationContext = createContext<RegistrationContextData | undefined>(undefined);

// provider
export const RegistrationProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [registrationId, setRegistrationId] = useState<string | null>(null);

  const setRegistrationIdValue = (id: string | null) => {
    setRegistrationId(id);
  };

  return (
    <RegistrationContext.Provider value={{ registrationId, setRegistrationIdValue }}>
      {children}
    </RegistrationContext.Provider>
  );
};

// hook
export const useRegistration = (): RegistrationContextData => {
  const context = useContext(RegistrationContext);

  if (!context) {
    throw new Error('useRegistration debe utilizarse dentro de un RegistrationProvider');
  }

  return context;
};
