import React, { useEffect, useState } from 'react';
import Table from 'components/Table';
import { LayoutHeader } from 'components/navs/LayoutHeader';
import AppleSystemConsume from 'services/AppleSystemService';

interface Nivel {
  nivel: number;
  crr: number;
  valorFinal: number;
}

interface bodyNivel {
  niveles: {
    [index: number]: number;
  },
  nombreUsuario: string;
  pagoPorNivel: {
    [index: number]: number;
  };
  periodo: string;
}

interface sendData {
  error: boolean;
  message: string;
  code: number;
  success: boolean;
  bonoBeneficio: bodyNivel;
  bonoNivel: bodyNivel;
  bonoAG: bodyNivel;
  bonoTR: bodyNivel;
  bonoAdelantoSaldo: bodyNivel;
}

function Comisiones() {

  const [bonoBeneficioTable, setBonoBeneficioTable] = useState<Nivel[] | null>(null);
  const [bonoNivelTable, setBonoNivelTable] = useState<Nivel[] | null>(null);
  const [bonoAGTable, setBonoAGTable] = useState<Nivel[] | null>(null);
  const [bonoTRTable, setBonoTRTable] = useState<Nivel[] | null>(null);
  const [bonoAdelantoSaldo, setBonoAdelantoSaldo] = useState<Nivel[] | null>(null);
  const [rowsBonoBeneficio, setRowsBonoBeneficio] = useState<Nivel[]>([])
  const [rowsBonoNivel, setRowsBonoNivel] = useState<Nivel[]>([])
  const [rowsBonoAG, setRowsBonoAG] = useState<Nivel[]>([])
  const [rowsBonoTR, setRowsBonoTR] = useState<Nivel[]>([])
  const [rowsBonoAdelantoSaldo, seRowsAdelantoSaldo] = useState<Nivel[]>([])
  const [message, setMessage] = useState('');
  const [activeTab, setActiveTab] = useState('CRR Bonos de nivel');

  const fetchComisiones = () => {

    AppleSystemConsume('usuario')
      .get('tus-comisiones')
      .then((res: sendData) => {
        if (res.error) throw new Error(res.message)

        setBonoBeneficioTable(Object.keys(res.bonoBeneficio.niveles).map((e: string) => ({
          nivel: +e,
          crr: res.bonoBeneficio.niveles[+e],
          valorFinal: res.bonoBeneficio.pagoPorNivel[+e],
        })))
        setBonoNivelTable(Object.keys(res.bonoNivel.niveles).map((e: string) => ({
          nivel: +e,
          crr: res.bonoNivel.niveles[+e],
          valorFinal: res.bonoNivel.pagoPorNivel[+e],
        })))
        setBonoAGTable(Object.keys(res.bonoAG.niveles).map((e: string) => ({
          nivel: +e,
          crr: res.bonoAG.niveles[+e],
          valorFinal: res.bonoAG.pagoPorNivel[+e],
        })))
        setBonoTRTable(Object.keys(res.bonoTR.niveles).map((e: string) => ({
          nivel: +e,
          crr: res.bonoTR.niveles[+e],
          valorFinal: res.bonoTR.pagoPorNivel[+e],
        })))
        setBonoAdelantoSaldo(Object.keys(res.bonoAdelantoSaldo.niveles).map((e: string) => ({
          nivel: +e,
          crr: res.bonoAdelantoSaldo.niveles[+e],
          valorFinal: res.bonoAdelantoSaldo.pagoPorNivel[+e],
        })))
      })
      .catch((err) => {
        setMessage(err.message)
        setBonoBeneficioTable([])
        setBonoNivelTable([])
        setBonoAGTable([])
        setBonoTRTable([])
      })
  }

  useEffect(() => {

    fetchComisiones();

  }, [])

  return (
    <div className='container'>
      <h3 style={{ textAlign: 'center', marginTop: '1em' }}>Tus Comisiones</h3>
      <LayoutHeader tabs={[
        'CRR Bonos de nivel',
        'CRR Bono sobre beneficio',
        'CRR Bono compras Apple Golden',
        'CRR Bono compras Treidblue',
        'CRR Adelantos de Saldo'
      ]} setActiveTab={setActiveTab} />
      {/* <div className='table-responsive col-md-8 mx-auto ' style={{ boxShadow: '0px 6px 8px rgba(121, 146, 127, 0.5)' }}> */}
      {activeTab === 'CRR Bono sobre beneficio' ?
        <>
          <Table
            color={'bg-dark'}
            rows={bonoBeneficioTable ?? []}
            pagination
            onLoad={!bonoBeneficioTable}
            renderRows={(e) => setRowsBonoBeneficio(e)}
          >
            <thead>
              <tr style={{ fontSize: '12px' }}>
                <th>Nivel</th>
                <th>CRR</th>
                <th>Valor final</th>
              </tr>
            </thead>
            <tbody>
              {
                rowsBonoBeneficio.length === 0
                  ? <tr><td colSpan={3}>no se encontraron resultados</td></tr>
                  : <></>
              }
              {rowsBonoBeneficio.map((row, index) => (
                <tr key={index} style={{ fontSize: '12px' }}>
                  <td>{row.nivel}</td>
                  <td>{row.crr}</td>
                  <td>{row.valorFinal}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
        : <></>

      }

      {activeTab === 'CRR Bonos de nivel' ?
        <>
          <Table
            background='#fff'
            rows={bonoNivelTable ?? []}
            pagination
            onLoad={!bonoNivelTable}
            renderRows={(e) => setRowsBonoNivel(e)}
          >
            <thead>
              <tr style={{ fontSize: '12px' }}>
                <th>Nivel</th>
                <th>CRR</th>
                <th>Valor final</th>
              </tr>
            </thead>
            <tbody>
              {
                rowsBonoNivel.length === 0
                  ? <tr><td colSpan={3}>no se encontraron resultados</td></tr>
                  : <></>
              }
              {rowsBonoNivel.map((row, index) => (
                <tr key={index} style={{ fontSize: '12px' }}>
                  <td>{row.nivel}</td>
                  <td>{row.crr}</td>
                  <td>{row.valorFinal}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
        : <></>

      }
      {activeTab === 'CRR Adelantos de Saldo' ?
        <>
          <Table
            background='#fff'
            rows={bonoAdelantoSaldo ?? []}
            pagination
            onLoad={!bonoAdelantoSaldo}
            renderRows={(e) => seRowsAdelantoSaldo(e)}
          >
            <thead>
              <tr style={{ fontSize: '12px' }}>
                <th>Nivel</th>
                <th>CRR</th>
                <th>Valor final</th>
              </tr>
            </thead>
            <tbody>
              {
                rowsBonoAdelantoSaldo.length === 0
                  ? <tr><td colSpan={3}>no se encontraron resultados</td></tr>
                  : <></>
              }
              {rowsBonoAdelantoSaldo.map((row, index) => (
                <tr key={index} style={{ fontSize: '12px' }}>
                  <td>{row.nivel}</td>
                  <td>{row.crr}</td>
                  <td>{row.valorFinal}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
        : <></>

      }
      {
        activeTab === 'CRR Bono compras Apple Golden' ?
          <>
            <Table
              background='#fff'
              rows={bonoAGTable ?? []}
              pagination
              onLoad={!bonoAGTable}
              renderRows={(e) => setRowsBonoAG(e)}
            >
              <thead>
                <tr style={{ fontSize: '12px' }}>
                  <th>Nivel</th>
                  <th>CRR</th>
                  <th>Valor final</th>
                </tr>
              </thead>
              <tbody>
                {
                  rowsBonoAG.length === 0
                    ? <tr><td colSpan={3}>no se encontraron resultados</td></tr>
                    : <></>
                }
                {rowsBonoAG.map((row, index) => (
                  <tr key={index} style={{ fontSize: '12px' }}>
                    <td>{row.nivel}</td>
                    <td>{row.crr}</td>
                    <td>{row.valorFinal}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </>
          : <></>
      }

      {
        activeTab === 'CRR Bono compras Treidblue' ?
          <>
            <Table
              background='#fff'
              rows={bonoTRTable ?? []}
              pagination
              onLoad={!bonoTRTable}
              renderRows={(e) => setRowsBonoTR(e)}
            >
              <thead>
                <tr style={{ fontSize: '12px' }}>
                  <th>Nivel</th>
                  <th>CRR</th>
                  <th>Valor final</th>
                </tr>
              </thead>
              <tbody>
                {
                  rowsBonoTR.length === 0
                    ? <tr><td colSpan={3}>no se encontraron resultados</td></tr>
                    : <></>
                }
                {rowsBonoTR.map((row, index) => (
                  <tr key={index} style={{ fontSize: '12px' }}>
                    <td>{row.nivel}</td>
                    <td>{row.crr}</td>
                    <td>{row.valorFinal}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </>
          : <></>
      }
      {/* </div> */}
      <span className='text-danger text-center pt-4'>{message}</span>
    </div>
  )
}





export default Comisiones



