// import Badge from 'components/Badge';
import Table from 'components/Table';
import { LayoutHeader } from 'components/navs/LayoutHeader';
import React, { useEffect, useState } from 'react';
import AppleSystemConsume from 'services/AppleSystemService';
import { parseFullDate, parsePrice } from 'utils/parser';

interface Saldo {
  codigo: number;
  codUsuario: number;
  fecha: string;
  valor: number;
  comision: number;
  fechaPago: string;
  referencia: string;
  cancelado: number;
}

interface SolicitudSaldo {
  codigo: number;
  codUsuario: number;
  fecha: string;
  valor: number;
  comision: number;
  fechaPago: string;
  referencia: string;
  cancelado: number;
}

interface sendDataSaldo {
  error: boolean;
  code: number;
  message: string;
  balanceAdvances: Array<Saldo>;
}

interface sendDataRequest {
  error: boolean;
  code: number;
  message: string;
  balanceAdvanceRequests: Array<SolicitudSaldo>;
}

export default function Saldos() {

  const [saldos, setSaldos] = useState<Saldo[] | null>(null)
  const [solicitudSaldo, setSolicitudSaldo] = useState<SolicitudSaldo[] | null>(null)
  const [rowSaldos, setRowSaldos] = useState<Saldo[]>([]);
  const [rowSaldoRequest, setRowSaldoRequest] = useState<SolicitudSaldo[]>([]);
  const [activeTab, setActiveTab] = useState('Adelantos');

  const saldosFetch = async () => {
    await AppleSystemConsume('balance-advance')
      .get('')
      .then((res: sendDataSaldo) => {
        if (res.error) throw new Error(res.message)
        setSaldos(res.balanceAdvances)
      })
  }

  const saldosRequestFetch = async () => {
    await AppleSystemConsume('balance-advance')
      .get('request')
      .then((res: sendDataRequest) => {
        if (res.error) throw new Error(res.message)
        setSolicitudSaldo(res.balanceAdvanceRequests)
      })
  }

  useEffect(() => {
    saldosFetch();
    saldosRequestFetch();
  }, [])

  return (

    <div className='container' >
      <LayoutHeader title='Saldo' tabs={['Adelantos', 'Solicitudes']} setActiveTab={setActiveTab} />
      <div>
        {activeTab === 'Adelantos' ?
          <Table
            background='#fff'
            color='bg-dark'
            rows={saldos ?? []}
            pagination
            onLoad={!saldos}
            renderRows={(e) => setRowSaldos(e)}
          >
            <thead>
              <tr style={{ fontSize: '12px' }}>
                <th>Fecha</th>
                <th>Monto</th>
              </tr>
            </thead>
            <tbody>
              {rowSaldos.map((row, index) => (
                <tr key={index} style={{ fontSize: '12px' }}>
                  <td>{parseFullDate(row.fecha)}</td>
                  <td style={{ color: row.valor > 0 ? 'green' : 'red' }}>
                    {parsePrice(row.valor)}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          : <Table
            rows={solicitudSaldo ?? []}
            pagination
            onLoad={!solicitudSaldo}
            renderRows={(e) => setRowSaldoRequest(e)}
          >
            <thead>
              <tr style={{ fontSize: '12px' }}>
                <th>Fecha</th>
                <th>Monto</th>
                <th>Estado</th>
              </tr>
            </thead>
            <tbody>
              {rowSaldoRequest.map((row, index) => (
                <tr key={index} style={{ fontSize: '12px' }}>
                  <td>{parseFullDate(row.fecha)}</td>
                  <td style={{ color: row.valor > 0 ? 'green' : 'red' }}>
                    {parsePrice(row.valor)}
                  </td>
                  {/* <td>{parseFullDate(row.fechaPago)}</td> */}
                  <td>{row.cancelado}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        }

      </div>
    </div>

  )
}