import BackIcon from 'assets/icons/BackIcon'
import { useNavigate } from 'react-router-dom';
import AppleSystemConsume from 'services/AppleSystemService'

export default function BackButtonRegister() {

    const navigate = useNavigate();

    const registrationId = localStorage.getItem('registrationId');
    const registrationIdParam = {
        registrationId: registrationId || ''
    }

    const fetchBack = () => {
        AppleSystemConsume('registration')
            .delete('registrationId', registrationIdParam)
            .then(() => {
                localStorage.removeItem('registrationId');
            });
            navigate('/login')
    }

    return (
        <>
            <p onClick={fetchBack} style={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                padding: '10px',
                color: '#fff',
                fontSize: '12px'
            }}>
                <BackIcon fill='#fff' />Volver
            </p>
        </>
    )
}
