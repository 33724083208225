import TarjetaGenerica from 'components/templates/TarjetaGenerica';
import { useState, useEffect } from 'react';
import AppleSystemConsume from 'services/AppleSystemService';
import { parsePrice } from 'utils/parser';


interface TotalRetiro {
  error: boolean;
  code: number;
  message: string;
  totalWithdrawals: number;
}

export default function TarjetaRetiros() {

  const [totalRetiro, setTotalRetiro] = useState<number>();
  const [cargando, setCargando] = useState<boolean>(false);

  const fetchRetiro = () => {
    setCargando(true)
    AppleSystemConsume('usuario')
      .get('total-withdrawals')
      .then((res: TotalRetiro) => {
        if (res.error) throw new Error(res.message)
        setTotalRetiro(res.totalWithdrawals)
      })
      .finally(() => {
        setCargando(false)
      })
  }

  useEffect(() => {
    fetchRetiro();
  }, [])

  return (
    <>
      <TarjetaGenerica
        onLoad={cargando}
        fondo='#E1FBFF'
        cantop='Retiros'
        numero={parsePrice(Number(totalRetiro))}
        colorLetter='#000000'
        colorNumber='#000000'
      />
    </>
  )
}
