import Table from 'components/Table';
import { LayoutHeader } from 'components/navs/LayoutHeader';
import React, { useState, useEffect } from 'react';
import AppleSystemConsume from 'services/AppleSystemService';
import { parseFullDate } from 'utils/parser';

interface Qualification {
  codigo: number;
  codUsuario: number;
  fecha: Date;
  proceso: 'Calificacion' | 'Calificacion CR1' | 'Calificacion CR2' | 'Calificacion CR3' | 'Calificacion CR4' | 'Calificacion CR5';
  valor: number;
  concepto: string;
}

interface sendData {
  error: boolean;
  code: number;
  message: string;
  qualifications: Array<Qualification>;
}

export default function MyQualifications() {

  const [activeTab, setActiveTab] = useState('Calificación por retiro');
  const [resQualifications, setResQualifications] = useState<Qualification[] | null>(null);
  const [rowsQuali, setRowsQuali] = useState<Qualification[]>([]);
  const [message, setMessage] = useState<string>('');

  useEffect(() => {
    AppleSystemConsume('process')
      .get('my-qualifications')
      .then((res: sendData) => {
        if (res.error) throw new Error(res.message)
        setResQualifications(res.qualifications)
      })
      .catch((error) => {
        setMessage(error.message)
        setResQualifications([])
      })

  }, [])

  const data = [
    { id: 'CR1', description: 'Total de Inversión máximo 899sc', value: '10%' },
    { id: 'CR2', description: 'Total de Inversión de 900sc a 2900sc', value: '25sc' },
    { id: 'CR3', description: 'Total de Inversión de 3000sc 4900sc', value: '50sc' },
    { id: 'CR4', description: 'Total de Inversión de 5000sc a 9900sc', value: '100sc' },
    { id: 'CR5', description: 'Total de Inversión de 10000sc a 20000sc', value: '200sc' },
  ];

  return (

    <div className='container'>
      <LayoutHeader tabs={['Calificación por retiro', 'Historial de calificaciones']} setActiveTab={setActiveTab} />
      {activeTab === 'Calificación por retiro'
        ?
        <table className="table table-hover bg-white">
          <thead>
            <tr style={{ fontSize: '12px' }}>
              <th></th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index} style={{ fontSize: '12px' }}>
                <td>{item.id}</td>
                <td>{item.description}</td>
                <td>{item.value}</td>
              </tr>
            ))}
          </tbody>
        </table>
        :
        <Table
          color='bg-dark'
          rows={resQualifications ?? []}
          pagination
          onLoad={!resQualifications}
          renderRows={(e) => setRowsQuali(e)}
        >
          <thead>
            <tr style={{ fontSize: '12px' }}>
              <th>Fecha</th>
              <th>Concepto</th>
              <th>Valor</th>
            </tr>
          </thead>
          <tbody>
            {rowsQuali.map((row, index) => (
              <tr key={index} style={{ textAlign: 'center', fontSize: '12px' }}>
                <td>{parseFullDate(row.fecha)}</td>
                <td>{row.proceso}</td>
                <td>{row.valor}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      }
      <div className='text-center mt-5'><span className='text-danger'>{message}</span></div>
    </div>

  )

}