/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-shadow
enum EColor {
  DEFAULT = 'btn-as',
  RED = 'btn-danger',
  BLUE = 'btn-primary',
  YELLOW = 'btn-warning',
  GREEN = 'btn-success',
  BLACK = 'btn-dark',
  GREY = 'btn-secondary',
  LIGHT_BLUE = 'btn-info',
}

export default EColor;
