import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import '../Registration.css'
import Platform from 'types/Platform';
import AppleSystemConsume from 'services/AppleSystemService';
import BackButtonRegister from 'components/BackButtonRegister';
import Logo from './Logo';


interface Contrasena {
  registrationId: string;
  password: string;
  passwordconfirm: string
}

interface ServerResponse {
  routes: { next: string }
  error: boolean;
  code: number;
  message: string;
}

export const Password = () => {

  const params = new URLSearchParams(window.location.search);
  const platform = params.get('platform') as Platform;
  const newLocal = 'white';

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [password, setPassword] = useState<Contrasena>({
    registrationId: localStorage.getItem('registrationId') || '',
    password: '',
    passwordconfirm: ''
  });

  useEffect(() => {
    const registrationIdParams = params.get('registrationId');
    if (registrationIdParams) {
      localStorage.setItem('registrationId', registrationIdParams)
      setPassword({ ...password, registrationId: registrationIdParams })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const inputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLInputElement>) => {
    const { name, value } = e.target
    setPassword({
      ...password,
      [name]: value
    })
  }

  const checkPassword = async () => {

    if (password.password !== password.passwordconfirm || !password.password || !password.passwordconfirm) {
      setErrorMessage('las contraseñas no coinciden')
      return;
    }
    setIsLoading(true);

    const requestpassword = {
      registrationId: password.registrationId || '',
      password: password.password
    }

    AppleSystemConsume('registration')
      .post('password_validation', requestpassword, {})
      .then((res: ServerResponse) => {
        if (res.error) throw new Error(res.message);
        navigate(res.routes.next);
      })
      .catch((err: any) => setErrorMessage(err.message))
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      <div className='body-registration'>
      <div className='back-button'>
          <BackButtonRegister />
        </div>
        <header className='container-logo'>
          <div className='logo'>
            <Logo id="logo_desktop" platform={platform} logoColor={newLocal} />
          </div>
        </header>
        <div className='container-register'>
        <div className='d-flex flex-column justify-content-center align-center align-items-center'>
            <h4 style={{ fontSize: '30px' }}>Contraseña</h4>
            <p style={{ fontSize: '17px' }}>Paso 5 de 5</p>
              <div className='form-group-password'>
                <input
                  type='password'
                  name='password'
                  onChange={inputChange}
                  placeholder='Contraseña' />
              </div>
              <div className='form-group-password'>
                <input
                  type='password'
                  name='passwordconfirm'
                  onChange={inputChange}
                  placeholder='Confirmar Contraseña' />
              </div>
              <ul className='text-start text-secondary' style={{ fontSize: '10px' }}>
                <li>
                  Debe contener un mínimo de 10 caracteres.
                </li>
                <li>
                  Debe contener al menos una mayúscula.
                </li>
                <li>
                  Debe contener al menos un caracter especial.
                </li>
                <li>
                  Debe contener al menos un número.
                </li>
              </ul>
              <div className='button-register'>
                <button onClick={checkPassword} disabled={isLoading} >
                  {isLoading ? 'Cargando...' : 'Registrarme'}
                </button>
              </div>
              <p className='text-center text-danger'>{errorMessage}</p>
            </div>
          </div>
        </div>
    </>
  )
}
