import React, { useState, useEffect } from 'react';
import Table from 'components/Table';
import { parseFullDate } from 'utils/parser';
import Th from 'components/tables/Th';
import Badge from 'components/Badge';
import { LayoutHeader } from 'components/navs/LayoutHeader';
import AppleSystemConsume from 'services/AppleSystemService';


interface historialPuntosAG {
  valor: number | string
  fecha: string
  puntosGenerados: string
  proceso: string
}

interface comprasAG {
  codigo: string
  codUsuario: string
  idCompra: string
  fecha: string
  puntosAG: string
  sacoins: string
  financiamiento: string
  estado: string
  crr: string
}

interface serviciosAG {
  codigo: string
  codUsuario: string
  idPagoServicio: string
  servicio: string
  fecha: string
  sacoins: string
  financiamiento: string
  estado: string
  crr: string
  convenio: string
  tipo: string
  provider: string
}

interface comprasTR {
  codigo: string
  codUsuario: string
  codUsuarioVendedor: string
  idCompra: string
  fecha: string
  sacoins: string
  financiamiento: string
  estado: string
  crr: string
}

interface comprasAgenciaDeViaje {
  codigo: string
  codUsuario: string
  numero_cotizacion: string
  fecha_solicitud: string
  estado: string
  tipo: string
  fecha_cotizacion: string
  informacion: string
  cotizacion_mxn: string
  cotizacion_sc: string
  financiamiento: string
  fecha_confirmacion: string
  motivo_rechazo: string
}

interface sendData {
  message: string
  error: boolean
  code: number
  historialPuntosAG: Array<historialPuntosAG>
  comprasAG: Array<comprasAG>
  serviciosAG: Array<serviciosAG>
  comprasTR: Array<comprasTR>
  comprasAgenciaDeViaje: Array<comprasAgenciaDeViaje>
  puntosAG: string
}

function ComprasServicios() {

  const [historialAG, setHistorialAG] = useState<historialPuntosAG[] | null>(null);
  const [comprasag, setComprasag] = useState<comprasAG[] | null>(null);
  const [serviciosag, setServiciosag] = useState<serviciosAG[] | null>(null);
  const [comprastr, setComprastr] = useState<comprasTR[] | null>(null);
  const [comprasAgencia, setComprasAgencia] = useState<comprasAgenciaDeViaje[] | null>(null);
  const [message, setMessage] = useState<string>('')
  const [rowsHistorial, setRowsHistorial] = useState<historialPuntosAG[]>([]);
  const [rowsCompras, setRowsCompras] = useState<comprasAG[]>([]);
  const [rowsServicios, setRowsServicios] = useState<serviciosAG[]>([]);
  const [rowsComprasTR, setRowsComprasTR] = useState<comprasTR[]>([]);
  const [rowsComprasAgencia, setRowsComprasAgencia] = useState<comprasAgenciaDeViaje[]>([]);
  const [activeTab, setActiveTab] = useState('Historial AG');
  const [puntosAG, setPuntosAG] = useState<string | null>(null)


  useEffect(() => {
    AppleSystemConsume('usuario')
      .get('compras-y-servicio')
      .then((res: sendData) => {
        if (res.error) throw new Error(res.message)
        setHistorialAG(res.historialPuntosAG)
        setComprasag(res.comprasAG)
        setServiciosag(res.serviciosAG)
        setComprastr(res.comprasTR)
        setComprasAgencia(res.comprasAgenciaDeViaje)
        setPuntosAG(res.puntosAG)
      })
      .catch((err) => {
        setMessage(err.message)
        setHistorialAG([])
        setComprasag([])
        setServiciosag([])
        setComprasAgencia([])
        setComprastr([])
        setPuntosAG('')
      })
  }, [])

  // filtros
  const sortDatosHistorial = (field: string, sort: number) => {
    if (historialAG == null) return;
    if (field === 'FECHA') {
      const firstSort = sort === -1 ? 1 : -1;
      const secondSort = sort === -1 ? -1 : 1;
      const sorted = historialAG.sort((a, b) => {
        return a.fecha > b.fecha ? firstSort : secondSort;
      });
      setHistorialAG([...sorted]);
    }

    if (field === 'VALOR') {
      const firstSort = sort === -1 ? 1 : -1;
      const secondSort = sort === -1 ? -1 : 1;
      const sorted = historialAG.sort((a, b) => {
        return Number(a.valor) > Number(b.valor) ? firstSort : secondSort;
      });
      setHistorialAG([...sorted]);
    }
    if (field === 'PUNTOSG') {
      const firstSort = sort === -1 ? 1 : -1;
      const secondSort = sort === -1 ? -1 : 1;
      const sorted = historialAG.sort((a, b) => {
        return Number(a.puntosGenerados) > Number(b.puntosGenerados) ? firstSort : secondSort;
      });
      setHistorialAG([...sorted]);
    }
  }

  const sortDatosCompras = (field: string, sort: number) => {
    if (comprasag == null) return;
    if (field === 'FECHA') {
      const firstSort = sort === -1 ? 1 : -1;
      const secondSort = sort === -1 ? -1 : 1;
      const sorted = comprasag.sort((a, b) => {
        return a.fecha > b.fecha ? firstSort : secondSort;
      });
      setComprasag([...sorted]);
    }
    if (field === 'SACOINS') {
      const firstSort = sort === -1 ? 1 : -1;
      const secondSort = sort === -1 ? -1 : 1;
      const sorted = comprasag.sort((a, b) => {
        return a.sacoins > b.sacoins ? firstSort : secondSort;
      });
      setComprasag([...sorted]);
    }
    if (field === 'PUNTOS') {
      const firstSort = sort === -1 ? 1 : -1;
      const secondSort = sort === -1 ? -1 : 1;
      const sorted = comprasag.sort((a, b) => {
        return a.puntosAG > b.puntosAG ? firstSort : secondSort;
      });
      setComprasag([...sorted]);
    }
    if (field === 'FINANCIAMIENTO') {
      const firstSort = sort === -1 ? 1 : -1;
      const secondSort = sort === -1 ? -1 : 1;
      const sorted = comprasag.sort((a, b) => {
        return a.financiamiento > b.financiamiento ? firstSort : secondSort;
      });
      setComprasag([...sorted]);
    }
    if (field === 'CRR') {
      const firstSort = sort === -1 ? 1 : -1;
      const secondSort = sort === -1 ? -1 : 1;
      const sorted = comprasag.sort((a, b) => {
        return a.crr > b.crr ? firstSort : secondSort;
      });
      setComprasag([...sorted]);
    }
  }

  const sortDatosServicios = (field: string, sort: number) => {
    if (serviciosag == null) return;
    if (field === 'FECHA') {
      const firstSort = sort === -1 ? 1 : -1;
      const secondSort = sort === -1 ? -1 : 1;
      const sorted = serviciosag.sort((a, b) => {
        return a.fecha > b.fecha ? firstSort : secondSort;
      });
      setServiciosag([...sorted]);
    }
    if (field === 'SACOINS') {
      const firstSort = sort === -1 ? 1 : -1;
      const secondSort = sort === -1 ? -1 : 1;
      const sorted = serviciosag.sort((a, b) => {
        return a.sacoins > b.sacoins ? firstSort : secondSort;
      });
      setServiciosag([...sorted]);
    }
    if (field === 'FINANCIAMIENTO') {
      const firstSort = sort === -1 ? 1 : -1;
      const secondSort = sort === -1 ? -1 : 1;
      const sorted = serviciosag.sort((a, b) => {
        return a.financiamiento > b.financiamiento ? firstSort : secondSort;
      });
      setServiciosag([...sorted]);
    }
    if (field === 'CRR') {
      const firstSort = sort === -1 ? 1 : -1;
      const secondSort = sort === -1 ? -1 : 1;
      const sorted = serviciosag.sort((a, b) => {
        return a.crr > b.crr ? firstSort : secondSort;
      });
      setServiciosag([...sorted]);
    }
  }

  return (
    <div className='container'>
      <LayoutHeader tabs={['Historial AG', 'Compras AG', 'Servicios AG', 'Compras TR', 'Compra Agencia de Viajes']}
        badges={{ 'Puntos Apple Golden:': puntosAG }} setActiveTab={setActiveTab} />
      <div className='contenido'>
        {activeTab === 'Historial AG' && (
          // <div className='table-responsive col-md-8 mx-auto mt-4' style={{ boxShadow: '0px 6px 8px rgba(121, 146, 127, 0.5)' }}>
          <Table
            color='bg-dark'
            rows={historialAG ?? []}
            pagination
            onLoad={!historialAG}
            renderRows={(e) => setRowsHistorial(e)}
          >
            <thead>
              <tr style={{ fontSize: '12px' }}>
                <Th onChange={(sort) => sortDatosHistorial('FECHA', sort)} sorted initiarSort={1}>Fecha</Th>
                <th>Proceso</th>
                <Th onChange={(sort) => sortDatosHistorial('VALOR', sort)} sorted>Valor</Th>
                <Th onChange={(sort) => sortDatosHistorial('PUNTOSG', sort)} sorted>Puntos Generados</Th>
              </tr>
            </thead>
            <tbody>
              {rowsHistorial.map((row, index) => (
                <tr key={index} style={{ fontSize: '12px' }}>
                  <td>{parseFullDate(row.fecha)}</td>
                  <td><Badge state={row.proceso} /></td>
                  <td className={+row.valor >= 0 ? 'text-success ' : 'text-danger '}>${(+row.valor).toFixed(2)}</td>
                  <td>{row.puntosGenerados}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
        {activeTab === 'Compras AG' && (
          // <div className='table-responsive col-md-8 mx-auto mt-4' style={{ boxShadow: '0px 6px 8px rgba(121, 146, 127, 0.5)' }}>
          <Table
            rows={comprasag ?? []}
            pagination
            onLoad={!comprasag}
            renderRows={(e) => setRowsCompras(e)}
          >
            <thead>
              <tr style={{ fontSize: '12px' }}>
                <Th onChange={(sort) => sortDatosCompras('FECHA', sort)} sorted initiarSort={1}>Fecha</Th>
                <Th onChange={(sort) => sortDatosCompras('PUNTOS', sort)} sorted initiarSort={1}>Puntos AG</Th>
                <Th onChange={(sort) => sortDatosCompras('SACOINS', sort)} sorted initiarSort={1}>Sacoins</Th>
                <Th onChange={(sort) => sortDatosCompras('FINANCIAMIENTO', sort)} sorted initiarSort={1}>Financiamiento</Th>
                <th>Estado</th>
                <Th onChange={(sort) => sortDatosCompras('CRR', sort)} sorted initiarSort={1}>CRR</Th>
              </tr>
            </thead>
            <tbody>
              {rowsCompras.map((row, index) => (
                <tr key={index} style={{ fontSize: '12px' }}>
                  <td>{parseFullDate(row.fecha)}</td>
                  <td>{row.puntosAG}</td>
                  <td>{row.sacoins}</td>
                  <td>{row.financiamiento}</td>
                  <td><Badge state={row.estado} /></td>
                  <td>{row.crr}</td>
                </tr>
              ))}
            </tbody>
          </Table>

        )}
        {activeTab === 'Servicios AG' && (
          // <div className='table-responsive col-md-8 mx-auto mt-4' style={{ boxShadow: '0px 6px 8px rgba(121, 146, 127, 0.5)' }}>
          <Table
            rows={serviciosag ?? []}
            pagination
            onLoad={!serviciosag}
            renderRows={(e) => setRowsServicios(e)}
          >
            <thead>
              <tr style={{ fontSize: '12px' }}>
                <th>Servicio</th>
                <Th onChange={(sort) => sortDatosServicios('FECHA', sort)} sorted initiarSort={1}>Fecha</Th>
                <Th onChange={(sort) => sortDatosServicios('SACOINS', sort)} sorted initiarSort={1}>Sacoins</Th>
                <Th onChange={(sort) => sortDatosServicios('FINANCIAMIENTO', sort)} sorted initiarSort={1}>Financiamiento</Th>
                <th>Estado</th>
                <Th onChange={(sort) => sortDatosServicios('CRR', sort)} sorted initiarSort={1}>CRR</Th>
                <th>Convenio</th>
                <th>Tipo</th>
                <th>Provider</th>
              </tr>
            </thead>
            <tbody>
              {rowsServicios.map((row, index) => (
                <tr key={index} style={{ fontSize: '12px' }}>
                  <td>{row.servicio}</td>
                  <td>{parseFullDate(row.fecha)}</td>
                  <td>{row.sacoins}</td>
                  <td>{row.financiamiento}</td>
                  <td><Badge state={row.estado} /></td>
                  <td>{row.crr}</td>
                  <td>{row.convenio}</td>
                  <td>{row.tipo}</td>
                  <td>{row.provider}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
        {activeTab === 'Compras TR' && (
          // <div className='table-responsive col-md-8 mx-auto mt-4' style={{ boxShadow: '0px 6px 8px rgba(121, 146, 127, 0.5)' }}>
          <Table
            rows={comprastr ?? []}
            pagination
            onLoad={!comprastr}
            renderRows={(e) => setRowsComprasTR(e)}
          >
            <thead>
              <tr style={{ fontSize: '12px' }}>
                <Th onChange={(sort) => sortDatosServicios('FECHA', sort)} sorted initiarSort={1}>Fecha</Th>
                <Th onChange={(sort) => sortDatosServicios('SACOINS', sort)} sorted initiarSort={1}>Sacoins</Th>
                <Th onChange={(sort) => sortDatosServicios('FINANCIAMIENTO', sort)} sorted initiarSort={1}>Financiamiento</Th>
                <th>Estado</th>
                <Th onChange={(sort) => sortDatosServicios('CRR', sort)} sorted initiarSort={1}>CRR</Th>
              </tr>
            </thead>
            <tbody>
              {rowsComprasTR.map((row, index) => (
                <tr key={index} style={{ fontSize: '12px' }}>
                  <td>{parseFullDate(row.fecha)}</td>
                  <td>{row.sacoins}</td>
                  <td>{row.financiamiento}</td>
                  <td><Badge state={row.estado} /></td>
                  <td>{row.crr}</td>
                </tr>
              ))}
            </tbody>
          </Table>

        )}
        {activeTab === 'Compra Agencia de Viajes' && (
          // <div className='table-responsive col-md-10 mx-auto mt-4' style={{ boxShadow: '0px 6px 8px rgba(121, 146, 127, 0.5)' }}>
          <Table
            rows={comprasAgencia ?? []}
            pagination
            onLoad={!comprasAgencia}
            renderRows={(e) => setRowsComprasAgencia(e)}
          >
            <thead>
              <tr style={{ fontSize: '10px' }}>
                <th>Numero de Cotización</th>
                <Th onChange={(sort) => sortDatosServicios('FECHA', sort)} sorted initiarSort={1}>fecha de solicitud</Th>
                <th>Estado</th>
                <th>Tipo</th>
                <th>Fecha de cotización</th>
                <th>Información</th>
                <th>Cotización MXN</th>
                <th>Cotización SC</th>
                <Th onChange={(sort) => sortDatosServicios('FINANCIAMIENTO', sort)} sorted initiarSort={1}>Financiamiento</Th>
                <th>Fecha de confirmación</th>
                <th>Motivo de rechazo</th>
              </tr>
            </thead>
            <tbody>
              {rowsComprasAgencia.map((row, index) => (
                <tr key={index} style={{ fontSize: '12px' }}>
                  <td>{row.numero_cotizacion}</td>
                  <td>{parseFullDate(row.fecha_solicitud)}</td>
                  <td><Badge state={row.estado} /></td>
                  <td>{row.tipo}</td>
                  <td>{row.fecha_cotizacion}</td>
                  <td>{row.informacion}</td>
                  <td>{row.cotizacion_mxn}</td>
                  <td>{row.cotizacion_sc}</td>
                  <td>{row.financiamiento}</td>
                  <td>{row.fecha_confirmacion}</td>
                  <td>{row.motivo_rechazo}</td>
                </tr>
              ))}
            </tbody>
          </Table>

        )}
      </div>
      <p className='text-danger text-center pt-4'>{message}</p>
    </div>
  )
}


export default ComprasServicios