// import Tarjeta from 'components/templates/Tarjeta';
// import './EstadoCuenta.css';
import React, { useEffect, useState } from 'react';
import Table from 'components/Table';
import Badge from 'components/Badge';
import { parseFullDate } from 'utils/parser';
import { LayoutHeader } from 'components/navs/LayoutHeader';
import AppleSystemConsume from 'services/AppleSystemService';

interface Tabla {
  codigo: string,
  codeUsuario: string,
  proceso: string,
  fecha: string,
  concepto: string,
  valor: string,
  saldo: string
}

interface Fondeos {
  codUsuario: string,
  codigo: string,
  fecha: string,
  proceso: string,
  tipo: string,
  valor: string
}

interface SendData {
  message: string | undefined;
  procesos: Array<Tabla>,
  cantOperaciones: string,
  procesosFondeo: Array<Fondeos>,
  saldoActual: string
  success: boolean,
  error: boolean
}

export const PagosServicios = () => {

  const [datosTabla, setDatosTabla] = useState<Tabla[] | null>(null);
  const [datosFondeo, setDatosFondeo] = useState<Fondeos[] | null>(null);
  const [rowsTabla, setRowsTabla] = useState<Tabla[]>([]);
  const [rowsFondeo, setRowsFondeo] = useState<Fondeos[]>([]);
  const [message, setMessage] = useState<string>('');
  const [textFilter, setTextFilter] = useState<string>('');
  const [textFilter2, setTextFilter2] = useState<string>('');
  const [activeTab, setActiveTab] = useState('Tipo de proceso');

  useEffect(() => {

    AppleSystemConsume('usuario')
      .get('estado-cuenta')
      .then((res: SendData) => {
        if (res.error) throw new Error(res.message)
        setDatosTabla(res.procesos)
        setDatosFondeo(res.procesosFondeo)
      })
      .catch((err) => {
        setMessage(err.message)
        setDatosFondeo([])
        setDatosTabla([])
      })

  }, []);

  return (
    <div className='conainter'>
      <LayoutHeader tabs={['Tipo de proceso', 'Concepto']} setActiveTab={setActiveTab} />
      {activeTab === 'Tipo de proceso'
        ?
        <article className='serviciosAppleGolden'>
          <input
            type="text"
            onChange={(e) => setTextFilter(e.target.value)}
            className="form-control h-25 text-center"
            placeholder="Buscar tipo proceso..."
            aria-describedby="search"
          />
          <Table
            color='bg-dark'
            rows={datosFondeo ?? []}
            pagination
            onLoad={!datosFondeo}
            textFilter={textFilter}
            renderRows={(e) => setRowsFondeo(e)}
          >
            <thead>
              <tr style={{ fontSize: '12px' }}>
                <th>Fecha</th>
                <th>Tipo de Proceso</th>
                <th>Valor</th>
              </tr>
            </thead>
            <tbody>
              {rowsFondeo.map((row, index) => (
                <tr key={index} style={{ fontSize: '12px' }}>
                  <td>{parseFullDate(row.fecha)}</td>
                  <td><Badge state={row.proceso} /></td>
                  <td>${(+row.valor).toFixed(2)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </article>
        :
        <article className='comprasTreidBlue'>
          <input
            type="text"
            onChange={(e) => setTextFilter2(e.target.value)}
            className="form-control h-25 text-center"
            placeholder="Buscar concepto..."
            aria-describedby="search"
          />
          <Table
            rows={datosTabla ?? []}
            textFilter={textFilter2}
            pagination
            onLoad={!datosTabla}
            renderRows={(e) => setRowsTabla(e)}
          >
            <thead>
              <tr style={{ fontSize: '12px' }}>
                <th>Fecha</th>
                <th>Concepto</th>
                <th>Valor</th>
              </tr>
            </thead>
            <tbody>
              {rowsTabla.map((row, index) => (
                <tr key={index} style={{ fontSize: '12px' }}>
                  <td>{parseFullDate(row.fecha)}</td>
                  <td><Badge state={row.proceso} /></td>
                  <td className={+row.valor >= 0 ? 'text-success ' : 'text-danger '}>${(+row.valor).toFixed(2)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </article>
      }
      <p className='text-danger text-center pt-4'>{message}</p>
    </div>
  );
};
