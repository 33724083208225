import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import AppleSystemConsume from 'services/AppleSystemService';
import Button from 'components/Button';
import Modal from 'components/Modal';
import UserDataFondeoPackage from 'components/paquetes/UserDataFondeoPackage';
import IUsuario from 'interfaces/IUsuario';
import PaqueteAdquirido from './PaqueteAdquirido';

type ResUser = {
  code: number;
  error: boolean;
  message: string;
  usuario: IUsuario
}

type PackageRequest = {
  codigo: string;
  codUsuario: string;
  fecha: string;
  monto: number;
  pagadoFondeo: boolean;
  costoSaldoComun: number;
}

type ResFondeo = {
  code: number;
  error: boolean;
  message: string;
  packageRequest: PackageRequest
}

export default function PaqueteFondeo() { 

  const [userInfo, setUserInfo] = useState<IUsuario | null>(null);
  const [fondeoValue, setFondeoValue] = useState<number>();
  const [monto, setMonto] = useState<number>();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [resFondeo, setResFondeo] = useState<PackageRequest>();
  const [message, setMessage] = useState<string>('');

  // los datos del usuario logueado
  const fetchUserInfo = () => {
    AppleSystemConsume('usuario')
      .get('my-usuario')
      .then((res: ResUser) => {
        setUserInfo(res.usuario)
      })
  }

  // aca consigo el saldo de fondeo
  const getFondeo = () => {
    AppleSystemConsume('user')
      .get('funding-balance')
      .then((res) => {
        setFondeoValue(res.fundingBalance)
      })
  }

  // mando la peticion con el valor de paquete a la base de datos
  const postFondeo = () => {
    if(monto && monto < 300) {
      setMessage('* El monto de fondeo debe ser igual o superior a 300.')
      return;
    }
    AppleSystemConsume('package-request')
      .post('',{monto}, {})
      .then((res: ResFondeo) => {
        if (res.error) throw new Error(res.message);
        setResFondeo(res.packageRequest)
        setMessage('');
        setIsModalVisible(true)
      })
  }

  const handleCloseModal = () => {
    setIsModalVisible(false);
    window.location.reload(); 
  };

  useEffect(() => {
    fetchUserInfo();
    getFondeo();
  }, [])

  return (
    <div className='container d-flex flex-column align-items-center justify-center'>
      <p className='text-center' style={{ fontSize: 25, fontWeight: '600' }}>Solicitar Paquete Fondeo</p>
      <div className="d-flex flex-row justify-content-center">
        <article className='d-flex align-items-center'
          style={{
            boxShadow: '2px 8px 20px rgba(0, 0, 0, 0.2)', backgroundColor: '#fff',
            borderRadius: 15,
            padding: 10
          }}>
           {userInfo && fondeoValue !== undefined && (
            <UserDataFondeoPackage
              usuario={userInfo}
              saldoFondeo={fondeoValue}
              titleSaldo='Saldo de Fondeo disponible'
            />
          )}
          <section className='p-4'>
            <p style={{ fontWeight: 'bolder', fontSize: 13 }} className='text-center'>Solicitar Paquete de Fondeo</p>
            <div className='d-flex flex-column mb-4'>
              <label htmlFor="valorPaquete" style={{ fontSize: 13, fontWeight: 'bolder' }}>Ingrese el valor de su nuevo paquete</label>
              <input
                id="valorPaquete"
                name="valorPaquete"
                onBlur={(e) => setMonto(Number(e.target.value))}
                className="form-control h-25 mb-3 border border-dark"
                placeholder='Valor de paquete...'
                type="number" />
                <p className='text-danger' style={{fontSize: 8, marginBottom: 0}}>{message}</p>
            </div>
            <div className='d-flex flex-column gap-2'>
              <div style={{ textAlign: 'center', maxWidth: 300 }}>
                <p className='text-light bg-danger bg-gradient'>Los paquetes disponibles están sujetos a tu disponibilidad de
                  saldo de fondeo. Para comprar con saldo y fondeo,
                 <Link className='text-light' to={'/user-panel/solicitar-paquete'}> hacé click acá</Link></p>
                <p className='bg-warning'>Una vez realizada la solicitud, la misma debe ser aceptada por el administrador.
                  Una vez adquirido el paquete, el mismo demorará 3 días hábiles para activarse.</p>
              </div>
            </div>
          </section>
        </article>
      </div>
      <div className='text-center mt-5'>
        <Button title='Solicitar Paquete' onPress={postFondeo} />
      </div>
      <Modal title={'Paquete de Fondeo'} onClose={handleCloseModal} show={isModalVisible}>
        <PaqueteAdquirido fecha={resFondeo?.fecha} monto={resFondeo?.monto}/>
      </Modal>
    </div>
  )
}
