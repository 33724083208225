import React from 'react';
import { Provider } from 'react-redux';

import { store } from 'store';
import { ThemeController } from 'providers/ThemeController.provider';

import Routes from './routes';

import './assets/styles/constants.css';
import './assets/styles/bootstrap.min.css';

import { NotificationLayout } from './layouts/Notification.layout';

const App = () => {
  return (
    <Provider store={store}>
      <ThemeController>
        <NotificationLayout>
          <Routes />
        </NotificationLayout>
      </ThemeController>
    </Provider>
  );
};

export default App;
