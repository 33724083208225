import Consume, { Obj, ObjectToURI } from './Consume';

const AppleSystemConsume: Consume = (resource: string, jwt = '') => {
  const url = (process.env.NODE_ENV === 'production' ? 'https://server.sistemaapple.com.mx/api/' : process.env.REACT_APP_AS_API) + resource;
  const headers = new Headers();
  
  if (jwt) {
    headers.append('Authorization', jwt);
  } else {
    const authStorage = localStorage.getItem('auth');
    const auth = authStorage ? JSON.parse(authStorage) : '{ "jwt": "" }';
    headers.append('Authorization', auth.jwt ?? '');
  }

  headers.append('Content-Type', 'application/json; charset=utf-8');

  const handleResponse = async (res: Response) => {
    const awaitRes = await res.json();
    const Authorization = res.headers?.get('Authorization') ?? '';
    if (Authorization) {
      const { userType } = JSON.parse(localStorage.getItem('auth') ?? '{ "userType": "" }');
      localStorage.setItem('auth', JSON.stringify({ jwt: Authorization, userType }));
    }
    if (awaitRes.token_expired) {
      localStorage.removeItem('auth');
      window.location.href = '/login';
    }
    return { ...awaitRes, jwt: Authorization };
  };

  const handleError = () => ({
    success: false,
    message: 'Hubo un problema al comunicarse con el servidor',
    err: 'ERR_CONNECTION_REFUSED',
  });

  return {
    get: (id = '', params: Obj = {}) =>
      fetch(`${url}${id ? `/${id}` : ''}${Object.keys(params).length ? `?${ObjectToURI(params)}` : ''}`, {
        headers,
        cache: 'no-store'
      }).then(handleResponse).catch(handleError),

    post: (id = '', body: Object | FormData = {}, params: Obj = {}) => {
      const postHeaders = new Headers(headers);
      if (body instanceof FormData) {
        postHeaders.delete('Content-Type');
      }

      return fetch(`${url}${id ? `/${id}` : ''}${Object.keys(params).length ? `?${ObjectToURI(params)}` : ''}`, {
        headers: postHeaders,
        method: 'POST',
        body: body instanceof FormData ? body : JSON.stringify(body),
        cache: 'no-store'
      }).then(handleResponse).catch(handleError);
    },

    put: (id = '', body: Object = {}, params: Obj = {}) =>
      fetch(`${url}${id ? `/${id}` : ''}${Object.keys(params).length ? `?${ObjectToURI(params)}` : ''}`, {
        headers,
        method: 'PUT',
        body: JSON.stringify(body),
        cache: 'no-store'
      }).then(handleResponse).catch(handleError),

    delete: (id = '', params: Obj = {}) =>
      fetch(`${url}${id ? `/${id}` : ''}${Object.keys(params).length ? `?${ObjectToURI(params)}` : ''}`, {
        headers,
        method: 'DELETE',
        cache: 'no-store'
      }).then(handleResponse).catch(handleError),
  };
};

export default AppleSystemConsume;
