import UserAdelantoSaldo from 'components/paquetes/UserAdelantoSaldo';
import IUsuario from 'interfaces/IUsuario';
import React, { useEffect, useState } from 'react';
import AppleSystemConsume from 'services/AppleSystemService';

type ResUser = {
    code: number;
    error: boolean;
    message: string;
    usuario: IUsuario;
}

export default function AdelantoDeSaldo() {

    const [userInfo, setUserInfo] = useState<IUsuario | null>(null);
    const [totalInversion, setTotalInversion] = useState<number | null>(null);
    const [saldoDisponible, setSaldoDisponible] = useState<number>();
    const [montoSolicitado, setMontoSolicitado] = useState<number | ''>('');
    const [message, setMessage] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>();

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { value } = e.target
        setMontoSolicitado(Number(value))
    }

    const fetchUserInfo = () => {
        AppleSystemConsume('usuario')
            .get('my-usuario')
            .then((res: ResUser) => {
                setUserInfo(res.usuario);
            });
    }

    const getSaldo = () => {
        AppleSystemConsume('balance-advance')
            .get('init')
            .then((res) => {
                setTotalInversion(res.totalInversion);
                setSaldoDisponible(res.totalPermitido);
            });
    }

    useEffect(() => {
        fetchUserInfo();
        getSaldo();
    }, []);

    const postSaldo = () => {
        setIsLoading(true)
        AppleSystemConsume('balance-advance')
            .post('', { monto: montoSolicitado }, {})
            .then((res) => {
                if (res.error !== false) setError(res.error)
                setError(res.error)
                setMessage(res.message);
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const totalObtenerSA = () => {
        if (totalInversion && montoSolicitado) {
            const mesesRecuperacion = Math.ceil((((montoSolicitado * 100) / totalInversion) / 5));
            return 0.10 * mesesRecuperacion * montoSolicitado;

        }
        return 0;
    }

    const totalSA = totalObtenerSA();

    const mininput = () => {
        if (totalInversion) {
            return totalInversion * 0.1
        }
        return 0
    }

    const min = mininput()

    return (
        <div className='container py-5'>
            <div className='row justify-content-center'>
                <div className='col-md-8'>
                    <div className='card shadow-sm'>
                        <div className='card-body'>
                            {userInfo && totalInversion && saldoDisponible !== undefined && (
                                <UserAdelantoSaldo
                                    usuario={userInfo}
                                    totalInversion={totalInversion}
                                    saldoDisponible={saldoDisponible}
                                    titleSaldo='Saldo disponible adelanto (sujeto a adelantos realizados):'
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <div className='row justify-content-center my-4'>
                <div className='col-md-6'>
                    <div className='text-center'>
                        <h5 className='font-weight-bold'>Solicitar adelanto de saldo</h5>
                    </div>
                    <div className='alert alert-warning text-center'>
                        <p> Has solicitado un Adelanto de Saldo, luego de procesar tu solicitud esta aparecerá
                             como una solicitud de retiro pendiente y sera procesada como lo indican los terminos y condiciones de 
                             retiro dentro de la compañia.</p>
                        <p>Este retiro se verá reflejado en tu estado de cuenta más los gastos financieros, 
                            quedando un saldo negativo en tu cuenta que sera pagado con futuros rendimientos, bonos, residuales y 
                            ganancias adicionales que se generen en tu cuenta.</p>
                        <p><b>Tú adelanto de saldo debe ser como mínimo del 10% de tu total de inversión y un máximo del 30%.</b>
                        </p>

                    </div>
                    <div className='d-flex justify-content-between align-items-center mb-3'>
                        <div className='form-group'>
                            <label className='form-label'>Monto Solicitado</label>
                            <input
                                min={min}
                                max={saldoDisponible}
                                onChange={handleInputChange}
                                name='montoSolicitado'
                                value={montoSolicitado}
                                type='number'
                                className='form-control text-center' />
                        </div>
                    </div>
                    <div className='alert alert-info text-center'>
                        {totalInversion && <>
                            <p>Meses máximo requeridos de recuperacion de saldo: {Math.ceil((((+montoSolicitado * 100) / totalInversion) / 5))}</p>
                            <p>Total a obtener: {montoSolicitado}</p>
                            <p>CRR generado por el préstamo: {(0.5 * totalSA).toFixed(2)}</p>
                            <p>Gasto administrativo de financiamiento: {(0.5 * totalSA).toFixed(2)}</p>
                            <p>Total a descontar: {(+montoSolicitado + totalSA).toFixed(2)}</p>
                        </>
                        }
                    </div>
                </div>
            </div>
            <div className='row justify-content-center'>
                <div className='col-md-4 text-center'>
                    <button className='btn btn-primary w-100' onClick={postSaldo}>Solicitar Adelanto</button>
                    {isLoading ? <p className='text-center fs-6 mt-3'>Cargando...</p> :
                        <p className={`text-center mt-3 fs-6 ${error ? 'text-danger' : 'text-success'}`}>{message && message.toUpperCase()}</p>}
                </div>
            </div>
        </div>
    )
}
