import React, { useEffect, useRef, useState } from 'react';
import AppleSystemConsume from 'services/AppleSystemService';
import CalculaldoraFondeo from 'components/CalculadoraFondeo';
import DepositForm from 'components/forms/DepositForm';
import TextoDeposito from 'components/TextoDepositos';
import { ApiResponse, BANK_DEPOSIT } from '../../types/Wallets';

export default function DepositoBancario() {

    const [wallets, setWallets] = useState<BANK_DEPOSIT[]>();
    const [valor, setValor] = useState<number>(0);
    const [fechaFondeo, setFechaFondeo] = useState<string>('');
    const [moneda, setMoneda] = useState<string>('');
    const [message, setMessage] = useState<string>();
    const [error, setError] = useState<boolean>();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const formRef = useRef<HTMLFormElement | null>(null);
    const fileRef = useRef<HTMLInputElement | null>(null);

    const handleFormChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        if (name === 'valor') {
            setValor(Number(value));
        } else if (name === 'fechaFondeo') {
            setFechaFondeo(value);
        } else if (name === 'moneda') {
            setMoneda(value);
        }
    };

    const fetchWallets = () => {
        AppleSystemConsume('wallets')
            .get('')
            .then((res: ApiResponse) => {
                setWallets(res.WALLETS.BANK_DEPOSIT)
            })
    };

    useEffect(() => {
        fetchWallets();
    }, []);

    const postDeposit = (e: React.FormEvent) => {
        e.preventDefault();
        if (!formRef.current) return;
        if (!fileRef.current) return;
        const data = new FormData(formRef.current);
        setIsLoading(true);
        AppleSystemConsume('acquire-balance')
            .post('bank-deposit', data, {})
            .then((res: ApiResponse) => {
                if (res.error !== false) setError(res.error)
                setError(res.error)
                setMessage(res.message);
            })
            .catch((err) => {
                setMessage(err.message);
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    return (
        <div>
            <p className="text-center p-4">Via Depósito Bancario y Pago con Tarjeta de Crédito</p>
            <div className="d-flex flex-column">
                <p className="text-center">Depositar en una de las siguientes cuentas:</p>
                <div className="d-flex flex-column items-center m-auto bg-gray-400 bg-gradient">
                    <div className='d-flex justify-center'>
                        {wallets?.slice(0, 2).map((option, index) => (
                            <div key={index} className="m-auto">
                                <div>
                                    <h4>{option.TITLE}</h4>
                                    <ul>
                                        {option.FIELDS.map((field, idx) => (
                                            <li key={idx}>
                                                <strong>{field.name}:</strong> {field.value}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className='d-flex flex-column justify-start p-2'>
                        <TextoDeposito tipo="bancario" />
                    </div>
                    <CalculaldoraFondeo />
                    <div className='d-flex justify-center mt-4'>
                        {wallets?.slice(2, 4).map((option, index) => (
                            <div key={index} className="m-auto">
                                <div>
                                    <h4>{option.TITLE}</h4>
                                    <ul>
                                        {option.FIELDS.map((field, idx) => (
                                            <li key={idx}>
                                                <strong>{field.name}:</strong> {field.value}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className='d-flex flex-column justify-start p-2'>
                        <TextoDeposito tipo="transferencia" />
                    </div>
                    <DepositForm
                        onPress={postDeposit}
                        formRef={formRef}
                        fileRef={fileRef}
                        onChange={handleFormChange}
                        valor={valor}
                        fechaFondeo={fechaFondeo}
                        moneda={moneda}
                        inputAvailable={true}
                    />
                    {isLoading ? <p className='text-center fs-6 mt-3'>Cargando...</p> :
                        <p className={`text-center mt-3 fs-6 ${error ? 'text-danger' : 'text-success'}`}>{message && message.toUpperCase()}</p>}
                </div>
            </div>
        </div>
    );
}
