import React from 'react';

export default function TextSkeleton() {
  return (
    <p title="Cargando" className="card-text placeholder-glow">
      <span className="placeholder mx-1 col-7"></span>
      <span className="placeholder mx-1 col-4"></span>
      <span className="placeholder mx-1 col-4"></span>
      <span className="placeholder mx-1 col-6"></span>
      <span className="placeholder mx-1 col-8"></span>
      <span className="placeholder mx-1 col-1"></span>
      <span className="placeholder mx-1 col-7"></span>
      <span className="placeholder mx-1 col-4"></span>
      <span className="placeholder mx-1 col-4"></span>
      <span className="placeholder mx-1 col-6"></span>
      <span className="placeholder mx-1 col-8"></span>
      <span className="placeholder mx-1 col-3"></span>
    </p>
  );
}
