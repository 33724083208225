import React from 'react'
import Platform from 'types/Platform';
import Logo from '../Logo';
import './reload.css'

export default function ReloadRecovey() {

    const params = new URLSearchParams(window.location.search);
    const platform = params.get('platform') as Platform;
    const loginUrl = `${window.location.origin}/login`;

    const moveToLogin = () => {
        window.location.href = loginUrl;
    }

    return (
        <div className='body-registration'>
            <header className='container-logo'>
                <div className='logo'>
                    <Logo id="logo_desktop" platform={platform} logoColor="white" />
                </div>
            </header>
            <div className='container-register'>
                <div className='texto'>
                    <h4>Email enviado</h4>
                    <p style={{ fontSize: '15px' }}>Hemos recibido tu solicitud para restablecer la contraseña.
                        Revisa tu correo electrónico para obtener el link que te llevara a completar el proceso.
                        Si no recibes el correo en unos minutos,
                        verifica la carpeta de correo no deseado o intenta nuevamente. ¡Gracias!</p>
                    <div className='button-login'>
                        <button onClick={moveToLogin}>
                            Volver
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
