/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-shadow
enum EPosicion {
  REPRESENTANTE = 'Representante',
  USUARIO = 'Usuario',
  R_INVERSOR = 'R Inversor',
  R_ACTIVO = 'R Inversor',
  LIDER_TITANIO = 'Lider Titanio',
  REPRESENTANTE_INVERSOR = 'Representante Inversor',
  RENOVACION_DE_INSCRIPCION = 'Renovacion de inscripcion',
  REPRESENTANTE_ACTIVO = 'Representante Activo',
}

export default EPosicion;
