import React, { useEffect, useState, useRef } from 'react';
import CalculadoraFondeo from 'components/CalculadoraFondeo';
import AppleSystemConsume from 'services/AppleSystemService';
import DepositForm from 'components/forms/DepositForm';

type Response = {
    error: boolean;
    code: number;
    message: string;
    WALLETS: {
        OXXO: string;
    };
}

export default function DepositoOxxo() {
    const [oxxo, setOxxo] = useState<string | null>(null);
    const [valor, setValor] = useState<number>(0);
    const [fechaFondeo, setFechaFondeo] = useState<string>('');
    const [message, setMessage] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>();

    const formRef = useRef<HTMLFormElement | null>(null);
    const fileRef = useRef<HTMLInputElement | null>(null);

    const requestOxxo = () => {
        AppleSystemConsume('wallets')
            .get('')
            .then((res: Response) => {
                setOxxo(res.WALLETS.OXXO);
            });
    };

    const handleFormChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        if (name === 'valor') {
            setValor(Number(value));
        } else if (name === 'fechaFondeo') {
            setFechaFondeo(value);
        }
    };

    useEffect(() => {
        requestOxxo();
    }, []);

    const postOxxo = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!formRef.current) return;
        if (!fileRef.current) return;
        const data = new FormData(formRef.current);
        setIsLoading(true)
        await AppleSystemConsume('acquire-balance')
            .post('oxxo-deposit', data, {})
            .then((res: Response) => {
                if (res.error !== false) setError(res.error)
                setError(res.error)
                setMessage(res.message);
            })
            .catch((err) => {
                setMessage(err.message);
            })
            .finally(() => {
                setIsLoading(false);
            })
    };

    return (
        <div className="container mt-4">
            <h2 className="text-center mb-4">Via Oxxo México</h2>
            <div className="card text-center mb-4">
                <div className="card-body" style={{ backgroundColor: '#f8f9fa', color: '#333' }}>
                    <h5 className="card-title" style={{ fontWeight: '600' }}>Cuenta depósito por tarjeta:</h5>
                    <div className='alert alert-info'>
                        <p className="card-text" style={{ fontWeight: '600' }}>Cuenta Scotiabank:</p><p>{oxxo}</p>
                        <p className="card-text">
                            * El máximo deposito por oxxo es de 5000 $MX. Si desea fondear un saldo mayor, debe realizar varios fondeos.
                        </p>
                    </div>
                </div>
            </div>
            <div className="d-flex flex-column gap-3 p-5 mb-4">
                <CalculadoraFondeo />
                <DepositForm
                    onPress={postOxxo}
                    formRef={formRef}
                    fileRef={fileRef}
                    onChange={handleFormChange}
                    valor={valor}
                    fechaFondeo={fechaFondeo}
                />
                {isLoading ? <p className='text-center fs-6 mt-3'>Cargando...</p> :
                    <p className={`text-center mt-3 fs-6 ${error ? 'text-danger' : 'text-success'}`}>{message && message.toUpperCase()}</p>}
            </div>
            <div className="alert alert-danger">
                * Todo fondeo por depósito oxxo tiene un cargo de 3%.<br />
                * Puede tardar en aparecer en su saldo de 1 a 3 días hábiles.<br />
                * Toda solicitud de retiro o fondeo que entra del exterior a cuentas bancarias mexicanas o
                sale al exterior enviado por plataformas de dispersión, <br />el SApoint tomará el valor del precio en México,
                esto es mientras nuestra compañía no se encuentre localmente con cuentas bancarias en sus países. <br />
                Puede haber variaciones en el precio final y comisiones extras.
            </div>
        </div>
    );
}
