import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import '../Registration.css';
import Platform from 'types/Platform';
import AppleSystemConsume from 'services/AppleSystemService';
import BackButtonRegister from 'components/BackButtonRegister';
import Logo from './Logo';
import { useRegistration } from '../context/RegistrationContext';


interface FormData {
  registrationId: string;
  username: string;
  aceptaTyC: boolean;
  sponsor: string | undefined;
}

interface ServerResponse {
  routes: { next: string }
  error: boolean;
  code: number;
  message: string;
}

export const DatosCuentaSponsor: React.FC = () => {

  const { setRegistrationIdValue } = useRegistration();
  const { sponsor } = useParams();
  const params = new URLSearchParams(window.location.search);
  const platform = params.get('platform') as Platform;
  const newLocal = 'white';
  const navigate = useNavigate(); 
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [userAvailable, setUserAvailable] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [formdata, setFormdata] = useState<FormData>({
    registrationId: localStorage.getItem('registrationId') || '',
    username: '',
    aceptaTyC: false,
    sponsor,
  });

  const tycURL = 'https://www.sistemaapple.com.mx/assets/img/terminosycondiciones.pdf';

  useEffect(() => {
    const registrationIdParams = params.get('registrationId');
    if (registrationIdParams) {
      localStorage.setItem('registrationId', registrationIdParams)
      setFormdata({...formdata, registrationId: registrationIdParams})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchSponsor = () => {
    AppleSystemConsume('registration')
    .post('start', {}, {})
    .then((res) => {
      const { registrationId = null, routes = {} } = res;
      if (!registrationId || !routes.next) throw new Error(res.message);
      localStorage.setItem('registrationId', registrationId);
      setRegistrationIdValue(registrationId);
      setFormdata(prevState => ({
        ...prevState,
        registrationId,
      }));
    });
  }

  useEffect(() => {
    fetchSponsor();    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkUsernameAvailability = async (nombreUsuario: string) => {
    if(!nombreUsuario) return;
    setUserAvailable(false);
    setErrorMessage('')
    AppleSystemConsume('user')
      .post('exists', { nombreUsuario }, {})
      .then((res: ServerResponse) => {
        if(res.error) throw new Error(res.message);
        setUserAvailable(true)
      })
      .catch((error: any) => {
        setErrorMessage(error.message)
      })
  };

  const sendFormDataToServer = async () => {
    setIsLoading(true);
    try {
      const res: ServerResponse = await AppleSystemConsume('registration').post('user_validation', formdata, {});
      if (res.error) {
        throw new Error(res.message);
      }
      navigate(res.routes.next);
    } catch (error: any) {
      setErrorMessage(error.message);
    } finally {
      setIsLoading(false);
    }
  };
  
  const handleCheckboxChange = () => {
    setFormdata({
      ...formdata,
      aceptaTyC: !formdata.aceptaTyC,
    });
  };

  const inputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLInputElement>) => {
    const { id, value, type, checked } = e.target;
    setFormdata({ ...formdata, [id]: type === 'checkbox' ? checked : value });
  };

  return (
    <>
      <div className='body-registration'>
      <div className='back-button'>
          <BackButtonRegister />
        </div>
        <header className='container-logo'>
          <div className='logo'>
            <Logo id="logo_desktop" platform={platform} logoColor={newLocal} />
          </div>
        </header>
        <div className='container-register'>
          <div className='texto-datosCuenta'>
            <h4 style={{ fontSize: '30px' }}>Datos de tu Cuenta</h4>
            <p style={{ fontSize: '17px' }}>Paso 1 de 5</p>
            <div className='centered-content-datosCuenta'>
              <div className='form-group-datosCuenta'>
                <input
                  type="text"
                  id='username'
                  placeholder='Nombre Usuario'
                  value={formdata.username}
                  // onChange={(e) => setFormdata({ ...formdata, username: e.target.value })}
                  onChange={(e) => { inputChange(e) }}
                  onBlur={() => checkUsernameAvailability(formdata.username)}
                />
              </div>
              <div className='form-group-datosCuenta'>
                <input
                  type="text"
                  readOnly
                  className='sponsorClass'
                  id='sponsor'
                  placeholder={formdata.sponsor}
                  value={formdata.sponsor}
                />
              </div>
              <p style={{ fontSize: '10px' }} className='text-dark'>Usted se registra con el patrocinio de: {formdata.sponsor}</p>
              <div className='form-group-tyc'>
                <label className='text-dark' htmlFor="aceptaTyC">
                  <input
                    type="checkbox"
                    id="aceptaTyC"
                    checked={formdata.aceptaTyC}
                    onChange={handleCheckboxChange}
                  />
                  Acepto los{' '}
                  <a href={tycURL} target='_blank' style={{ textDecoration: 'underline' }} rel="noreferrer">
                    términos y condiciones
                  </a>
                  {' '}de Sistema Apple MLM.
                </label>
              </div>
              <div className='button-register'>
                <button onClick={sendFormDataToServer} disabled={isLoading || !userAvailable || !formdata.aceptaTyC}>
                  {isLoading ? 'Cargando...' : 'Continuar'}
                </button>
                <p className='text-danger'>{errorMessage}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

