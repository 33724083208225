import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import '../Registration.css';
import Platform from 'types/Platform';
import AppleSystemConsume from 'services/AppleSystemService';
import Logo from './Logo';
import { useRegistration } from '../context/RegistrationContext';

export const Registration = () => {

  const { setRegistrationIdValue } = useRegistration();
  const { sponsor } = useParams(); // Obtén el parámetro sponsor
  const params = new URLSearchParams(window.location.search);
  const platform = params.get('platform') as Platform;

  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const sendRegistrationId = async () => {
    setIsLoading(true);
    setMessage('');
    AppleSystemConsume('registration')
      .post('start', {}, {})
      .then((res) => {
        const { registrationId = null, routes = {} } = res;
        if (!registrationId || !routes.next) throw new Error(res.message);
        localStorage.setItem('registrationId', registrationId);
        setRegistrationIdValue(registrationId);
        // Si el usuario tiene un sponsor, redirige a /registration/email_validation
        if (sponsor) {
          navigate('/registration/email_validation');
        } else {
          navigate(routes.next);
        }
      })
      .catch((err: any) => {
        setMessage(err.message);
      })
      .finally(() => {
        setIsLoading(false);
      })
  };

  const checkRegistrationId = () => {
    const registrationId = localStorage.getItem('registrationId');
    if (!registrationId) {
      return;
    }

    AppleSystemConsume('registration')
      .post('check-status', { registrationId }, {})
      .then((res) => {
        const { next } = res;
        if (!next) {
          return;
        }
        navigate(next);
      })
  }

  useEffect(() => {
    checkRegistrationId();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className='body-registration'>
      <header className='container-logo'>
        <div className='logo'>
          <Logo id="logo_desktop" platform={platform} logoColor="white" />
        </div>
      </header>
      <div className='container-register'>
        <div className='texto'>
          <h4 style={{ fontSize: '30px' }}>Creación de Cuenta</h4><br /><br />
          <p style={{ fontSize: '17px' }}>Te solicitaremos algunos datos<br />
            para la creación de tu cuenta</p><br /><br />
          <p className='p-start'>Crea tu cuenta en 5 simples pasos.</p><br /><br /><br />
          <div className='text-center'>
            <button className="button-register-registration" onClick={sendRegistrationId} disabled={isLoading}>
              {isLoading ? 'Cargando...' : 'Iniciar Registro'}
            </button>
          </div>
          <p className='text-center text-danger'>{message}</p>
        </div>
      </div>
    </div>
  );
};
