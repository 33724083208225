/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { v4 as uuid } from 'uuid';

import './login.css';

import Button from 'components/Button';
import Article from 'components/templates/Article';
import { init, logout } from 'store/reducers/auth.reducers';
import { useAppDispatch } from 'store/hooks';
import api from 'utils/api';
import { FormControl } from 'components/forms/FormControl';
import OnKeyEnterHook from 'hooks/OnKeyEnterHook';

const Login = () => {
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [pwarning, setPwarning] = useState<string>('');

  const [onFetch, setOnFetch] = useState<boolean>(false);
  const [showPwd, setShowPwd] = useState<boolean>(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const idUsername = uuid();
  const idPassword = uuid();

  useEffect(() => {
    dispatch(logout());
  }, [dispatch]);

  const handleLogin = () => {
    setPwarning('');
    setOnFetch(true);
    api('auth')
      .post('login', {
        username,
        password,
      })
      .then((res: any) => {
        setOnFetch(false);
        if (!res.success) {
          setPwarning('usuario o contraseña incorrecto');
          return;
        }

        if (['admin', 'A3'].includes(res.usertype)) {
          dispatch(init({ jwt: res.jwt, userType: res.usertype, user: null, posicion: '' }));
          navigate('/admin-panel/home');
          return;
        }

        setPwarning('no posee permisos para este portal');
      });
  };

  return (
    <div className="row m-0" style={{ height: '100vh' }}>
      <div className="col-lg-7 col-12 container p-5 my-auto" style={{ maxWidth: '50em' }}>
        <div className="logo-img mb-5" />
        <Article>
          <form className="pt-5">
            <h3 className="text-center">Iniciar Sesión</h3>
            <FormControl className="mb-3" title="Nombre de usuario:" uuid={idUsername}>
              <input
                id={idUsername}
                className="form-control"
                placeholder="miusuario"
                type="text"
                name="username"
                onChange={(e) => setUsername(e.target.value)}
              />
            </FormControl>
            <FormControl className="mb-3" title="Contraseña:" uuid={idPassword}>
              <div className="d-password">
                <input
                  onKeyDown={(e) => OnKeyEnterHook(e, () => handleLogin())}
                  className="form-control"
                  type={showPwd ? 'text' : 'password'}
                  name="password"
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="******"
                  id={idPassword}
                />
                <div className="column-icon" onClick={() => setShowPwd(!showPwd)} title="Mostrar/Ocultar contraseña">
                  {showPwd ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />}
                </div>
              </div>
            </FormControl>
            <div className="my-3 text-center">
              <Button onPress={() => handleLogin()} title="Iniciar sesión" onLoad={!!onFetch} />
            </div>
          </form>
          <p title="respuesta del servidor" className="text-danger text-center">
            {pwarning}
          </p>
        </Article>
      </div>
      <div className="col-5 d-none d-lg-block bg-as-secondary"></div>
    </div>
  );
};

export default Login;
