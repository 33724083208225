import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';

// enums
import EColor from 'enums/EColor';

export default function ButtonIcon({
  color = EColor.DEFAULT,
  icon,
  onPress,
  title = 'ver',
}: {
  title?: string;
  color?: EColor;
  icon: IconDefinition;
  onPress: Function;
}) {
  return (
    <span title={title} className={`m-1 btn ${color} text-capitalize`} onClick={() => onPress()}>
      <span style={{ padding: '0.2em' }}>
        <FontAwesomeIcon icon={icon} />
      </span>
    </span>
  );
}
