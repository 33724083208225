import TarjetaInicio from 'components/templates/TarjetaInicio';
import { useEffect, useState } from 'react';
import AppleSystemConsume from 'services/AppleSystemService';
import '../templates/template.css'
import { parsePrice } from 'utils/parser';

interface TotalContribution {
  error: boolean;
  code: number;
  message: string;
  totalContribution: number;
  contributionLastMonth: number;
  contributionThisMonth: number;
  percentaje: string;
}


export default function TarjetaAportacion() {

  const [datosContribution, setDatosContribution] = useState<number>();
  const [datosContributionPercentaje, setDatosContributionPercentaje] = useState<string>();
  const [cargando, setCargando] = useState<boolean>(false);

  const fetchContribution = async () => {
    setCargando(true)

    AppleSystemConsume('usuario')
      .get('total-contribution')
      .then((res: TotalContribution) => {
        if (res.error || res.totalContribution === undefined || res.percentaje === undefined) throw new Error(res.message);
        setDatosContribution(res.totalContribution)
        setDatosContributionPercentaje(res.percentaje)
      })
      .finally(() => {
        setCargando(false)
      })
  }

  useEffect(() => {
    fetchContribution();
  }, [])

  const dataFetch = {
    aportacion: {
      numero: datosContribution,
      percentaje: datosContributionPercentaje,
      text: 'Total de Aportación',
      txtPercentaje: 'del mes pasado',
      fondo: '#FFFFFF',
      colorLetter: '#000000',
      colorPercentaje: '#5FC507',
      fill: Number(datosContributionPercentaje) >= 0 ? '#5FC507' : 'E20616'
    }
  }

  return (
    <div className='tarjeta-margin'>
      {Object.values(dataFetch).map((data, index) => (
        <TarjetaInicio
          key={index}
          onLoad={cargando}
          text={data.text}
          numero={parsePrice(Number(data.numero))}
          percentaje={data.percentaje}
          txtPercentaje={data.txtPercentaje}
          fondo={data.fondo}
          colorLetter={data.colorLetter}
          colorPercentaje={data.colorPercentaje}
          fill={data.fill}
        />
      ))}
    </div>
  )
}
