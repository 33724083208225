export default function TarjetaResponsabilidad({title = 'Title', price}: {title:string, price: number}) {

    return (
        <div 
        style={{borderRadius: 20, width: 200, height: 200}} 
        className="d-flex flex-column gap-5 bg-white shadow-lg text-center">
            <div className="d-flex flex-column p-3">
                <p style={{fontSize: 18, fontWeight: '600'}}>{title}</p>
                <p style={{fontSize: 18, fontWeight: '600'}}>${price}</p>    
            </div> 
        </div>
    )
}