import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDownWideShort, faArrowDownShortWide } from '@fortawesome/free-solid-svg-icons';
import React, { useState } from 'react';
import './th.css';

type Props = {
  children: React.ReactNode;
  sorted?: boolean;
  initiarSort?: number;
  // eslint-disable-next-line no-unused-vars
  onChange?: (sort: number) => void;
}

export default function Th({ children, sorted = false, initiarSort = 0, onChange = () => {} }: Props) {
  const [sort, setSort] = useState(initiarSort);
  return <th className='column-name'>{children}
    {
      sorted
        ? <FontAwesomeIcon
        className='cursor-pointer'
        color={sort === 0 ? '#ccc' : ''}
        onClick={() => {setSort(sort === -1 ? 1 : -1); onChange(sort === -1 ? 1 : -1)}}
        icon={sort === -1 ? faArrowDownShortWide : faArrowDownWideShort}
      />
        : <></>
    }
  </th>
}