import notificationAdmin from './notificationAdmin.reducer';
import tipoCambio from './tipoCambio.reducer';
import auth from './auth.reducers';
import config from './config.reducer';

const reducers = {
  notificationAdmin,
  tipoCambio,
  auth,
  config,
};

export default reducers;
