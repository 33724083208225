import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import '../Registration.css'
import Platform from 'types/Platform';
import AppleSystemConsume from 'services/AppleSystemService';
import Modal from 'components/Modal';
import BackButtonRegister from 'components/BackButtonRegister';
import Logo from './Logo';


interface FormData {
  registrationId: string;
  username: string;
  sponsor: string;
  aceptaTyC: boolean;
}

interface ServerResponse {
  routes: { next: string }
  error: boolean;
  code: number;
  message: string;
  userInProgressRegistration: boolean;
}

export const DatosCuenta: React.FC = () => {

  const params = new URLSearchParams(window.location.search);
  const platform = params.get('platform') as Platform;
  const newLocal = 'white';

  const navigate = useNavigate(); // Obtiene la función de navegación

  const [isLoading, setIsLoading] = useState<boolean>(false); // Agrega un estado isLoading
  const [userAvailable, setUserAvailable] = useState<boolean>(false); // Permite saber si el usuario existe
  const [sponsorAvailable, setSponsorAvailable] = useState<boolean>(false); // Permite saber si el sponsor existe
  const [tycAccepted, setTycAccepted] = useState<boolean>(false); // Permite saber si el usuario aceptó los tyc
  const [errorMessageSponsor, setErrorMessageSponsor] = useState<string>(''); // Muestra un mensaje de error
  const [errorMessageUser, setErrorMessageUser] = useState<string>(''); // Muestra un mensaje de error
  const [errorMessage, setErrorMessage] = useState<string>(''); // Muestra un mensaje de error
  const [messageInProgress, setMessageInProgress] = useState<string>('');
  const [isSendEmail, setIsSendEmail] = useState<boolean>(false);
  const [messageSuccessEmail, setMessageSuccessEmail] = useState<string>('Enviando Correo...');
  const [formdata, setFormdata] = useState<FormData>({
    registrationId: localStorage.getItem('registrationId') || '',
    username: '',
    sponsor: '',
    aceptaTyC: false
  });

  useEffect(() => {
    const registrationIdParams = params.get('registrationId');
    if (registrationIdParams) {
      localStorage.setItem('registrationId', registrationIdParams)
      setFormdata({...formdata, registrationId: registrationIdParams})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const checkSponsorAvailability = async (nombreUsuario: string) => {
    if (!nombreUsuario) return;
    setMessageInProgress('');
    setSponsorAvailable(false);
    setErrorMessageSponsor('');
    AppleSystemConsume('user')
      .post('exists', { nombreUsuario }, { sponsor_verification: 'true' })
      .then((res: ServerResponse) => {
        if (res.error) throw new Error(res.message);
        setSponsorAvailable(true)
      })
      .catch((err: any) => {
        setErrorMessageSponsor(err.message);
      })
  };

  const checkUsernameAvailability = async (nombreUsuario: string) => {
    if (!nombreUsuario) return;
    setUserAvailable(false);
    setErrorMessageUser('');
    AppleSystemConsume('user')
      .post('exists', { nombreUsuario }, {})
      .then((res: ServerResponse) => {
        if (res.error) throw new Error(res.message);
        setUserAvailable(true);
        if (res.userInProgressRegistration) {
          setMessageInProgress(res.message);
        }
      })
      .catch((err: any) => {
        setErrorMessageUser(err.message);
      })
  };

  const inputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLInputElement>) => {
    const { id, value, type, checked } = e.target;
    setFormdata({ ...formdata, [id]: type === 'checkbox' ? checked : value });
  };

  const sendFormDataToServer = async () => {
    if (isLoading) {
      return;
    }
    if (!userAvailable) {
      setErrorMessage('Por favor, escribe un nombre usuario válido.');
      return;
    }

    if (!sponsorAvailable) {
      setErrorMessage('Por favor, escribe un patrocinador válido.');
      return;
    }

    if (!tycAccepted) {
      setErrorMessage('Por favor, acepta los términos y condiciones.');
      return;
    }

    setIsLoading(true);

    AppleSystemConsume('registration')
      .post('user_validation', formdata, {})
      .then((res: ServerResponse) => {
        if (res.error) throw new Error(res.message);
        navigate(res.routes.next);
      })
      .catch((err: any) => {
        setErrorMessage(err.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleCheckboxChange = () => {
    setTycAccepted(!tycAccepted)
  }

  const continueRegistration = async () => {
    setIsSendEmail(true);
    try {
      AppleSystemConsume('registration')
        .post('continue-registration', { username: formdata.username }, {})
        .then((res) => {
          setMessageSuccessEmail(res.message);
        })
        .catch((err: any) => {
          setErrorMessage(err.message);
        })
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <div className='body-registration'>
        <div className='back-button'>
          <BackButtonRegister />
        </div>
        <header className='container-logo'>
          <div className='logo'>
            <Logo id="logo_desktop" platform={platform} logoColor={newLocal} />
          </div>
        </header>
        <div className='container-register'>
          <div className='texto-datosCuenta'>
            <h4 style={{ fontSize: '30px' }}>Datos de tu Cuenta</h4>
            <p style={{ fontSize: '17px' }}>Paso 1 de 5</p>
            <div className='centered-content-datosCuenta'>
              <div className='form-group-datosCuenta'>
                <input
                  type="text"
                  id='username'
                  placeholder='Nombre Usuario'
                  value={formdata.username}
                  onChange={(e) => { inputChange(e) }}
                  onBlur={() => checkUsernameAvailability(formdata.username)}
                />
                <p className='text-center text-danger'>{errorMessageUser}</p>
                {
                  messageInProgress
                    ?
                    <p>{messageInProgress}
                      <span className='text-primary cursor-pointer'
                        onClick={continueRegistration}> Haga click aquí para enviar correo
                      </span>
                    </p>
                    : <></>
                  //               
                }
              </div>
              <div className='form-group-datosCuenta'>
                <input
                  type="text"
                  id='sponsor'
                  className='sponsorDatos'
                  placeholder='Usuario Patrocinador'
                  value={formdata.sponsor}
                  onChange={(e) => { inputChange(e) }}
                  onBlur={() => checkSponsorAvailability(formdata.sponsor)}
                />
                <p className='text-center text-danger'>{errorMessageSponsor}</p>
              </div>
              <div className='form-group-tyc'>
                <label htmlFor="aceptaTyC">
                  <input
                    type="checkbox"
                    id="aceptaTyC"
                    checked={tycAccepted}
                    onChange={handleCheckboxChange}
                  />
                  <span>Acepto los </span>
                  <a href="https://www.sistemaapple.com.mx/assets/img/terminosycondiciones.pdf" style={{ textDecoration: 'underline' }}>
                    términos y condiciones
                  </a>
                  <span> de Sistema Apple MLM</span>
                </label>
              </div>
              <div className='button-register'>
                <div className='button-container'>
                  <button onClick={sendFormDataToServer}>
                    {isLoading ? 'Cargando...' : 'Continuar'}
                  </button>
                </div>
                <p className='text-center text-danger'>{errorMessage}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal showFooter={false} title='Enviando Correo' onClose={() => {}} show={isSendEmail}>
          <p className='m-5'>{messageSuccessEmail}</p>
        </Modal>
    </>
  );
};
