import React, { useState } from 'react';
import AppleSystemConsume from 'services/AppleSystemService';

export default function AdquirirSaldo() {

  const [codigo, setCodigo] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCodigo(e.target.value);
  };

  const adquirirSaldo = () => {
    setIsLoading(true)
    AppleSystemConsume('prepaid-cards')
      .post('redem', { codigo }, {})
      .then((res) => {
        if (res.error !== false) setError(res.error)
        setError(res.error)
        setMessage(res.message)
      })
      .catch(err => {
        setMessage(err.message)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <div className="d-flex flex-column min-vh-100 w-100 p-3">
      <p className="text-center fs-2">Canjear Tarjeta prepaga</p>
      <div className="d-flex flex-column align-items-center p-5"
        style={{ background: 'linear-gradient(#e9ecef, #ffffff)', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
        <div className="d-flex flex-column w-50 mb-3">
          <p className="text-center fs-4 mb-3">Código de tarjeta prepaga</p>
          <input
            onChange={handleInputChange}
            type="text"
            name="codigo"
            id="codigo"
            value={codigo}
            className="form-control mb-3 text-center fs-5" />
          <p className="text-center fs-6 p-2 text-warning bg-warning bg-opacity-25 rounded">
            Una vez realizada la solicitud, la misma debe ser aceptada por el administrador.
          </p>
        </div>
        <button
          style={{ backgroundColor: 'var(--color-as)', border: 'none' }}
          type="button"
          className="btn btn-primary btn-sm"
          onClick={adquirirSaldo}
          disabled={isLoading}
        >
          Solicitar Fondeo
        </button>
        {isLoading ? <p className='text-center fs-6 mt-3'>Cargando...</p> :
          <p className={`text-center mt-3 fs-6 ${error ? 'text-danger' : 'text-success'}`}>{message.toUpperCase()}</p>}
      </div>
    </div>
  );
}
