export const PAGINATION_CONFIG = {
  ITEMS_PAGES: 30,
};

const USER_TYPE = {
  ADMIN: 'admin',
  ADMIN_3: 'A3',
};

const ROUTES = {
  USER_PANEL: 'user-panel',
  ADMIN_PANEL: 'admin-panel',
  USUARIOS: 'usuarios',
  PROCESO: 'proceso',
  USUARIO: 'usuario',
  VER_USUARIO: 'ver',
  // Usuarios
  USUARIOS_REGISTRADOS: 'registrados',
  USUARIOS_SOLICITUDES_VALIDACION: 'solicitudes-validacion',
  USUARIOS_REPRESENTANTES_ACTIVOS: 'representantes-activos',
  USUARIOS_DESHABILITADOS: 'deshabilitados',
  USUARIOS_REPRESENTANTES: 'representantes',
  ADMIN_PANEL_USUARIOS: '',
  ADMIN_PANEL_USUARIOS_REGISTRADOS: '',
  ADMIN_PANEL_USUARIOS_SOLICITUDES_VALIDACION: '',
  ADMIN_PANEL_USUARIOS_REPRESENTANTES_ACTIVOS: '',
  ADMIN_PANEL_USUARIOS_DESHABILITADOS: '',
  ADMIN_PANEL_USUARIOS_REPRESENTANTES: '',
  // Agencia de Viajes
  AGENCIA_VIAJES: 'agencia-de-viajes',
  AGENCIA_VIAJES_ABIERTAS: 'abiertas',
  AGENCIA_VIAJES_CERRADAS: 'cerradas',
  AGENCIA_VIAJES_CONFIRMADAS: 'confirmadas',
  ADMIN_PANEL_AGENCIA_VIAJES: '',
  ADMIN_PANEL_AGENCIA_VIAJES_ABIERTAS: '',
  ADMIN_PANEL_AGENCIA_VIAJES_CERRADAS: '',
  ADMIN_PANEL_AGENCIA_VIAJES_CONFIRMADAS: '',
  // Paquetes
  PAQUETES: 'paquetes',
  PAQUETES_ACTIVOS: 'activos',
  PAQUETES_CANCELADOS: 'cancelados',
  ADMIN_PANEL_PAQUETES: '',
  ADMIN_PANEL_PAQUETES_ACTIVOS: '',
  ADMIN_PANEL_PAQUETES_CANCELADOS: '',

  ADMIN_PANEL_VER_USUARIO: '',
  ADMIN_PANEL_AGREGAR_PAQUETE_USUARIO: '',
  AGREGAR_PAQUETE_USUARIO: 'agregar-paquete-usuario',
  AGREGAR_PROCESO_USUARIO: 'agregar-proceso-usuario',
  ADMIN_PANEL_AGREGAR_PROCESO_USUARIO: '',
};

const ACCESS = {
  ADMIN_PANEL: {
    USUARIOS: {
      VIEW: [USER_TYPE.ADMIN, USER_TYPE.ADMIN_3],
      REGISTRADOS: {
        VIEW: [USER_TYPE.ADMIN, USER_TYPE.ADMIN_3],
        EDIT: [USER_TYPE.ADMIN],
      },
      SOLICITUDES_VALIDACION: {
        VIEW: [USER_TYPE.ADMIN, USER_TYPE.ADMIN_3],
        EDIT: [USER_TYPE.ADMIN],
      },
    },
    CONCURSO_DE_VIAJE: {
      VIEW: [USER_TYPE.ADMIN, USER_TYPE.ADMIN_3],
      EDIT: [USER_TYPE.ADMIN, USER_TYPE.ADMIN_3],
    },
    PAGO_SISTEMA_APPLE: {
      VIEW: [USER_TYPE.ADMIN, USER_TYPE.ADMIN_3],
      EDIT: [USER_TYPE.ADMIN],
    },
    SOLICITUDES: {
      VIEW: [USER_TYPE.ADMIN, USER_TYPE.ADMIN_3],
      FONDEO: {
        VIEW: [USER_TYPE.ADMIN, USER_TYPE.ADMIN_3],
        EDIT: [USER_TYPE.ADMIN],
      },
      RETIRO: {
        VIEW: [USER_TYPE.ADMIN, USER_TYPE.ADMIN_3],
        EDIT: [USER_TYPE.ADMIN],
      },
      RETIRO_EXTEMPORANEO: {
        VIEW: [USER_TYPE.ADMIN, USER_TYPE.ADMIN_3],
        EDIT: [USER_TYPE.ADMIN],
      },
      PAQUETE: {
        VIEW: [USER_TYPE.ADMIN, USER_TYPE.ADMIN_3],
        EDIT: [USER_TYPE.ADMIN],
      },
      TRANSFERENCIA: {
        VIEW: [USER_TYPE.ADMIN, USER_TYPE.ADMIN_3],
        EDIT: [USER_TYPE.ADMIN],
      },
      ADELANTO_SALDO: {
        VIEW: [USER_TYPE.ADMIN, USER_TYPE.ADMIN_3],
        EDIT: [USER_TYPE.ADMIN],
      },
    },
    PAQUETE: {
      VIEW: [USER_TYPE.ADMIN, USER_TYPE.ADMIN_3],
      ACTIVOS: {
        VIEW: [USER_TYPE.ADMIN, USER_TYPE.ADMIN_3],
        EDIT: [USER_TYPE.ADMIN],
      },
      CANCELADOS: {
        VIEW: [USER_TYPE.ADMIN, USER_TYPE.ADMIN_3],
        EDIT: [USER_TYPE.ADMIN],
      },
    },
    RENDIMIENTOS: {
      VIEW: [USER_TYPE.ADMIN, USER_TYPE.ADMIN_3],
    },
    TARJETA_PREPAGA: {
      VIEW: [USER_TYPE.ADMIN, USER_TYPE.ADMIN_3],
      DISPONIBLES: {
        VIEW: [USER_TYPE.ADMIN],
      },
      CONFIRMADAS: {
        VIEW: [USER_TYPE.ADMIN, USER_TYPE.ADMIN_3],
      },
      NO_CONFIRMADAS: {
        VIEW: [USER_TYPE.ADMIN],
      },
    },
  },
};


const accesible = (section: Array<string>, usertype: string) => section.includes(usertype);

// Usuarios
ROUTES.ADMIN_PANEL_USUARIOS = `/${ROUTES.ADMIN_PANEL}/${ROUTES.USUARIOS}`;
ROUTES.ADMIN_PANEL_USUARIOS_REGISTRADOS = `${ROUTES.ADMIN_PANEL_USUARIOS}/${ROUTES.USUARIOS_REGISTRADOS}`;
ROUTES.ADMIN_PANEL_USUARIOS_SOLICITUDES_VALIDACION = `${ROUTES.ADMIN_PANEL_USUARIOS}/${ROUTES.USUARIOS_SOLICITUDES_VALIDACION}`;
ROUTES.ADMIN_PANEL_USUARIOS_REPRESENTANTES_ACTIVOS = `${ROUTES.ADMIN_PANEL_USUARIOS}/${ROUTES.USUARIOS_REPRESENTANTES_ACTIVOS}`;
ROUTES.ADMIN_PANEL_USUARIOS_REGISTRADOS = `${ROUTES.ADMIN_PANEL_USUARIOS}/${ROUTES.USUARIOS_REGISTRADOS}`;
ROUTES.ADMIN_PANEL_USUARIOS_DESHABILITADOS = `${ROUTES.ADMIN_PANEL_USUARIOS}/${ROUTES.USUARIOS_DESHABILITADOS}`;
ROUTES.ADMIN_PANEL_USUARIOS_REPRESENTANTES = `${ROUTES.ADMIN_PANEL_USUARIOS}/${ROUTES.USUARIOS_REPRESENTANTES}`;
ROUTES.ADMIN_PANEL_VER_USUARIO = `${ROUTES.ADMIN_PANEL_USUARIOS}/${ROUTES.VER_USUARIO}`;
// Agencia de viajes
ROUTES.ADMIN_PANEL_AGENCIA_VIAJES = `/${ROUTES.ADMIN_PANEL}/${ROUTES.AGENCIA_VIAJES}`;
ROUTES.ADMIN_PANEL_AGENCIA_VIAJES_ABIERTAS = `${ROUTES.ADMIN_PANEL_AGENCIA_VIAJES}/${ROUTES.AGENCIA_VIAJES_ABIERTAS}`;
ROUTES.ADMIN_PANEL_AGENCIA_VIAJES_CERRADAS = `${ROUTES.ADMIN_PANEL_AGENCIA_VIAJES}/${ROUTES.AGENCIA_VIAJES_CERRADAS}`;
ROUTES.ADMIN_PANEL_AGENCIA_VIAJES_CONFIRMADAS = `${ROUTES.ADMIN_PANEL_AGENCIA_VIAJES}/${ROUTES.AGENCIA_VIAJES_CONFIRMADAS}`;
// Paqueres
ROUTES.ADMIN_PANEL_PAQUETES = `/${ROUTES.ADMIN_PANEL}/${ROUTES.PAQUETES}`;
ROUTES.ADMIN_PANEL_PAQUETES_ACTIVOS = `${ROUTES.ADMIN_PANEL_PAQUETES}/${ROUTES.PAQUETES_ACTIVOS}`;
ROUTES.ADMIN_PANEL_PAQUETES_CANCELADOS = `${ROUTES.ADMIN_PANEL_PAQUETES}/${ROUTES.PAQUETES_CANCELADOS}`;

ROUTES.ADMIN_PANEL_AGREGAR_PAQUETE_USUARIO = `${ROUTES.ADMIN_PANEL}/${ROUTES.PAQUETES}/${ROUTES.AGREGAR_PAQUETE_USUARIO}`;
ROUTES.ADMIN_PANEL_AGREGAR_PROCESO_USUARIO = `/${ROUTES.ADMIN_PANEL}/${ROUTES.PROCESO}/${ROUTES.AGREGAR_PROCESO_USUARIO}`;

export { ROUTES, ACCESS, accesible };
