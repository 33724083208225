import AppleSystemConsume from 'services/AppleSystemService';
import React, { useEffect, useState } from 'react';
import { ApiResponse, CRYPTO } from 'types/Wallets';
import CalculaldoraFondeo from 'components/CalculadoraFondeo';
import DepositForm from 'components/forms/DepositForm';
import QRETH from '../../assets/images/QR-usdt-1.png';
import QRTRON from '../../assets/images/QR-usdt-2.png';

export default function ConfirmarFondeoUsdt() {

    const [crypto, setCrypto] = useState<CRYPTO>();
    const [valor, setValor] = useState<number>(0);
    const [fechaFondeo, setFechaFondeo] = useState<string>('');
    const [txid, setTxid] = useState<string>('');
    const [message, setMessage] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>();

    const handleFormChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        if (name === 'valor') {
            setValor(Number(value));
        } else if (name === 'fechaFondeo') {
            setFechaFondeo(value);
        } else if (name === 'txid') {
            setTxid(value);
        }
    };

    const getInfoWallet = () => {
        AppleSystemConsume('wallets')
            .get('')
            .then((res: ApiResponse) => {
                setCrypto(res.WALLETS.CRYPTO)
            })
            .catch(err => console.error(err));
    }

    useEffect(() => {
        getInfoWallet();
    }, []);

    const confirmFondeoUsdt = (e: React.FormEvent) => {
        e.preventDefault();
        setIsLoading(true)
        AppleSystemConsume('acquire-balance')
            .post('usdt-deposit', { txid, valor, fechaFondeo }, {})
            .then((res: ApiResponse) => {
                if (res.error !== false) setError(res.error)
                setError(res.error)
                setMessage(res.message);
            })
            .catch((err) => {
                setMessage(err.message);
                setIsLoading(false);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }


    return (
        <div className="container py-5">
            <h2 className="text-center mb-4">Fondear Via Criptomoneda (USDT) TheterUS</h2>
            <div className="card mx-auto" style={{ maxWidth: '800px' }}>
                <div className="card-body">
                    <div className="row">
                        <h5 className='text-center pb-5'>Fondear a esta dirección:</h5>
                        <div className="col-md-6 text-center mb-4 mb-md-0">
                            <img src={QRETH} alt='ethereum' className="img-fluid mb-3" style={{ maxWidth: '70%' }} />
                            {crypto && crypto.USDT.slice(0, 1).map((usdt, idx) => (
                                <div key={idx}>
                                    <p className="font-weight-bold">{usdt.NAME}</p>
                                    <p className="text-muted">{usdt.WALLET}</p>
                                </div>
                            ))}
                            <p className="text-info">* Por favor, copiar y pegar el texto, y confirmarlo antes de enviar.</p>
                        </div>
                        <div className="col-md-6 text-center">
                            <img src={QRTRON} alt='tron' className="img-fluid mb-3" style={{ maxWidth: '70%' }} />
                            {crypto && crypto.USDT.slice(1, 2).map((usdt, idx) => (
                                <div key={idx}>
                                    <p className="font-weight-bold">{usdt.NAME}</p>
                                    <p className="text-muted">{usdt.WALLET}</p>
                                </div>
                            ))}
                            <p className="text-info">* Por favor, copiar y pegar el texto, y confirmarlo antes de enviar.</p>
                        </div>
                        <p className='text-center text-info'>
                            * Poner solo enviar USDT a estas direcciones,
                            si tiene otra cripto moneda favor de convertirlas a USDT para poder enviarlas.
                        </p>
                        <p className='text-center text-info'>* Asegúrate que sea la red correcta.</p>
                    </div>
                    <CalculaldoraFondeo />
                    <div className='mt-4'>
                        <DepositForm
                            onPress={confirmFondeoUsdt}
                            onChange={handleFormChange}
                            valor={valor.toString()}
                            fechaFondeo={fechaFondeo}
                            txid={true}
                            txId={txid}
                            nameValor='Valor enviado (USDT)'
                        />
                        {isLoading ? <p className='text-center fs-6 mt-3'>Cargando...</p> :
                            <p className={`text-center mt-3 fs-6 ${error ? 'text-danger' : 'text-success'}`}>{message.toUpperCase()}</p>}
                    </div>
                    <div className="alert alert-danger p-3 text-center">
                        <p>* Todo fondeo por Thether USDT tiene un cargo de 4%.</p>
                        <p>* Puede tardar en aparecer en su saldo de 1 a 3 días hábiles.</p>
                        <p>* Toda solicitud de retiro o fondeo que entra del exterior a cuentas bancarias mexicanas o sale al exterior<br />
                            enviado por plataformas de dispersión, el SApoint tomará el valor del precio en Mexico, esto es mientras<br />
                            nuestra compañía no se encuentre localmente con cuentas bancarias en sus países. Puede haber<br />
                            variaciones en el precio final y comisiones extras.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
