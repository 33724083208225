import React from 'react'
import { parseFullDate } from 'utils/parser';

interface NewItem {
  codigo: number;
  fecha: Date;
  asunto: string;
  texto: string;
}

interface NewsItemProp {
  item: NewItem;
}

const NewsItem: React.FC<NewsItemProp> = ({ item }) => {

  return (

    <div className='m-3'>
      <div className="card text-center" style={{ boxShadow: '3px 3px 3px 2px rgba(173, 171, 171, 0.2)', background: 'var(--light-theme-article)' }}>
        <div className="card-header" style={{
          background: 'var(--color-as)',
          color: '#fff', textAlign: 'left'
        }}>
          <b>News</b>
        </div>
        <div className="card-body">
          <h5 className="card-title" style={{ fontSize: '20px', fontWeight: '800' }}>{item.asunto}</h5>
          <p className="card-text" style={{ fontSize: '15px' }}>{item.texto}</p>
        </div>
        <div className="card-footer text-body-secondary">
          {parseFullDate(item.fecha)}
        </div>
      </div>
    </div>

  )
}

export default NewsItem;