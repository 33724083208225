import TarjetaResponsabilidad from 'components/TarjetaResponsabilidad';
import { useEffect, useState } from 'react';
import AppleSystemConsume from 'services/AppleSystemService';
import { Data, ApiResponse } from '../../types/ResponsabilityType';

export default function TablaResponsabilidad() {

    const [stateResponsabilidad, setStateResponsabilidad] = useState<Data | null>(null);

    useEffect(() => {
        AppleSystemConsume('responsibility-table')
            .get('')
            .then((res: ApiResponse) => {
                setStateResponsabilidad(res.data);
            })
            .catch((err) => {
                throw new Error(err.message);
            });

    }, []);

    const totalComprado = (item: { sacoins: number, puntos: number | string }) => {
        return Number(item.sacoins) + Number(item.puntos);
    };

    return (
        <div className="d-flex flex-column items-center justify-center">
            <h1 className="text-center">Tabla de responsabilidad</h1>
            <div className='d-flex gap-5 m-auto p-5 mb-5' style={{ backgroundColor: 'snow', borderRadius: 20 }}>
                <TarjetaResponsabilidad title='Aportación Total' price={Number(stateResponsabilidad?.totalAportacion)} />
                <TarjetaResponsabilidad title='Responsabilidad inicial S.A.' price={Number(stateResponsabilidad?.responsabilidadSA)} />
                <TarjetaResponsabilidad title='Beneficio Usado' price={Number(stateResponsabilidad?.beneficioUsado)} />
                <TarjetaResponsabilidad title='Responsabilidad actual S.A' price={Number(stateResponsabilidad?.responsabilidadActualSA)} />
            </div>
            <div className='d-flex flex-column gap-5 m-auto p-5 mb-5' style={{ backgroundColor: 'snow', borderRadius: 20 }}>
                <h3 className="text-center">Pagos Administrativos</h3>
                <div className='d-flex gap-5'>
                    <TarjetaResponsabilidad title='Total comprado'
                        price={totalComprado(stateResponsabilidad?.total_administrativos || { sacoins: 0, puntos: 0 })} />
                    <TarjetaResponsabilidad title='Pagado a S.A.' price={Number(stateResponsabilidad?.total_administrativos.sacoins)} />
                    <TarjetaResponsabilidad title='Puntos Usado' price={Number(stateResponsabilidad?.total_administrativos.puntos)} />
                </div>
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col">Concepto</th>
                            <th scope="col">Total Comprado</th>
                            <th scope="col">Pagado a S.A</th>
                            <th scope="col">Puntos Usados</th>
                        </tr>
                    </thead>
                    <tbody>
                        {stateResponsabilidad && (
                            <>
                                <tr>
                                    <td style={{ fontWeight: 700 }}>Inscripción</td>
                                    <td>{totalComprado(stateResponsabilidad.inscripcion)}</td>
                                    <td>{Number(stateResponsabilidad.inscripcion.sacoins)}</td>
                                    <td>{Number(stateResponsabilidad.inscripcion.puntos)}</td>
                                </tr>
                                <tr>
                                    <td style={{ fontWeight: 700 }}>Renovación</td>
                                    <td>{totalComprado(stateResponsabilidad.renovacion)}</td>
                                    <td>{Number(stateResponsabilidad.inscripcion.sacoins)}</td>
                                    <td>{Number(stateResponsabilidad.inscripcion.puntos)}</td>
                                </tr>
                                <tr>
                                    <td style={{ fontWeight: 700 }}>Calificación</td>
                                    <td>{totalComprado(stateResponsabilidad.calificacion)}</td>
                                    <td>{Number(stateResponsabilidad.inscripcion.sacoins)}</td>
                                    <td>{Number(stateResponsabilidad.inscripcion.puntos)}</td>
                                </tr>
                                <tr>
                                    <td style={{ fontWeight: 700 }}>Mantenimiento R.A.</td>
                                    <td>{totalComprado(stateResponsabilidad.mantenimiento)}</td>
                                    <td>{Number(stateResponsabilidad.inscripcion.sacoins)}</td>
                                    <td>{Number(stateResponsabilidad.inscripcion.puntos)}</td>
                                </tr>
                            </>
                        )}
                    </tbody>
                </table>
            </div>
            <div className='d-flex flex-column gap-5 m-auto p-5 mb-5' style={{ backgroundColor: 'snow', borderRadius: 20 }}>
                <h3 className='text-center'>COMPRAS Y PAGOS (puntos, Sacoin, o financiamiento)</h3>
                <div className='d-flex gap-5'>
                    <TarjetaResponsabilidad title='Total Comprado'
                        price={totalComprado(stateResponsabilidad?.total_comprasYPagos || { sacoins: 0, puntos: 0 })} />
                    <TarjetaResponsabilidad title='Pagado a S.A.' price={Number(stateResponsabilidad?.total_comprasYPagos.sacoins)} />
                    <TarjetaResponsabilidad title='Puntos Usado' price={Number(stateResponsabilidad?.total_comprasYPagos.puntos)} />
                </div>
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col">Concepto</th>
                            <th scope="col">Total Comprado</th>
                            <th scope="col">Pagado a S.A</th>
                            <th scope="col">Puntos Usados</th>
                        </tr>
                    </thead>
                    <tbody>
                        {stateResponsabilidad && (
                            <>
                                <tr>
                                    <td style={{ fontWeight: 700 }}>Puntos Apple Golden</td>
                                    <td>{totalComprado(stateResponsabilidad.comprasAG)}</td>
                                    <td>{Number(stateResponsabilidad.comprasAG.sacoins)}</td>
                                    <td>{Number(stateResponsabilidad.comprasAG.puntos)}</td>
                                </tr>
                                <tr>
                                    <td style={{ fontWeight: 700 }}>Pagos de servicios y facturas</td>
                                    <td>{totalComprado(stateResponsabilidad.serviciosAG)}</td>
                                    <td>{Number(stateResponsabilidad.serviciosAG.sacoins)}</td>
                                    <td>{Number(stateResponsabilidad.serviciosAG.puntos)}</td>
                                </tr>
                                <tr>
                                    <td style={{ fontWeight: 700 }}>Pagos agencia de viajes</td>
                                    <td>{totalComprado(stateResponsabilidad.agenciaViajes)}</td>
                                    <td>{Number(stateResponsabilidad.agenciaViajes.sacoins)}</td>
                                    <td>{Number(stateResponsabilidad.agenciaViajes.puntos)}</td>
                                </tr>
                                <tr>
                                    <td style={{ fontWeight: 700 }}>Compras en TreidBlue</td>
                                    <td>{totalComprado(stateResponsabilidad.comprasTR)}</td>
                                    <td>{Number(stateResponsabilidad.comprasTR.sacoins)}</td>
                                    <td>{Number(stateResponsabilidad.comprasTR.puntos)}</td>
                                </tr>
                                <tr>
                                    <td style={{ fontWeight: 700 }}>Pago en boletos</td>
                                    <td>{totalComprado(stateResponsabilidad.pagoBoletos)}</td>
                                    <td>{Number(stateResponsabilidad.pagoBoletos.sacoins)}</td>
                                    <td>{Number(stateResponsabilidad.pagoBoletos.puntos)}</td>
                                </tr>
                                <tr>
                                    <td style={{ fontWeight: 700 }}>Pagos extras en servicios</td>
                                    <td>{totalComprado(stateResponsabilidad.pagosSA)}</td>
                                    <td>{Number(stateResponsabilidad.pagosSA.sacoins)}</td>
                                    <td>{Number(stateResponsabilidad.pagosSA.puntos)}</td>
                                </tr>
                                <tr>
                                    <td style={{ fontWeight: 700 }}>Viajes empresa</td>
                                    <td>{totalComprado(stateResponsabilidad.inscripcionesViajes)}</td>
                                    <td>{Number(stateResponsabilidad.inscripcionesViajes.sacoins)}</td>
                                    <td>{Number(stateResponsabilidad.inscripcionesViajes.puntos)}</td>
                                </tr>
                            </>
                        )}
                    </tbody>
                </table>
            </div>
            <div className='d-flex flex-column gap-5 m-auto p-5' style={{ backgroundColor: 'snow', borderRadius: 20 }}>
                <h3 className='text-center'>OTROS USOS SACOIN</h3>
                <div className='d-flex gap-5'>
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col">Concepto</th>
                                <th scope="col">Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {stateResponsabilidad && (
                                <>
                                <tr>
                                    <td style={{ fontWeight: 700 }}>Retiros</td>
                                    <td>{stateResponsabilidad.retiros.sacoins}</td>
                                </tr>
                                <tr>
                                    <td style={{ fontWeight: 700 }}>Retiros Extemporáneos</td>
                                    <td>{stateResponsabilidad.retirosE.sacoins}</td>
                                </tr>
                                <tr>
                                    <td style={{ fontWeight: 700 }}>Adelantos de saldo</td>
                                    <td>{stateResponsabilidad.adelantosSaldo.sacoins}</td>
                                </tr>
                                <tr>
                                    <td style={{ fontWeight: 700 }}>Transferencias</td>
                                    <td>{stateResponsabilidad.transferencias.sacoins}</td>
                                </tr>
                                </>
                            )}
                        </tbody>
                    </table>
                    <TarjetaResponsabilidad title='Total retirado' price={Number(stateResponsabilidad?.total_otrosUsosSC.sacoins)} />
                </div>
            </div>
            <div className='d-flex flex-column p-3 w-75 m-auto mt-5 text-left'>
                <p className='alert alert-success' style={{ borderRadius: 20 }}>
                    <p style={{ fontWeight: 600 }}>Responsabilidad actual:</p> indica la responsabilidad de la empresa
                    sobre tu aportación,<br /> si esta es cero, felicidades ha superado su punto de
                    equilibrio y está siempre en beneficio de nuestro sistema.<br />
                    Esta tabla es de carácter estadístico y no afecta en nada el total de su aportación,
                    recuerde que su aportación está disponible para ser retirada del sistema
                    en el momento que usted desee, según las condiciones de cancelación y de retiro.
                </p>
            </div>
        </div>
    );
}
