/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-shadow
enum EProceso {
  ADELANTO_DE_SALDO = 'Adelanto de saldo',
  ADQUISICION_DE_MANTENIMIENTO = 'Adquisición de mantenimiento.',
  ADQUISICION_DE_PAQUETE = 'Adquisición de paquete',

  BONO_NIVEL = 'Bono nivel',
  BONO_PATROCINADOR = 'Bono patrocinador',
  BONO_RETROACTIVO = 'Bono retroactivo',
  BONO_ESTRELLA = 'Bono estrella',
  BONO_POR_COMPRAS_APPLE_GOLDEN = 'Bono por compras Apple Golden',
  BONO_RENDIMIENTO_RA = 'Bono rendimiento RA',
  BONO_DE_BENEFICIOS = 'Bono de beneficios',
  BONO_DE_NIVEL_POR_PAQUETES_DE_INVERSION = 'Bono de nivel por paquetes de inversion',
  BONO_EXTRAORDINARIO = 'Bono extraordinario',
  BONO_APPLE_GOLDEN = 'Bono Apple Golden',
  BONO_DE_RENDIMIENTO_LA = 'Bono de rendimiento LA',

  CANCELACION_PAQUETE = 'Cancelacion paquete',
  CANJE_TARJETA_PREPAGA_DESDE_APPLE_GOLDEN = 'Canje tarjeta prepagada desde apple golden',
  CALIFICACION = 'Calificacion',

  COMPRA_A_TREIDBLUE = 'Compra a Treidblue',
  COMPRA_A_APPLE_GOLDEN = 'Compra a Apple Golden',
  COMPRA_DE_BOLETOS = 'Compra de boletos',

  CALIFICACION_CR1 = 'Calificacion CR1',
  CALIFICACION_CR2 = 'Calificacion CR2',
  CALIFICACION_CR5 = 'Calificacion CR5',
  CUOTA_DE_CANCELACION_DE_PAQUETE = 'Cuota de cancelacion de paquete',
  CONVERSION_SALDO_FONDEO_A_SALDO_DISPONIBLE = 'Conversion saldo fondeo a saldo disponible',
  COTIZACION_AGENCIA_DE_VIAJE = 'Cotizacion agencia de viajes',

  FINANCIAMIENTO_APPLE_GOLDEN = 'Financiamiento Apple Golden',
  FINANCIAMIENTO_DE_SERVICIO_EN_APPLE_GOLDEN = 'Financiamiento de servicio en Apple Golden',
  FONDEO = 'Fondeo',

  INSCRIPCION = 'Inscripcion',

  PAGO_A_SISTEMA_APPLE = 'Pago a Sistema Apple',
  PAGO_DE_SERVICIO_A_APPLE_GOLDEN = 'Pago de servicio a Apple Golden',

  RENDIMIENTO_MENSUAL = 'Rendimiento mensual',

  RETIRO_EXTEMPORANEO_CONFIRMADO = 'Retiro extemporaneo confirmado.',
  RETIRO_CONFIRMADO = 'Retiro confirmado.',
  RETENCION_SOBRE_BENEFICIO = 'Retención sobre beneficio',
  RETENCION_RETIRO_EXTEMPORANEO = 'Retencion retiro extemporaneo',

  RENOVACION = 'Renovacion',
  RENOVACION_DE_INSCRIPCION = 'Renovacion de inscripcion',
  RENDIMIENTO = 'Rendimiento',

  TRANSFERENCIA_CONFIRMADA = 'Transferencia confirmada.',
  TRANSFERENCIA_DE_SALDO_DE_TREIDBLUE = 'Transferencia de saldo de Treidblue',
}

export const procesos: EProceso[] = [
  EProceso.RETENCION_SOBRE_BENEFICIO,
  EProceso.PAGO_A_SISTEMA_APPLE,
  EProceso.FINANCIAMIENTO_APPLE_GOLDEN,
  EProceso.RENDIMIENTO,
  EProceso.BONO_NIVEL,
  EProceso.BONO_PATROCINADOR,
  EProceso.BONO_RETROACTIVO,
  EProceso.BONO_ESTRELLA,
  EProceso.BONO_EXTRAORDINARIO,
  EProceso.INSCRIPCION,
  EProceso.RENOVACION,
  EProceso.CALIFICACION,
  EProceso.FONDEO,
  EProceso.BONO_APPLE_GOLDEN,
  EProceso.BONO_DE_RENDIMIENTO_LA,
];
export default EProceso;

/*
  TRANSFERENCIA_RECIBIDA_DEL_USUARIO = "TRANSFERENCIA_RECIBIDA_DEL_USUARIO_", // _USERNAM
*/
