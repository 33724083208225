import React, { useEffect, useState } from 'react';
import useTipoCambio from 'hooks/useTipoCambio.hook';
import { parsePrice } from 'utils/parser';
import './templates/template.css'
// import api from 'utils/api';
interface TipoCambio {
  success: boolean;
  SERVER_INFO: Record<string, unknown>;
  changes: {
    BTC: string;
    'USA-Venta': number;
    'USA-Compra': number;
    'COP-Venta': number;
    'COP-Compra': number;
    'MXN-Venta': number;
    'MXN-Compra': number;
    'ARG-Venta': number;
    'ARG-Compra': number;
    'CLP-Venta': number;
    'CLP-Compra': number;
    'PEN-Venta': number;
    'PEN-Compra': number;
    'BOB-Venta': number;
    'BOB-Compra': number;
    'EUR-Venta': number;
    'EUR-Compra': number;
    'CRC-Venta': number;
    'CRC-Compra': number;
    'DOP-Venta': number;
    'DOP-Compra': number;
  };
}

export default function CalculaldoraFondeo() {
  const [moneda, setMoneda] = useState<string>('usa');
  const [totalPagar, setTotalPagar] = useState<number>(0);
  const [valorFondear, setValorFondear] = useState<number>(0);
  const [cambio, setCambio] = useState<TipoCambio | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const tipoCambio = useTipoCambio();

  const fetchTipoCambio = async () => {
    try {
      const response = await fetch('https://api.treidblue.com/tipo-cambio');
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setCambio(data);
    } catch (error) {
      console.error(`There was a problem with the fetch operation: ${error}`);
    }
  }


  useEffect(() => {
    fetchTipoCambio();
  }, []);

  useEffect(() => {
    switch (moneda) {
      case 'usd':
        setTotalPagar(tipoCambio.usd.compra * valorFondear);
        break;
      case 'colombia':
        setTotalPagar(tipoCambio.colombia.compra * valorFondear);
        break;
      case 'mexico':
        setTotalPagar(tipoCambio.mexico.compra * valorFondear);
        break;
      default:
        break;
    }
  }, [tipoCambio, moneda, valorFondear]);

  const styleInput = {
    width: '100%'
  }

  return (
    <div style={{
      backgroundColor: '#FFF3CD',
      color: '#837B07',
      borderRadius: '.5em',
      height: '100%',
      left: '2719px',
      padding: '20px',
      alignItems: 'right',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'right'
    }}>
      <h5 style={{ color: 'black' }}>Calculadora de Fondeo</h5>
      <form>
        <div className="mb-3" style={{ maxWidth: '100%' }}>
          <label className="form-label" style={{ color: 'black' }}>Valor a fondear (Sacoins):</label>
          <input
            type="number"
            className="form-control"
            placeholder="valor a fondear (sacoins)"
            onChange={(e) => setValorFondear(Number(e.target.value))}
          />
        </div>
        <div className="mb-3">
          <label className="form-label" style={{ color: 'black' }}>Moneda del pais:</label>
          <select style={styleInput} className="form-select" onChange={(e) => setMoneda(e.target.value)}>
            <option value="usd">🇺🇲 Estados Unidos</option>
            <option value="colombia">🇨🇴 Colombia</option>
            <option value="mexico">🇲🇽 México</option>
            <option value="argentina">🇦🇷 Argentina</option>
            <option value="chile">🇨🇱 Chile</option>
            <option value="peru">🇵🇪 Perú</option>
            <option value="bolivia">🇧🇴 Bolivia</option>
            <option value="euro">🇪🇺 Euro</option>
          </select>
        </div>
        <div className="">
          <label className="form-label" style={{ color: 'black' }}>Total a pagar:</label>
          <input type="text" style={styleInput} className="form-control" placeholder="total a pagar" value={parsePrice(totalPagar)} disabled />
        </div>
      </form>
      <p className="text-danger" style={{ fontSize: '10px' }}>
        * Este cálculo puede variar al momento de fondearse por la variación del tipo de cambio.
      </p>
      <div className='tipoCambio'>
        <div>
          <p className='text-center' style={{
            color: 'black', textDecoration: 'underline',
            cursor: 'pointer', fontSize: '10px'
          }} onClick={() => setIsOpen(!isOpen)}>
            {isOpen ? 'Ocultar tipos de cambio' : 'Ver tipos de cambio'}
          </p>
          <div className='gridTipoCambio'>
            {isOpen && cambio && Object.entries(cambio.changes).map(([key, value]) => (
              <p style={{ fontSize: '10px', marginRight: '10px' }} key={key}>{`${key}: ${value}`}</p>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
