import { useEffect, useState } from 'react';
import AppleSystemConsume from 'services/AppleSystemService';
import { useLocation } from 'react-router-dom';
import Logo from '../../assets/images/logo_AS_fondo_negro.png';

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

export default function AvisoRetiroAceptado() {

    const query = useQuery();
    const [renderOption, setRenderOption] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [onFetch, setOnFetch] = useState<boolean>(false);

    useEffect(() => {
        if (onFetch) return;
        setOnFetch(true);
        const params: Record<string, string> = {};
        query.forEach((value, key) => {
            params[key] = value;
        });

        const { hash, solicitud } = params;
        if (!hash || !solicitud) {
            return;
        }

        const postVerify = async () => {
            setIsLoading(true);
            try {
                const res = await AppleSystemConsume('withdrawal').post('confirm', { hash, solicitud }, {});
                setRenderOption(res.success);
            } catch (err: any) {
                setRenderOption(false);
                console.error(err.message);
            } finally {
                setIsLoading(false);
            }
        };

        postVerify();
    }, [query, onFetch]);


    const renderContent = () => {
        if (isLoading) {
            return <h1>Cargando...</h1>;
        }

        if (renderOption) {
            return (
                <div className="card bg-dark text-white text-center" style={{ width: '50%', height: '30%' }}>
                    <img className="card-img w-50 h-50 m-auto" src={Logo} alt="" />
                    <div className="card-img-overlay">
                        <h5 className="card-title">Retiro Exitoso!</h5>
                        <p className="card-text">
                            Su solicitud ha sido aceptada correctamente. La misma puede tardar unos minutos en verse reflejada en su cuenta.
                            Para cualquier aclaración o soporte dirigirse a la sección de soporte de la plataforma donde puedes generar tu ticket.
                        </p>
                    </div>
                </div>
            );
        }

        return (
            <div className="card bg-dark text-white text-center" style={{ width: '50%', height: '30%' }}>
                <img className="card-img w-50 h-50 m-auto" src={Logo} alt="" />
                <div className="card-img-overlay">
                    <h5 className="card-title">No pudimos procesar tu retiro, vuelve a intentarlo.</h5>
                </div>
            </div>
        );
    };

    return (
        <div style={{
            background: 'linear-gradient(to right, #E9E7E6, #fff)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100vw',
            height: '100vh'
        }}>
            {renderContent()}
        </div>
    );
}
