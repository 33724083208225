import { Outlet, useLocation } from 'react-router-dom';
import { useEffect, useState, Suspense } from 'react';
import AuthContext from 'contexts/auth.context';

export default function OldPortal() {
  const { search } = useLocation();
  const [onLoad, setOnLoad] = useState<boolean>(true);
  const [jwt, setJwt] = useState<string>('');

  useEffect(() => {
    const jwt2 = new URLSearchParams(search).get('jwt');
    localStorage.setItem('auth', JSON.stringify({ jwt: jwt2 }));
    setJwt(jwt2 ?? '');
    setOnLoad(false);
  }, [search]);

  return onLoad ? (
    <div>Cargando...</div>
  ) : (
    <AuthContext.Provider value={{jwt, setJwt: (j: string) => setJwt(j)}}>
    <Suspense>
      <Outlet />
    </Suspense>
    </AuthContext.Provider>
  );
}
