import Calendar from 'assets/icons/Calendar';
import Link from 'assets/icons/Link';
import Location from 'assets/icons/Location';
import Phone from 'assets/icons/Phone';
import { dateParse } from 'utils/parser';
import Schedule from 'assets/icons/Schedule';
import Button from './Button';

type Props = {
  nombre: string;
  direccion: string;
  fecha: Date;
  link: string;
  lugar: string;
  telefono: string;
  hora?: string;
  onPress?: Function
  onPut?: Function
}

export default function Target({ nombre, direccion, fecha, hora, link, lugar, telefono, onPress = () => {}, onPut = () => {} }: Props) {


  return ( 
    <div style={{
      display: 'flex', flexDirection: 'column', borderRadius: 20, backgroundColor: '#FFF',
      boxShadow: '2px 8px 20px var(--color-as-ho)', padding: 10, width: '400px', overflow: 'auto', border: 'none'
    }}>
      <div 
      style={{
        display: 'flex', alignItems: 'center',justifyContent: 'space-between', 
        borderBottom: '1px solid var(--color-as-ho)', marginBottom: 10 
        }}>
        <p style={{ fontSize: 14, fontWeight: '700' }}>{nombre}</p>
        <div style={{display: 'flex', alignItems: 'center', marginBottom: 10}}>
          <Button title='Eliminar' onPress={() => onPress()} />
          <Button title='Editar' onPress={() => onPut()} />
        </div>
      </div>
      <div style={{ display: 'flex', gap: 10 }}>
        <Calendar fill='var(--color-as-ho)'/><p style={{ fontSize: 14 }}>{dateParse(fecha)}</p>
        <Schedule fill='var(--color-as-ho)'/><p style={{ fontSize: 14 }}>{hora}</p>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', gap: 10 }}>
          <Location fill='var(--color-as-ho)'/><p style={{ fontSize: 14 }}>{direccion}</p>
          <Phone fill='var(--color-as-ho)'/><p style={{ fontSize: 14 }}>{telefono}</p>
        </div>
        <div style={{ display: 'flex',flexDirection: 'column' }}>
          <div style={{display: 'flex', gap: 10}}>
          <p style={{ fontSize: 14, fontWeight: '600', color: 'var(--color-as-ho)' }}>Lugar:</p>
          <p style={{ fontSize: 14 }}>{lugar}</p>
          </div>
          <div style={{display: 'flex', gap: 10}}>
            <Link fill='var(--color-as-ho)'/><a style={{fontSize: 14}} target='_blank' href={link} rel='noopener noreferrer' >{link}</a>
          </div>
        </div>
      </div>
    </div>
  )
}
