/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import INotificationAdmin from 'interfaces/INotificationAdmin';

const initialState: INotificationAdmin = {
  solicitudes: {
    extemporaneos: 0,
    trasnferencias: 0,
    retiros: 0,
    paquetes: 0,
    fondeo: 0,
  },
  tarjetasPrepaga: { solicitudes: 0 },
  usuarios: { validacion: 0 },
  paquetes: {
    cancelados: 0,
  },
  soporte: {
    sinResponder: 0,
  },
};

const notificationAdmin = createSlice({
  name: 'NotificationAdmin',
  initialState,
  reducers: {
    init: (state, action: PayloadAction<INotificationAdmin>) => {
      state = action.payload;
      return state;
    },
  },
});

export const { init } = notificationAdmin.actions;
export default notificationAdmin.reducer;
