import Button from 'components/Button';
import Modal from 'components/Modal';
import Target from 'components/Target';
import Activity from 'components/forms/Activity';
import { useCallback, useEffect, useState } from 'react';
import AppleSystemConsume from 'services/AppleSystemService';
import IActivity from '../../interfaces/IActivity'


type SendData = {
  error: boolean;
  code: number;
  message: string;
  activities: Array<IActivity>
}

export default function Activities() {

  const [activities, setActivities] = useState<IActivity[] | null>(null);
  const [showAll, setShowAll] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [showPost, setShowPost] = useState<boolean>(false);
  const [editActivity, setEditActivity] = useState<IActivity | null>(null);

  const getData = useCallback(() => {
    AppleSystemConsume('activities')
      .get('')
      .then((res: SendData) => {
        if (res.error) throw new Error(res.message)
        const sortedActivities = res.activities.map(activity => {
          const fecha = new Date(activity.fecha)
          const hora = `${fecha.getHours()}:${fecha.getMinutes()}`;
          return { ...activity, hora };
        }).sort((a, b) => new Date(b.fecha).getTime() - new Date(a.fecha).getTime());
        setActivities(sortedActivities)
      })
  }, [])

  const now = new Date();
  const recentActivities = activities?.filter(activity => new Date(activity.fecha) >= now) || [];
  const remainingActivities = activities?.filter(activity => new Date(activity.fecha) < now) || [];

  const deleteData = (id: string) => {
    AppleSystemConsume('activities')
      .delete(id)
      .then((res: SendData) => {
        if (res.error) throw new Error(res.message);
        setActivities(activities && activities.filter(activity => activity.id !== parseInt(id, 10)));
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const edit = (activity: IActivity) => {
    setEditActivity(activity)
    setShow(true)
  }
  

  const setActivity = () => {
    if(editActivity != null) {
      AppleSystemConsume('activities')
      .get('')
      .then((res: SendData) => {
        if (res.error) throw new Error(res.message)
        const sortedActivities = res.activities.map(activity => {
          const fecha = new Date(activity.fecha)
          const hora = `${fecha.getHours()}:${fecha.getMinutes()}`;
          return { ...activity, hora };
        }).sort((a, b) => new Date(b.fecha).getTime() - new Date(a.fecha).getTime());
        setActivities(sortedActivities)
      })
    }
  }

  useEffect(() => {
    getData();
  }, [getData])

  return (
    <>
      <div style={{ padding: 25, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div className='text-center' style={{ textDecoration: 'underline' }}>
          <p style={{ fontSize: '20px', marginBottom: 20 }}>Cronograma y Actividades</p>
          <div className='mb-3'>
            <Button title='Agregar Actividad' onPress={() => {setShowPost(true)}}/>
          </div>
        </div>
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '20px' }}>
          {recentActivities.map((activity, index) => (
            <Target key={index} {...activity} onPress={() => deleteData(activity.id.toString())} onPut={() => edit(activity)} />
          ))}
          {showAll && remainingActivities.map((activity, index) => (
              <Target key={index} {...activity} onPress={() => deleteData(activity.id.toString())} onPut={() => edit(activity)}/>
          ))}
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        {!showAll ?
          <Button title='Mis Actividades' onPress={() => setShowAll(true)} />
          :
          <Button title='Cerrar' onPress={() => setShowAll(false)} />}
      </div>
      <Modal title='Editar Actividad' onClose={() => { setShow(false) }} show={show}>
        {editActivity ? <Activity 
        activity={editActivity} 
        reloadPut={() => { setShow(false) }} 
        newGet={() => {setActivity()}} /> : <></>}
      </Modal>
      <Modal title='Agregar Actividad' onClose={() => {setShowPost(false)}} show={showPost}>
        <Activity reloadPost={() => {setShowPost(false)}} newGet={() => {setActivity()}} />
      </Modal>
    </>
  )
}
