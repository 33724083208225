/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import IAuth from 'interfaces/IAuth';
import IUsuario from 'interfaces/IUsuario';

const authStorageString: string | null = localStorage.getItem('auth');
const authStorage: IAuth | null = authStorageString ? JSON.parse(authStorageString) : null;
const initialState: IAuth = {
  jwt: authStorage?.jwt ?? '',
  userType: '',
  user: null,
  posicion: '',
};

const auth = createSlice({
  name: 'Auth',
  initialState,
  reducers: {
    init: (state, action: PayloadAction<IAuth>) => {
      state = action.payload;
      localStorage.setItem('auth', JSON.stringify({ jwt: state.jwt, userType: state.userType }));
      return state;
    },
    setUser: (state, action: PayloadAction<{ user: IUsuario; posicion: string }>) => {
      state.user = action.payload.user;
      state.userType = action.payload.user.tipoCuenta ?? '';
      state.posicion = action.payload.posicion;
      return state;
    },
    logout: (state) => {
      state = {
        jwt: '',
        userType: '',
        user: null,
        posicion: '',
      };
      localStorage.removeItem('auth');
      return state;
    },
  },
});

export const { init, setUser, logout } = auth.actions;
export default auth.reducer;
