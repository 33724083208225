import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import EColor from 'enums/EColor';
import { Link } from 'react-router-dom';
import Platform from 'types/Platform';
import './Button.css';

export default function Button({
  title = 'aceptar',
  color = EColor.DEFAULT,
  icon = null,
  onPress,
  onLoad = false,
  secondary = false,
  block = false,
  to,
  className,
  platform
}: {
  title?: string;
  to?: string;
  color?: EColor;
  icon?: IconDefinition | null;
  onPress: Function;
  onLoad?: boolean;
  secondary?: boolean;
  block?: boolean;
  className?: string;
  platform?: Platform;
}) {
  const buttonClassName = className ? `m-1 btn ${className}` : `m-1 btn ${color} text-capitalize`;

  const body = icon ? (
    <span
      title={title}
      // eslint-disable-next-line max-len
      className={secondary ? `${block ? 'w-100' : ''} ${buttonClassName} ${color}-out platform-${platform ?? 'AS'}` : `${block ? 'w-100' : ''} ${buttonClassName} platform-${platform ?? 'AS'}`}
      onClick={() => onPress()}
    >
      <span
        style={{
          padding: '0.2em',
          borderRadius: '0.3em',
          backgroundColor: '#ffffff4d',
          width: block ? '100%' : 'auto',
        }}
      >
        <FontAwesomeIcon icon={icon} />
      </span>{' '}
      {title}
    </span>
  ) : (
    <span
      title={title}
      // eslint-disable-next-line max-len
      className={secondary ? `${block ? 'w-100' : ''} ${buttonClassName} ${color}-out platform-${platform ?? 'AS'}` : `${block ? 'w-100' : ''} ${buttonClassName} platform-${platform ?? 'AS'}`}
      onClick={() => onPress()}
    >
      {title}
    </span>
  );

  return onLoad ? (
    // eslint-disable-next-line max-len
    <span className={`${block ? 'w-100' : ''} ${buttonClassName} platform-${platform ?? 'AS'}`}>
      <FontAwesomeIcon icon={faSpinner} className="spinner animaton-rotate-ing me-2" />
      Cargando...
    </span>
  ) : (
    <>{to ? <Link to={to}>{body}</Link> : body}</>
  );
}
