/* eslint-disable no-unused-vars */
export type Obj = {
  [key: string]: string;
};

interface Get {
  (resource: string): Promise<any>;
}

interface Post {
  (id: string, body: Object, params: Obj): Promise<any>
}

interface Put {
  (id: string, body: Object, params: Obj): Promise<any>
}

interface Delete {
  (id: string, params?: Obj): Promise<any>
}
interface ConsumeResponse {
  get: Get
  post: Post
  put: Put
  delete: Delete
}

export function ObjectToURI(obj: Obj = {}): string {
  return Object.keys(obj).reduce((acc, key) => acc + encodeURI(`${key}=${obj[key]}&`), '');
}

export default interface Consume {
  (resource: string, jwt?: string): ConsumeResponse
}

