import TiposDeCambio from 'components/TiposDeCambio';
import SectionSolicitarRetiro from 'components/paquetes/SectionSolicitarRetiro';
import UserDataFondeoPackage from 'components/paquetes/UserDataFondeoPackage';
import IUsuario from 'interfaces/IUsuario';
import { useEffect, useState } from 'react';
import AppleSystemConsume from 'services/AppleSystemService';

type ResUser = {
    code: number;
    error: boolean;
    message: string;
    usuario: IUsuario
}

export default function SolicitarRetiro() {

    const [userInfo, setUserInfo] = useState<IUsuario | null>(null);
    const [saldo, setSaldo] = useState<number>();
    const [optRetiro, setOptRetiro] = useState<string>('');

    // datos del usuario
    const fetchUserInfo = () => {
        AppleSystemConsume('usuario')
            .get('my-usuario')
            .then((res: ResUser) => {
                setUserInfo(res.usuario)
                setOptRetiro(res.usuario.optRetiro ?? '');
            })
    }
    // saldo disponible
    const getSaldo = () => {
        AppleSystemConsume('usuario')
            .get('available-balance')
            .then((res) => {
                if (res.error) throw new Error;
                setSaldo(res.avaliableBalance)
            })
    }

    useEffect(() => {
        fetchUserInfo();
        getSaldo();
    }, []);
    
    return (
        <div className='container d-flex flex-column'>
            <p className='text-center' style={{ fontSize: 25, fontWeight: '600' }}>Solicitar Retiro</p>
            <div className="d-flex flex-row justify-content-center">
                <div className='d-flex' style={{
                    boxShadow: '2px 8px 20px rgba(0, 0, 0, 0.2)',
                    backgroundColor: '#ffff',
                    borderRadius: 15,
                    padding: 10,
                }}>
                    <article className='d-flex flex-column'>
                        {userInfo && saldo !== undefined && (
                            <UserDataFondeoPackage
                                usuario={userInfo}
                                saldoFondeo={saldo}
                                titleSaldo='Saldo disponible (sujeto a retiros pendientes):'
                            />
                        )}
                        <SectionSolicitarRetiro saldoDisponible={saldo} optUser={optRetiro}/>
                    </article>
                    <TiposDeCambio />
                </div>
            </div>
        </div>
    )
}