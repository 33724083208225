import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../Registration.css';
import Platform from 'types/Platform';
import AppleSystemConsume from 'services/AppleSystemService';
import BackButtonRegister from 'components/BackButtonRegister';
import Logo from './Logo';


interface Email {
  registrationId: string
  email: string
}
interface ServerResponse {
  routes: { next: string }
  error: boolean;
  code: number;
  message: string;
}

export const CorreoElectronico = () => {

  const params = new URLSearchParams(window.location.search);
  const platform = params.get('platform') as Platform;

  const newLocal = 'white';

  const navigate = useNavigate(); // Obtiene la función de navegación
  const [isLoading, setIsLoading] = useState<boolean>(false); // Agrega un estado isLoading
  const [errorMessage, setErrorMessage] = useState<string>(''); // Muestra un mensaje de error
  const [formData, setFormData] = useState<Email>({
    registrationId: localStorage.getItem('registrationId') || '',
    email: ''
  })

  useEffect(() => {
    const registrationIdParams = params.get('registrationId');
    if (registrationIdParams) {
      localStorage.setItem('registrationId', registrationIdParams)
      setFormData({ ...formData, registrationId: registrationIdParams })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isEmailValid = (email: string) => {
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement | HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    })
  };

  const saveEmail = async () => {
    if (!isEmailValid(formData.email)) {
      setErrorMessage('El correo electrónico ingresado no es válido.');
      return;
    }

    setIsLoading(true);

    AppleSystemConsume('registration')
      .post('email_validation', formData, {})
      .then((res: ServerResponse) => {
        if (res.error) throw new Error(res.message);
        navigate(res.routes.next);
      })
      .catch((err: any) => {
        setErrorMessage(err.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <div className='body-registration'>
      <div className='back-button'>
          <BackButtonRegister />
        </div>
        <header className='container-logo'>
          <div className='logo'>
            <Logo id="logo_desktop" platform={platform} logoColor={newLocal} />
          </div>
        </header>
        <div className='container-register'>
          <div className='texto'>
            <h4 style={{ fontSize: '30px' }}>Cuenta de Correo Electronico</h4>
            <p style={{ fontSize: '17px' }}>Paso 2 de 5</p>
            <div className='centered-content-correo'>
              <div className='form-group'>
                <input type="email"
                  placeholder='Correo Electronico'
                  name='email'
                  value={formData.email}
                  onInput={handleEmailChange}
                />
                <p>Deberas tener acceso a tu email ya que deberas ingresar
                  un codigo que enviaremos.
                </p>
              </div>
              <div className='button-registerData'>
                <button onClick={saveEmail} disabled={isLoading}>
                  {isLoading ? 'Cargando...' : 'Validar'}
                </button>
              </div>
              <p className='text-center text-danger'>{errorMessage}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
