import Toast from 'components/alerts/Toast';
import React, { useState, createContext, useContext, Suspense } from 'react';
import { createPortal } from 'react-dom';

interface IToast {
  message: string;
  show: boolean;
  id: number;
}

export type NotificationsContextType = {
  notifications: Array<IToast>;
  // eslint-disable-next-line no-unused-vars
  setNotifications: (notifications: Array<IToast>) => void;
};

export const NotificationsContext = createContext<NotificationsContextType>({
  notifications: [],
  // eslint-disable-next-line no-unused-vars
  setNotifications: (notifications) => {},
});
export const useNotifications = () => useContext(NotificationsContext);

function NotificationLayout({ children }: { children: React.ReactNode }) {
  const [notifications, setNotifications] = useState<Array<IToast>>([]);

  const el = document.querySelector('#modal-root') ?? document.createElement('div');
  return (
    <NotificationsContext.Provider value={{ notifications, setNotifications }}>
      {createPortal(
        <div className="fixed-top" style={{ left: 'auto' }}>
          <div className="d-flex justify-content-end pt-3 pe-3 flex-column">
            {notifications.map((toast) => (
              <Toast
                key={toast.message}
                show={toast.show}
                state={false}
                onClose={() => {
                  setNotifications(notifications.filter((e) => e.id !== toast.id));
                }}
                message={toast.message}
              />
            ))}
          </div>
        </div>,
        el
      )}
      <Suspense>{children}</Suspense>
    </NotificationsContext.Provider>
  );
}

export { NotificationLayout };
