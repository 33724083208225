import React from 'react';

export default function Warnings() {
  const { REACT_APP_API = '' } = process.env;
  return (
    <div className="fixed-top" style={{ justifyContent: 'flex-end', display: 'flex' }}>
      {!REACT_APP_API && process.env.NODE_ENV === 'development' ? (
        <div className="badge bg-danger">no se encontró REACT_APP_API, agregar en .env: REACT_APP_API = "https://www.sistemaapple.com.mx/api/"</div>
      ) : (
        <></>
      )}
      {REACT_APP_API.includes('applesystem.local') ? <div className="badge bg-warning right-0 text-dark">DEV MODE</div> : <></>}
    </div>
  );
}
