import TarjetaInicio from 'components/templates/TarjetaInicio';
import { useEffect, useState } from 'react';
import AppleSystemConsume from 'services/AppleSystemService';
import '../templates/template.css'
import { parsePrice } from 'utils/parser';

interface AvailableBalance {
  error: boolean;
  code: number;
  message: string;
  avaliableBalance: number;
  success: boolean;
}

export default function TarjetaSaldo() {

  const [saldoDisponible, setSaldoDisponible] = useState<number>();
  const [cargando, setCargando] = useState<boolean>(false);

  const fetchSaldo = async () => {
    setCargando(true)
    AppleSystemConsume('usuario')
      .get('available-balance')
      .then((res: AvailableBalance) => {
        if (res.error) throw new Error(res.message);
        setSaldoDisponible(res.avaliableBalance);
      })
      .catch(() => { })
      .finally(() => {
        setCargando(false);
      })
  }

  useEffect(() => {
    fetchSaldo();
  }, [])

  const dataFetch = {
    saldo: {
      numero: saldoDisponible,
      percentaje: '',
      text: 'Saldo Disponible',
      txtPercentaje: '',
      fondo: '#5FC507',
      colorLetter: '#000000',
      colorPercentaje: '#FFFFFF',
      fill: ''
    }
  }

  return (
    <div className='tarjeta-margin' style={{ height: '199px' }}>
      {Object.values(dataFetch).map((data, index) => (
        <TarjetaInicio
          key={index}
          onLoad={cargando}
          text={data.text}
          numero={parsePrice(Number(data.numero))}
          percentaje={data.percentaje}
          txtPercentaje={data.txtPercentaje}
          fondo={data.fondo}
          colorLetter={data.colorLetter}
          colorPercentaje={data.colorPercentaje}
          fill={data.fill}
        />
      ))}
    </div>
  )
}
