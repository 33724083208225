import React from 'react';
import './Logo.css'
import Platform from 'types/Platform';
import LogoColor from 'types/LogoColor';
import logoASWhite from '../../../../assets/images/logo_AS_fondo_blanco.png';
import logoASBlack from '../../../../assets/images/logo_AS_fondo_negro.png';
import logoAGWhite from '../../../../assets/images/logo_AG_fondo_blanco.png';
import logoAGBlack from '../../../../assets/images/logo_AG_fondo_negro.png';
import logoTB from '../../../../assets/images/logo_TB.png';

interface LogoProps {
  id: string;
  platform: Platform;
  logoColor: LogoColor;
}

const Logo: React.FC<LogoProps> = ({ id, platform, logoColor }) => {

  let platformString: String = ''

  const getLogoByPlatform = () => {
    switch (platform) {
      case 'AS':
        platformString = 'Apple System MLM';
        return logoColor === 'white' ? logoASWhite : logoASBlack;
      case 'AG':
        platformString = 'Apple Golden';
        return logoColor === 'white' ? logoAGWhite : logoAGBlack;
      case 'TB':
        platformString = 'Treidblue';
        return logoTB;
      default:
        return logoASWhite;
    }
  };

  const logoSrc = getLogoByPlatform();

  return (
    <div id={id}>
      <img src={logoSrc} alt={`Logo ${platformString}`} />
    </div>
  );
};

export default Logo;