const formToObj = (form: HTMLFormElement) => {
  const formData = new FormData(form);
  const formProps = Object.fromEntries(formData);
  const obj: { [index: string] : string | null | number | boolean | File } = {};
  Object.keys(formProps).forEach(k => {
    obj[k] = formProps[k] !== '' ? formProps[k] : null;
  })
  return obj;
}

export default formToObj;