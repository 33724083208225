import { useEffect, useState } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, ArcElement } from 'chart.js';
import { Line } from 'react-chartjs-2';
import Article from 'components/templates/Article';
import AppleSystemConsume from 'services/AppleSystemService';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, ArcElement);

export default function YourExpensesChart() { 
  const [data, setData] = useState<any | null>(null);

    const fetchGraph = () => {
    AppleSystemConsume('usuario')
      .get('your-expenses')
      .then((res) => {
        if (res.error) throw new Error(res.message)

        const months = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];

        setData({
          labels: res.labels.map((e: string) => `${months[Number(e.slice(4,6))-1]} ${e.slice(0,4)}`),
          datasets: [
            {
              label: 'Compras',
              data: res.labels.map((e: string) => res.compras[e] ?? 0),
              borderColor: '#7f7f7f88',
              pointRadius: 0,
              spanGaps: false,
              tension: 0.4,
              backgroundColor: '#7f7f7f88',
            },
            {
              label: 'Servicios',
              data: res.labels.map((e: string) => res.servicios[e] ?? 0),
              borderColor: '#c3ed8d88',
              pointRadius: 0,
              tension: 0.4,
              backgroundColor: '#c3ed8d88',
            },
            {
              label: 'Pagos',
              data: res.labels.map((e: string) => res.pagos[e] ?? 0),
              borderColor: '#938ded88',
              pointRadius: 0,
              tension: 0.4,
              backgroundColor: '#938ded88',
            },
          ],
        });
      })
      .catch(() => {})
  }

  useEffect(() => {
    fetchGraph();
  }, []);

  if (!data) return(<></>);

  return <div>
    <Article>
    <Line options={{
    plugins: {
      tooltip: {
        enabled: true,
        mode: 'nearest',
        intersect: false,
      },
    },
    scales: {
      y: { grid: { display: false } },
      x: { grid: { display: false } }
    },
    responsive: true,
  }} data={data} />
  </Article>
  </div>
}