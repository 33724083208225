/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import ITipoCambio from 'interfaces/ITipoCambio';

const initialState: ITipoCambio = {
  colombia: {
    compra: 0,
    venta: 0,
  },
  mexico: {
    compra: 0,
    venta: 0,
  },
  argentina: {
    compra: 0,
    venta: 0,
  },
  chile: {
    compra: 0,
    venta: 0,
  },
  peru: {
    compra: 0,
    venta: 0,
  },
  bolivia: {
    compra: 0,
    venta: 0,
  },
  euro: {
    compra: 0,
    venta: 0,
  },
  usd: {
    compra: 0,
    venta: 0,
  },
};

const tipoCambio = createSlice({
  name: 'TipoCambio',
  initialState,
  reducers: {
    set: (state, action: PayloadAction<ITipoCambio>) => {
      state = action.payload;
      return state;
    },
  },
});

export const { set } = tipoCambio.actions;
export default tipoCambio.reducer;
