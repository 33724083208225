import Button from 'components/Button';
import Modal from 'components/Modal';
import React, { useEffect, useState } from 'react';
import AppleSystemConsume from 'services/AppleSystemService';
import UserDataFondeoPackage from 'components/paquetes/UserDataFondeoPackage';
import IUsuario from 'interfaces/IUsuario';
import TransferenciaPendiente from './TransferenciaPendiente';

type ResUser = {
  code: number;
  error: boolean;
  message: string;
  usuario: IUsuario
}

type resPost = {
  error: boolean;
  message: string;
}

export default function SolicitarTransferencia() {

  const [userInfo, setUserInfo] = useState<IUsuario | null>(null);
  const [nombreUsuarioReceptor, setNombreUsuarioReceptor] = useState<string>('');
  const [monto, setMonto] = useState<number>();
  const [transactionId, setTransactionId] = useState<string>();
  const [saldo, setSaldo] = useState<number>();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [userAvailble, setUserAvailable] = useState<boolean>(false);

  // ver el tema de graficar el error

  // datos del usuario
  const fetchUserInfo = () => {
    AppleSystemConsume('usuario')
      .get('my-usuario')
      .then((res: ResUser) => {
        setUserInfo(res.usuario)
      })
  }

  // transactionid
  const transaction = () => {
    AppleSystemConsume('transfer')
      .get('transactionid')
      .then((res) => {
        setTransactionId(res.transactionId)
      })
  }

  // saldo disponible
  const getSaldo = () => {
    AppleSystemConsume('usuario')
      .get('available-balance')
      .then((res) => {
        if (res.error) throw new Error;
        setSaldo(res.avaliableBalance)
      })
  }

  useEffect(() => {
    fetchUserInfo();
    getSaldo();
    transaction();
  }, []);

  // fn para localizar el usuario cambiar el post porque no es user/exists
  const userExist = (nombreUsuario: string) => {
    if (!nombreUsuario) return;
    setUserAvailable(false);
    AppleSystemConsume('user')
      .post('exists', { nombreUsuario, needExist: false }, {})
      .then((res) => {
        if (res.error) throw new Error(res.message);
        setUserAvailable(true);
      })
      .catch((err) => {
        setMessage(err.message)
        console.log(err)
      })
  }

  // enviar la transferencia
  const postTransfer = () => {
    if ((monto && monto < 300) || !userAvailble) {
      setMessage('Error en la solicitud. Usuario no registrado o monto menor a 300.')
      return
    }
    setMessage('')
    AppleSystemConsume('transfer')
      .post('new-request-transfer', { transactionId, monto, nombreUsuarioReceptor }, {})
      .then((res: resPost) => {
        if (res.error) throw new Error(res.message);
        setIsModalVisible(true);
      })
  }

  return (
    <div className='container d-flex flex-column'>
      <p className='text-center' style={{ fontSize: 25, fontWeight: '600' }}>Solicitar Transferencia</p>
      <div className="d-flex flex-row justify-content-center">
        <div className='d-flex flex-column' style={{
          boxShadow: '2px 8px 20px rgba(0, 0, 0, 0.2)',
          backgroundColor: '#ffff',
          borderRadius: 15,
          padding: 10,
        }}>
          <article className='d-flex'>
          {userInfo && saldo !== undefined && (
            <UserDataFondeoPackage
             usuario={userInfo}
              saldoFondeo={saldo}
              titleSaldo='Saldo disponible:'
            />
          )}
            <section className='p-4'>
              <div className='d-flex flex-column gap-2 mb-4'>
                <p style={{ fontWeight: 'bolder', fontSize: 13 }} className='text-center'>Solicitar Paquete</p>
                <label htmlFor="userToTransfer" style={{ fontSize: 13, fontWeight: 'bolder' }}>Nombre de usuario a transferir</label>
                <input
                  id="transfer"
                  name="transfer"
                  onChange={(e) => setNombreUsuarioReceptor(e.target.value)}
                  onBlur={() => userExist(nombreUsuarioReceptor)}
                  className="form-control h-25 mb-3 border border-dark"
                  placeholder='Nombre de Usuario...'
                  type="text" />
                <p className='text-danger'>{message}</p>
              </div>
              <div className='d-flex flex-column gap-2 mb-4'>
                <label htmlFor="valorPaquete" style={{ fontSize: 13, fontWeight: 'bolder' }}>Monto</label>
                <input
                  id="amount"
                  name="amount"
                  onChange={(e) => setMonto(Number(e.target.value))}
                  className="form-control h-25 mb-3 border border-dark"
                  placeholder='Monto...'
                  type="number" />
                {/* <p className='text-danger' style={{fontSize: 8, padding: 0}}>{message}</p> */}
              </div>
              <div className='d-flex flex-column gap-2'>
                <div style={{ textAlign: 'center', maxWidth: 300 }}>
                  <p style={{ backgroundColor: '#ffecb5' }}>La transferencia tiene un costo de 6% sobre el total de la transferencia.</p>
                </div>
              </div>
            </section>
          </article>
          <div className='text-center mt-5'>
            <Button title='Solicitar Transferencia' onPress={postTransfer} />
          </div>
        </div>
      </div>
      <Modal title={'Transferencia'} onClose={() => setIsModalVisible(false)} show={isModalVisible}>
        <TransferenciaPendiente />
      </Modal>
    </div>
  )
}