import { useNotifications } from 'layouts/Notification.layout';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AppleSystemConsume from 'services/AppleSystemService';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setUser } from 'store/reducers/auth.reducers';

export default function UseTipoCuenta() {
  const dispatch = useAppDispatch();
  const [onLoad, setOnLoad] = useState<boolean>(false);

  const [error, setError] = useState<boolean>(false);

  const { notifications, setNotifications } = useNotifications();

  const { userType } = useAppSelector((state: RootState) => state.auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (error) {
      return;
    }

    if (userType) return;

    setOnLoad(true);
    // dispatch(setUserType(''));
    AppleSystemConsume('auth')
      .get('tipo-cuenta')
      .then((res) => {
        if (res.error) {
          setError(true);
          navigate('/login');
          return;
        }
        dispatch(setUser({ user: res.user, posicion: res.posicion }));
      })
      .finally(() => {
        setOnLoad(false);
      });
  }, [dispatch, error, setError, notifications, setNotifications, setOnLoad, navigate, userType]);

  return { onLoad, userType, error };
}
