import React from 'react';
import './template.css';
import TextSkeleton from 'components/skeletons/TextSkeleton';

interface Prop {
  numero: string | number;
  cantop: string;
  fondo?: string
  colorNumber?: string;
  colorLetter?: string;
  onLoad?: boolean
}

const TarjetaGenerica = ({ cantop, numero, fondo, colorLetter, colorNumber, onLoad }: Prop) => {
  const showNumber = numero !== '' ? numero : '0';

  return (
    <div className='article-tarjetaGenerica d-flex flex-column' style={{ background: fondo }}>
      {onLoad ? <TextSkeleton /> 
      : 
      <>
        <div className='p-3' style={{ fontSize: '15px', fontWeight: 'normal', color: colorLetter }}>
          <p style={{fontSize: '18px'}}>{cantop}</p>
        </div>
        <div className='p-3' style={{ fontSize: '65px', color: colorNumber }}>
          {showNumber}
        </div>
      </>
      }
    </div>
  );
};

export default TarjetaGenerica
