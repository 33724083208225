import Button from 'components/Button';
import Table from 'components/Table';
import Th from 'components/tables/Th';
import { useEffect, useState } from 'react';
import generateExcel from 'utils/generateExcel';

type Responsability = {
  aportacionReal: number;
  beneficioUsado: number;
  nombreUsuario: string;
  otrosUsos: number;
  responsabilidadActual: number;
  responsabilidadSA: number;
  saldoActual: number;
  saldoFondeo: number;
  saldoPuntosAG: number;
  totalAportacion: number;
  totalComprado: number;
  totalGenerado: number;
  totalPagado: number;

}

export default function PanelObservacion() {
  const [responsabilities, setResponsabilities] = useState<Array<Responsability> | null>(null);
  const [textFilter, setTextFilter] = useState<string>('');
  const [rows, setRows] = useState<Array<Responsability>>([]);

  useEffect(() => {
    fetch('https://www.sistemaapple.com.mx/api/v2/tabla-responsabilidad')
      .then(res => res.json())
      .then(res => {
        setResponsabilities(res);
      })
  }, []);

  const sorter = (field: string, sort: number | string) => {
    if (responsabilities == null) return;

    if (field === 'NOMBRE') {
      const firstSort = sort === -1 ? 1 : -1;
      const secondSort = sort === -1 ? -1 : 1;
      const sorted = responsabilities.sort((a, b) => {
        return a.otrosUsos > b.otrosUsos ? firstSort : secondSort;
      });
      setResponsabilities([...sorted]);
    }

    if (field === 'OTROS') {
      const firstSort = sort === -1 ? 1 : -1;
      const secondSort = sort === -1 ? -1 : 1;
      const sorted = responsabilities.sort((a, b) => {
        return Number(a.otrosUsos) > Number(b.otrosUsos) ? firstSort : secondSort;
      });
      setResponsabilities([...sorted]);
    }

    if (field === 'RESPONSABILIDADA') {
      const firstSort = sort === -1 ? 1 : -1;
      const secondSort = sort === -1 ? -1 : 1;
      const sorted = responsabilities.sort((a, b) => {
        return Number(a.responsabilidadActual) > Number(b.responsabilidadActual) ? firstSort : secondSort;
      });
      setResponsabilities([...sorted]);
    }

    if (field === 'RESPONSABILIDADSA') {
      const firstSort = sort === -1 ? 1 : -1;
      const secondSort = sort === -1 ? -1 : 1;
      const sorted = responsabilities.sort((a, b) => {
        return Number(a.responsabilidadSA) > Number(b.responsabilidadSA) ? firstSort : secondSort;
      });
      setResponsabilities([...sorted]);
    }

    if (field === 'SALDOA') {
      const firstSort = sort === -1 ? 1 : -1;
      const secondSort = sort === -1 ? -1 : 1;
      const sorted = responsabilities.sort((a, b) => {
        return Number(a.saldoActual) > Number(b.saldoActual) ? firstSort : secondSort;
      });
      setResponsabilities([...sorted]);
    }

    if (field === 'SALDOF') {
      const firstSort = sort === -1 ? 1 : -1;
      const secondSort = sort === -1 ? -1 : 1;
      const sorted = responsabilities.sort((a, b) => {
        return Number(a.saldoFondeo) > Number(b.saldoFondeo) ? firstSort : secondSort;
      });
      setResponsabilities([...sorted]);
    }

    if (field === 'PUNTOSG') {
      const firstSort = sort === -1 ? 1 : -1;
      const secondSort = sort === -1 ? -1 : 1;
      const sorted = responsabilities.sort((a, b) => {
        return Number(a.saldoPuntosAG) > Number(b.saldoPuntosAG) ? firstSort : secondSort;
      });
      setResponsabilities([...sorted]);
    }
    if (field === 'APORTACION') {
      const firstSort = sort === -1 ? 1 : -1;
      const secondSort = sort === -1 ? -1 : 1;
      const sorted = responsabilities.sort((a, b) => {
        return Number(a.totalAportacion) > Number(b.totalAportacion) ? firstSort : secondSort;
      });
      setResponsabilities([...sorted]);
    }

    if (field === 'APORTACIONR') {
      const firstSort = sort === -1 ? 1 : -1;
      const secondSort = sort === -1 ? -1 : 1;
      const sorted = responsabilities.sort((a, b) => {
        return Number(a.aportacionReal) > Number(b.aportacionReal) ? firstSort : secondSort;
      });
      setResponsabilities([...sorted]);
    }

    if (field === 'COMPRADO') {
      const firstSort = sort === -1 ? 1 : -1;
      const secondSort = sort === -1 ? -1 : 1;
      const sorted = responsabilities.sort((a, b) => {
        return Number(a.totalComprado) > Number(b.totalComprado) ? firstSort : secondSort;
      });
      setResponsabilities([...sorted]);
    }

    if (field === 'GENERADO') {
      const firstSort = sort === -1 ? 1 : -1;
      const secondSort = sort === -1 ? -1 : 1;
      const sorted = responsabilities.sort((a, b) => {
        return Number(a.totalGenerado) > Number(b.totalGenerado) ? firstSort : secondSort;
      });
      setResponsabilities([...sorted]);
    }

    if (field === 'PAGADO') {
      const firstSort = sort === -1 ? 1 : -1;
      const secondSort = sort === -1 ? -1 : 1;
      const sorted = responsabilities.sort((a, b) => {
        return Number(a.totalPagado) > Number(b.totalPagado) ? firstSort : secondSort;
      });
      setResponsabilities([...sorted]);
    }
  }

  return (<section className='m-2'>
    <header>
      <h3 className='text-center my-5'>Panel de Observación</h3>
      <div className='text-end'>
        {
          responsabilities
            ? <Button title='Descargar excel' onPress={() => generateExcel(responsabilities)} />
            : <></>
        }
      </div>
      <div className="table-responsive mb-5">
        <table className="table">
          <thead>
            <tr>
              <th>Total de usuarios</th>
              <th>Otros Usos</th>
              <th>Total Responsabilidad Actual</th>
              <th>Total Responsabilidad SA</th>
              <th>Total Saldo Actual</th>
              <th>Total Saldo Fondeo</th>
              <th>Total Saldo AG</th>
              <th>Total Aportación</th>
              <th>Total Aportacion Real</th>
              <th>Total de Comprado</th>
              <th>Total Generado</th>
              <th>Total pagado</th>
            </tr>
          </thead>
          {
            responsabilities
              ? <tbody>
                <tr>
                  <td>{responsabilities.length}</td>
                  <td>{responsabilities.reduce((acc, e) => acc + e.otrosUsos, 0).toFixed(2)}</td>
                  <td>{responsabilities.reduce((acc, e) => acc + e.responsabilidadActual, 0).toFixed(2)}</td>
                  <td>{responsabilities.reduce((acc, e) => acc + e.responsabilidadSA, 0).toFixed(2)}</td>
                  <td>{responsabilities.reduce((acc, e) => acc + e.saldoActual, 0).toFixed(2)}</td>
                  <td>{responsabilities.reduce((acc, e) => acc + e.saldoFondeo, 0).toFixed(2)}</td>
                  <td>{responsabilities.reduce((acc, e) => acc + e.saldoPuntosAG, 0).toFixed(2)}</td>
                  <td>{responsabilities.reduce((acc, e) => acc + e.totalAportacion, 0).toFixed(2)}</td>
                  <td>{responsabilities.reduce((acc, e) => acc + e.aportacionReal, 0).toFixed(2)}</td>
                  <td>{responsabilities.reduce((acc, e) => acc + e.totalComprado, 0).toFixed(2)}</td>
                  <td>{responsabilities.reduce((acc, e) => acc + e.totalGenerado, 0).toFixed(2)}</td>
                  <td>{responsabilities.reduce((acc, e) => acc + e.totalPagado, 0).toFixed(2)}</td>
                </tr>
              </tbody> : <tbody></tbody>
          }
        </table>
      </div>
      <input
        type="text"
        onChange={(e) => setTextFilter(e.target.value)}
        className="form-control"
        placeholder="Buscar usuario..."
        aria-describedby="search"
      />
    </header>
    <Table rows={responsabilities ?? []} textFilter={textFilter} renderRows={(e) => setRows(e)} pagination onLoad={!responsabilities}>
      <thead>
        <tr>
          <Th onChange={(sort) => sorter('NOMBRE', sort)} sorted initiarSort={1}>Nombre Usuario</Th>
          <Th onChange={(sort) => sorter('OTROS', sort)} sorted initiarSort={1}>Otros Usos</Th>
          <Th onChange={(sort) => sorter('RESPONSABILIDADA', sort)} sorted initiarSort={1}>responsabilidad Actual</Th>
          <Th onChange={(sort) => sorter('RESPONSABILIDADSA', sort)} sorted initiarSort={1}>responsabilidad SA</Th>
          <Th onChange={(sort) => sorter('SALDOA', sort)} sorted initiarSort={1}>Saldo Actual</Th>
          <Th onChange={(sort) => sorter('SALDOF', sort)} sorted initiarSort={1}>Saldo Fondeo</Th>
          <Th onChange={(sort) => sorter('PUNTOSG', sort)} sorted initiarSort={1}>Saldo AG</Th>
          <Th onChange={(sort) => sorter('APORTACION', sort)} sorted initiarSort={1}>Aportacion</Th>
          <Th onChange={(sort) => sorter('APORTACIONR', sort)} sorted initiarSort={1}>Aportacion Real</Th>
          <Th onChange={(sort) => sorter('COMPRADO', sort)} sorted initiarSort={1}>Comprado</Th>
          <Th onChange={(sort) => sorter('GENERADO', sort)} sorted initiarSort={1}>Generado</Th>
          <Th onChange={(sort) => sorter('PAGADO', sort)} sorted initiarSort={1}>Pagado</Th>
        </tr>
      </thead>
      <tbody>
        {rows.length ? (
          rows.map((row, i) => (
            <tr key={i}>
              <td>{row.nombreUsuario}</td>
              <td>{row.otrosUsos}</td>
              <td>{row.responsabilidadActual}</td>
              <td>{row.responsabilidadSA}</td>
              <td>{row.saldoActual}</td>
              <td>{row.saldoFondeo}</td>
              <td>{row.saldoPuntosAG}</td>
              <td>{row.totalAportacion}</td>
              <td>{row.aportacionReal}</td>
              <td>{row.totalComprado}</td>
              <td>{row.totalGenerado}</td>
              <td>{row.totalPagado}</td>
            </tr>
          ))
        ) : (
          <tr>
            <td className="text-center" colSpan={15}>
              No se encontraron resultados
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  </section>);
}