// import Badge from 'components/Badge';
import Table from 'components/Table';
import { LayoutHeader } from 'components/navs/LayoutHeader';
import React, { useEffect, useState } from 'react';
import AppleSystemConsume from 'services/AppleSystemService';
import { parseFullDate, parsePrice } from 'utils/parser';

interface Retiro {
  codigo: number;
  codUsuario: number;
  fecha: string;
  monto: number;
}

interface SolicitudRetiro {
  codigo: number;
  codUsuario: number;
  fecha: string;
  monto: number;
  fechaPago: string;
  referencia: string;
  confirmado: number;
}

interface sendDataRetiro {
  error: boolean;
  code: number;
  message: string;
  withdrawals: Array<Retiro>;
}

interface sendDataRequest {
  error: boolean;
  code: number;
  message: string;
  withdrawalsRequests: Array<SolicitudRetiro>;
}

export default function Retiros() {

  const [retiros, setRetiros] = useState<Retiro[] | null>(null)
  const [solicitudRetiro, setSolicitudRetiro] = useState<SolicitudRetiro[] | null>(null)
  const [rowRetiros, setRowRetiros] = useState<Retiro[]>([]);
  const [rowRetiroRequest, setRowRetiroRequest] = useState<SolicitudRetiro[]>([]);
  const [activeTab, setActiveTab] = useState('Tus Retiros');

  const retirosFetch = async () => {
    await AppleSystemConsume('withdrawal')
      .get('')
      .then((res: sendDataRetiro) => {
        if (res.error) throw new Error(res.message)
        setRetiros(res.withdrawals)
      })
  }

  const retirosRequestFetch = async () => {
    await AppleSystemConsume('withdrawal')
      .get('request')
      .then((res: sendDataRequest) => {
        if (res.error) throw new Error(res.message)
        setSolicitudRetiro(res.withdrawalsRequests)
      })
  }

  useEffect(() => {
    retirosFetch();
    retirosRequestFetch();
  }, [])

  return (

    <div className='container'>
      <LayoutHeader title='Retiros' tabs={['Tus Retiros', 'Solicitudes']} setActiveTab={setActiveTab} />
      <div>
        {activeTab === 'Tus Retiros' ?
          <Table
            background='#fff'
            color='bg-dark'
            rows={retiros ?? []}
            pagination
            onLoad={!retiros}
            renderRows={(e) => setRowRetiros(e)}
          >
            <thead>
              <tr style={{ fontSize: '12px' }}>
                <th>Fecha</th>
                <th>Monto</th>
              </tr>
            </thead>
            <tbody>
              {rowRetiros.map((row, index) => (
                <tr key={index} style={{ fontSize: '12px' }}>
                  <td>{parseFullDate(row.fecha)}</td>
                  <td style={{ color: row.monto > 0 ? 'green' : 'red' }}>
                    {parsePrice(row.monto)}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          : <Table
            rows={solicitudRetiro ?? []}
            pagination
            onLoad={!solicitudRetiro}
            renderRows={(e) => setRowRetiroRequest(e)}
          >
            <thead>
              <tr style={{ fontSize: '12px' }}>
                <th>Fecha</th>
                <th>Monto</th>
                <th>Fecha de pago</th>
                <th>Estado</th>
              </tr>
            </thead>
            <tbody>
              {rowRetiroRequest.map((row, index) => (
                <tr key={index} style={{ fontSize: '12px' }}>
                  <td>{parseFullDate(row.fecha)}</td>
                  <td style={{ color: row.monto > 0 ? 'green' : 'red' }}>
                    {parsePrice(row.monto)}
                  </td>
                  <td>{parseFullDate(row.fechaPago)}</td>
                  <td>{row.confirmado}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        }

      </div>
    </div>

  )
}