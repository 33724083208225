import TarjetaInicio from 'components/templates/TarjetaInicio';
import { useEffect, useState } from 'react';
import AppleSystemConsume from 'services/AppleSystemService';
import { parsePrice } from 'utils/parser';

interface AGPoints {
  error: boolean;
  code: number;
  message: string;
  appleGoldenPoints: number;
  appleGoldenPointsThisMonth: number;
  appleGoldenPointsLastMonth: number;
  percentaje: string;
}

export default function TarjetaPuntosAG() {

  const [appleGPoints, setAppleGPoints] = useState<number>();
  const [percentajeAG, setPercentajeAG] = useState<string>();
  const [cargando, setCargando] = useState<boolean>(false);

  const fetchAG = async () => {
    setCargando(true)
    AppleSystemConsume('usuario')
      .get('apple-golden-points')
      .then((res: AGPoints) => {
        if (res.error) throw new Error(res.message);
        setAppleGPoints(res.appleGoldenPoints);
        setPercentajeAG(res.percentaje);
      })
      .catch(() => { })
      .finally(() => {
        setCargando(false)
      })
  }

  useEffect(() => {
    fetchAG();
  }, [])

  const dataFetch = {
    puntos: {
      numero: appleGPoints,
      percentaje: percentajeAG,
      text: 'Puntos Apple Golden',
      txtPercentaje: 'del mes pasado',
      fondo: '#E20616',
      colorLetter: '#FFFFFF',
      colorPercentaje: '#FFFFFF',
      fill: Number(percentajeAG) >= 0 ? '#5FC507' : '#FFFFFF'
    }
  }

  return (

    <>
      {Object.values(dataFetch).map((data, index) => (
        <TarjetaInicio
          key={index}
          onLoad={cargando}
          text={data.text}
          numero={parsePrice(Number(data.numero))}
          percentaje={data.percentaje}
          txtPercentaje={data.txtPercentaje}
          fondo={data.fondo}
          colorLetter={data.colorLetter}
          colorPercentaje={data.colorPercentaje}
          fill={data.fill}
        />
      ))}

    </>
  )
}
