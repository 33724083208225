import React, { useEffect, useState } from 'react';
import AppleSystemConsume from 'services/AppleSystemService';
import Button from 'components/Button';
import Modal from 'components/Modal';
import PaqueteAdquirido from './PaqueteAdquirido';


type InfoUser = {
  email: string;
  nombreUsuario: string;
  nombreUsuarioPatrocinador: string;
  apellidoPaterno: string;
  apellidoMaterno: string;
  nombre: string;
  codigo: number
}

type ResUser = {
  code: number;
  error: boolean;
  message: string;
  usuario: InfoUser
}

export default function SolicitarPaquete() {

  const [userInfo, setUserInfo] = useState<InfoUser | null>(null);
  const [monto, setMonto] = useState<number>();
  const [saldo, setSaldo] = useState<number>();
  const [fondeoValue, setFondeoValue] = useState<number>();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');

  const fetchUserInfo = () => {
    AppleSystemConsume('usuario')
      .get('my-usuario')
      .then((res: ResUser) => {
        setUserInfo(res.usuario)
      })
  }

  // aca consigo el saldo de fondeo
  const getFondeo = () => {
    AppleSystemConsume('user')
      .get('funding-balance')
      .then((res) => {
        setFondeoValue(res.fundingBalance)
      })
  }

  const getSaldo = () => {
    AppleSystemConsume('usuario')
      .get('available-balance')
      .then((res) => {
        if(res.error) throw new Error;
        setSaldo(res.avaliableBalance)
      })
  }

  const postPackage = () => {
    if(monto && monto < 300) {
      setMessage('* El monto de saldo debe ser igual o superior a 300.')
      return;
    }
    AppleSystemConsume('package')
      .post('new-request-package', {monto}, {})
      .then((res) => {
        if (res.error) throw new Error(res.message);
        setIsModalVisible(true)
      })
  }

  const saldoTotalDisponible = () => {
    if(saldo !== undefined && fondeoValue !== undefined) {
      return saldo - (fondeoValue > 0 ? fondeoValue : 0);
    }
    return undefined
  }

  const handleCloseModal = () => {
    setIsModalVisible(false);
    window.location.reload(); 
  };

  useEffect(() => {
    fetchUserInfo();
    getSaldo();
    getFondeo();
  }, [])

  return (
    <div className='container d-flex flex-column'>
      <p className='text-center' style={{ fontSize: 25, fontWeight: '600' }}>Solicitar Paquete</p>
      <div>
        <div className='d-flex flex-column' style={{
            boxShadow: '2px 8px 20px rgba(0, 0, 0, 0.2)', backgroundColor: '#fff',
            borderRadius: 15,
            padding: 10
          }}>
        <article className='d-flex justify-content-center'>
          <section className='p-4'>
            <p style={{ fontWeight: 'bolder', fontSize: 13 }} className='text-center'>Tus Datos</p>
            <div className='d-flex gap-2'>
              <p style={{ fontWeight: 'bolder', fontSize: 13 }}>Email:</p>
              <p>{userInfo?.email}</p>
            </div>
            <div className='d-flex gap-2'>
              <p style={{ fontWeight: 'bolder', fontSize: 13 }}>Nombre de usuario:</p>
              <p>{userInfo?.nombreUsuario}</p>
            </div>
            <div className='d-flex gap-2'>
              <p style={{ fontWeight: 'bolder', fontSize: 13 }}>Usuario Patrocinador:</p>
              <p>{userInfo?.nombreUsuarioPatrocinador}</p>
            </div>
            <div className='d-flex gap-2'>
              <p style={{ fontWeight: 'bolder', fontSize: 13 }}>Apellido Paterno:</p>
              <p>{userInfo?.apellidoPaterno}</p>
            </div>
            <div className='d-flex gap-2'>
              <p style={{ fontWeight: 'bolder', fontSize: 13 }}>Apellido Materno:</p>
              <p>{userInfo?.apellidoMaterno}</p>
            </div>
            <div className='d-flex gap-2'>
              <p style={{ fontWeight: 'bolder', fontSize: 13 }}>Nombre:</p>
              <p>{userInfo?.nombre}</p>
            </div>
            <div className='d-flex gap-2 align-items-center'>
              <p className='text-success' style={{ fontWeight: 'bolder', fontSize: 13 }}>Saldo disponible:</p>
              <p className='text-success'>{saldo}</p>
            </div>
            <div className='d-flex gap-2 align-items-center'>
              <p className='text-success' style={{ fontWeight: 'bolder', fontSize: 13 }}>Saldo de Fondeo disponible:</p>
              <p className={fondeoValue && fondeoValue < 0 ? 'text-danger' : 'text-success'}>{fondeoValue}</p>
            </div>
            <div className='d-flex gap-2 align-items-center'>
              <p className='text-success' style={{ fontWeight: 'bolder', fontSize: 13 }}>Saldo total disponible:</p>
              <p className='text-success'>{saldoTotalDisponible()}</p>
            </div>
          </section>
          <section className='p-4'>
            <p style={{ fontWeight: 'bolder', fontSize: 13 }} className='text-center'>Solicitar Paquete</p>
            <div className='d-flex flex-column gap-2 mb-4'>
              <label htmlFor="valorPaquete" style={{ fontSize: 13, fontWeight: 'bolder' }}>Ingrese el valor de su nuevo paquete</label>
              <input
                id="valorPaquete"
                name="valorPaquete"
                onBlur={(e) => setMonto(Number(e.target.value))}
                className="form-control h-25 mb-3 border border-dark"
                placeholder='Valor de paquete...'
                type="number" />
                <p className='text-danger' style={{fontSize: 8, marginBottom: 0}}>{message}</p>
            </div>
            <div className='d-flex flex-column gap-2'>
              <div style={{ textAlign: 'center', maxWidth: 300 }}>
                <p className='text-light bg-danger bg-gradient'>Los paquetes disponibles están sujetos a tu disponibilidad de saldo.</p>
                <p className='bg-warning'>Una vez realizada la solicitud, la misma debe ser aceptada por el administrador. 
                Una vez adquirido el paquete, el mismo demorará 3 días hábiles para activarse.</p>
              </div>
            </div>
          </section>
        </article>
        <div className='text-center mt-5'>
        <Button title='Solicitar Paquete' onPress={postPackage} />
      </div>
        </div>
      </div>
      <Modal title='Paquete' onClose={handleCloseModal} show={isModalVisible}>
          <PaqueteAdquirido monto={monto} />
      </Modal>
    </div>
  )
}